import React, { ReactNode } from "react";
import Drawer from "@mui/material/Drawer";
import { Box, Typography } from "@mui/material";
import Icons from "../Icons";

interface BottomDrawerProps {
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
  headerText: string;
}

const BtmCloseHeader: React.FC<BottomDrawerProps> = ({
  open,
  onClose,
  children,
  headerText,
}) => {
  return (
    <Box>
      {(["bottom"] as const).map((anchor) => (
        <Box key={anchor}>
          <Drawer
            anchor={anchor}
            open={open}
            onClose={onClose}
            sx={{
              ".MuiDrawer-paper": {
                paddingTop: "12px",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
              },
            }}
          >
            <Box
              sx={{ display: "flex", paddingX: "16px", alignItems: "center" }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "21.6px",
                  fontFamily: "PolySans_Neutral",
                  color: "#333",
                }}
              >
                {headerText}
              </Typography>
              <Box
                sx={{ marginLeft: "auto" }}
                component={"div"}
                onClick={onClose}
              >
                <Icons name={"close"} width={24} height={24} />
              </Box>
            </Box>
            {children}
          </Drawer>
        </Box>
      ))}
    </Box>
  );
};

export default BtmCloseHeader;
