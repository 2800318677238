import { Box, Typography } from "@mui/material";
import React from "react";
import IconComponent from "../../components/Icons";

interface MinimizedBugPop {
  expand: () => void;
  close: () => void;
}

const MinimizedBugPop: React.FC<MinimizedBugPop> = ({ expand, close }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "inherit",
        left: { md: "24px", sm: "24px", xs: "0px" },
        bottom: { md: "48px", sm: "48px", xs: "-1px" },
        zIndex: "100",
        background: "#007AFF",
        paddingX: { md: "16px", sm: "16px", xs: "0px" },
        paddingTop: { md: "16px", sm: "16px", xs: "15px" },
        paddingBottom: { md: "16px", sm: "16px", xs: "24px" },
        borderRadius: { md: "16px", sm: "16px", xs: "0" },
        display: "flex",
        alignItems: "center",
        gap: "8px",
        maxWidth: { md: "436px", sm: "436px", xs: "none" },
        width: "100%",
      }}
    >
      <Box
        sx={{ paddingLeft: { md: "0", sm: "0", xs: "16px" }, display: "flex" }}
      >
        <IconComponent name="bugWhiteIcon" width={27} height={27} fill="#FFF" />
      </Box>
      <Typography variant="bodyLg" color={"#FFF"}>
        Report a bug
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginLeft: "auto",
          gap: "20px",
          marginRight: "10px",
        }}
      >
        <Box
          sx={{ display: "flex", cursor: "pointer" }}
          component={"div"}
          onClick={expand}
        >
          <IconComponent
            name="expandWhiteIcon"
            width={27}
            height={27}
            fill="#FFF"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            cursor: "pointer",
            paddingRight: { md: "0", sm: "0", xs: "16px" },
          }}
          component={"div"}
          onClick={close}
        >
          <IconComponent
            name="closeWhiteIcon"
            width={27}
            height={27}
            fill="#FFF"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MinimizedBugPop;
