import client from "./client";
import Cookies from "js-cookie";

interface LoginPayload {
  email: string;
  password: string;
}

interface ForgotPayload {
  email: string;
}

interface OtpPayload {
  otp: string;
}

interface resetPayload {
  newpwd: string;
  confirmpwd: string;
}

export const loginCredentials = async (payload: LoginPayload) =>
  await client.post("/employees/student-login", payload);

export const verifyOtp = async (payload: OtpPayload, authToken: string) =>
  await client.post("/employees/student-verify", payload, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

export const forgotPassword = async (payload: ForgotPayload) =>
  await client.post("/employees/student-forgot-password", payload);

export const resetPassword = async (payload: resetPayload, token: string) =>
  await client.post("/employees/student-reset-password", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const resendOtp = async (payload: object, token: string) =>
  await client.post("/employees/studentResend-otp", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getToken = () => {
  const authAccessString = Cookies.get("authAccess");
  const authAccess = authAccessString ? JSON.parse(authAccessString) : null;
  return authAccess ? authAccess : null;
};
