import moment from "moment";

export const secondsToDuration = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Format the duration components with leading zeros
    const formattedHours = ('0' + hours).slice(-2);
    const formattedMinutes = ('0' + minutes).slice(-2);
    const formattedSeconds = ('0' + remainingSeconds).slice(-2);

    return formattedHours + ':' + formattedMinutes + ':' + formattedSeconds;
}

export const getSubjectFormat = (subjectName: any) => {
    const numeratorRegex = /(\d)\/\d/;
    console.log("The numerator is: " + subjectName);

// Extracting the numerator
    const match = subjectName.match(numeratorRegex);
    if (match && match.length > 1) {
        const numerator = match[1];
        console.log("The numerator is: " + numerator);
        // return `${subjectName} - Unit ${numerator}`
        return `${subjectName}`
    } else {
        console.log("Numerator not found in the string.");
        return subjectName;
    }

}
export const formatLastopend=(date:string)=>{
    moment(date)
}
export const formatDate=()=>{
    // Get the current UTC time
const now = new Date();
const utcYear = now.getUTCFullYear();
const utcMonth = now.getUTCMonth() + 1; // Months are zero-indexed, so add 1
const utcDay = now.getUTCDate();
const utcHours = now.getUTCHours();
const utcMinutes = now.getUTCMinutes();
const utcSeconds = now.getUTCSeconds();

// Format the UTC time
const utcTimeString = `${utcYear}-${pad(utcMonth)}-${pad(utcDay)} ${pad(utcHours)}:${pad(utcMinutes)}:${pad(utcSeconds)}`;

console.log(utcTimeString);
return utcTimeString;
// Helper function to pad single digits with leading zeros
function pad(num:number) {
    if (num < 10) {
        return '0' + num;
    }
    return num;
}

}
export const debounceFunction = <T extends (...args: any[]) => void>(
    func: T,
    delay: number
  ) => {
    let timer: NodeJS.Timeout;
    return function(this: ThisParameterType<T>, ...args: Parameters<T>) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };