import React from 'react';

interface ErrorBoundaryProps {
  fallback?: React.ReactNode; // Fallback component or UI to show on error
  children: React.ReactNode;   // Children components to wrap
}

interface ErrorBoundaryState {
  hasError: boolean;  // State to track if an error has occurred
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state to show fallback UI
    console.log("error",error)
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Log the error if needed
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render the fallback component
      return this.props.fallback || <div>Something went wrong.</div>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
