import {
  Box,
  Checkbox,
  SvgIcon,
  SvgIconProps,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IconComponent from "../Icons";
import BottomDrawer from "../BottomDrawer";
import { getToken } from "../../services/api/login";
import { getAllFileType, getAllSubjects } from "../../services/api/dashboard";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface CustomCheckboxProps extends SvgIconProps {
  checked: boolean;
}
const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  ...props
}) => (
  <SvgIcon {...props}>
    {checked ? (
      <>
        <rect width="20" height="20" rx="6" fill="#FFA737" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7535 5.20711C16.0605 5.50374 16.0839 6.00965 15.8058 6.3371L9.01173 14.3371C8.86955 14.5045 8.66765 14.6 8.45588 14.6C8.24412 14.6 8.04222 14.5045 7.90004 14.3371L4.19416 9.97346C3.91607 9.64601 3.9395 9.1401 4.24648 8.84347C4.55346 8.54684 5.02776 8.57182 5.30585 8.89927L8.45588 12.6084L14.6942 5.26291C14.9722 4.93546 15.4465 4.91048 15.7535 5.20711Z"
          fill="#333333"
        />
      </>
    ) : (
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="5.5"
        fill="white"
        stroke="#D2D2D0"
      />
    )}
  </SvgIcon>
);


interface FilterComponentProps {
  selectedOptions1: string[];
  setSelectedOptions1: React.Dispatch<React.SetStateAction<string[]>>;
  selectedOptions4: string[];
  setSelectedOptions4: React.Dispatch<React.SetStateAction<string[]>>;
  handleSearchChange: () => void;
  resetSearchData: () => void;
}

const MobileFilterComponent: React.FC<FilterComponentProps> = ({
  selectedOptions1,
  setSelectedOptions1,
  selectedOptions4,
  setSelectedOptions4,
  handleSearchChange,
  resetSearchData,
}) => {

  const [bottomDrawerOpen, setBottomDrawerOpen] = useState(false);
  const [selectedBox, setSelectedBox] = useState<string | null>(null);
  const verifyAccess = getToken();

  useEffect(() => {
    getAllData();
    getAllSub();
  }, []);
  const [filtype, setFiltype] = useState([]);
  const [subjects, setSubjects] = useState<any>([]);
  const getAllSub = async () => {
    try {
      const response = await getAllSubjects(verifyAccess.token);
      setSubjects(response?.data?.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const getAllData = async () => {
    try {
      const response = await getAllFileType(verifyAccess.token);
      setFiltype(response?.data.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const handleClick = (boxName: string) => {
    setSelectedBox(boxName);
    handleBottomDrawerOpen();
  };

  const handleBottomDrawerOpen = () => {
    setBottomDrawerOpen(true);
  };
  const handleOptionClick1 = (optionId: number) => {
    setSelectedOptions1(prevSelectedOptions => {
      if (prevSelectedOptions.includes(optionId.toString())) {

        return prevSelectedOptions.filter(item => item !== optionId.toString());
      } else {

        return [...prevSelectedOptions, optionId.toString()];
      }
    });

    handleSearchChange();
  };

  const handleOptionClick4 = (optionId: string) => {
    setSelectedOptions4((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId)) {
        return prevSelectedOptions.filter((item) => item !== optionId);
      } else {
        return [...prevSelectedOptions, optionId];
      }
    });
    handleSearchChange();
  };


  const handleClearAll4 = () => {
    setSelectedOptions4([]);
  };

  const handClose = () => {
    setBottomDrawerOpen(false);
  };
  const handleClearAll = () => {
    setSelectedOptions1([]);
    setSelectedOptions4([]);
    resetSearchData();
  };

  return (
    <Box
      sx={{
        display: { md: "none", sm: "none", xs: "flex" },
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: {
            md: "190px",
            sm: "140px",
            xs: "114px",
          },
          "@media screen and (max-width:374px)": {
            width: "100px",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
          border: "1px solid #D2D2D0",
          padding: "4.5px 12px 4.5px 12px",
          fontSize: "12px",
          fontWeight: "400",
          marginRight: "8px",
          color: "#696969",
          cursor: "pointer",
          background: selectedOptions4.length ? "#F4F6FF" : "none",
        }}
        component={"div"}
        onClick={() => handleClick("FileType")}
      >
        File type
        {selectedOptions4.length > 0 && `(${selectedOptions4.length})`}
        <Box sx={{ display: "flex" }}>
          <IconComponent name="userArrowDown" fill={"#8E8D8A"} />
        </Box>
      </Box>

      <Box
        sx={{
          width: {
            md: "190px",
            sm: "140px",
            xs: "114px",
          },
          "@media screen and (max-width:374px)": {
            width: "100px",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
          border: "1px solid #D2D2D0",
          padding: "4.5px 12px 4.5px 12px",
          fontSize: "12px",
          fontWeight: "400",
          marginRight: "8px",
          color: "#696969",
          cursor: "pointer",
          background: selectedOptions1.length ? "#F4F6FF" : "none",
        }}
        component={"div"}
        onClick={() => handleClick("Subject")}
      >
        Subject
        {selectedOptions1.length > 0 && `(${selectedOptions1.length})`}
        <Box sx={{ display: "flex" }}>
          <IconComponent name="userArrowDown" fill={"#8E8D8A"} />
        </Box>
      </Box>

      <Typography
        onClick={handleClearAll}
        sx={{
          fontSize: { md: "14px", sm: "14px", xs: "12px" },
          lineHeight: { md: "21px", sm: "15px", xs: "15px" },
          fontWeight: { md: "600", sm: "500", xs: "500" },
          color: "#333",
          borderBottom: "2px solid #FFA737",
          cursor: "pointer",
          "@media screen and (max-width:374px)": {
            fontSize: "10px",
          },
        }}
      >
        Clear all
      </Typography>

      <BottomDrawer open={bottomDrawerOpen} onClose={handClose}>
        {selectedBox === "FileType" && (
          <Box>
            <Box>
              <Typography
                sx={{
                  display: "block",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "18.2px",
                  paddingX: "16px",
                  paddingTop: "12px",
                  color: "#000000",
                }}
              >
                Filter by file type
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingX: "16px",
                borderBottom: "1px solid #D2D2D0",
                paddingY: "12px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "15px",
                  color: "#696969",
                }}
              >
                {selectedOptions4.length > 0
                  ? `${selectedOptions4.length} Selected`
                  : "0 Selected"}
              </Typography>
              {selectedOptions4.length > 0 && (
                <button
                  style={{
                    padding: "0px",
                    background: "transparent",
                    border: "none",
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#333",
                    borderBottom: "2px solid #FFA737",
                    cursor: "pointer",
                    marginLeft: "auto",
                  }}
                  onClick={handleClearAll4}
                >
                  Clear All
                </button>
              )}
            </Box>
            {filtype.map((option: any, index: number) => (
              <Box
                key={index}
                sx={{
                  "&.MuiBox-root": {
                    paddingX: "6px",
                    borderBottom: "1px solid #D2D2D0",
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                style={{
                  background: selectedOptions4.includes(option.file_type)
                    ? "#FFF8EC"
                    : "",
                }}
                onClick={() => handleOptionClick4(option.file_type)}
              >
                <Checkbox
                  checked={selectedOptions4.includes(option.file_type)}
                  icon={<CustomCheckbox checked={false} />}
                  checkedIcon={
                    <CustomCheckbox checked style={{ fill: "##333333" }} />
                  }
                />
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#333",
                    lineHeight: "18.2px",
                  }}
                >
                  {option.file_type}
                </span>
              </Box>
            ))}
            <Box
              sx={{
                marginY: "12px",
                borderRadius: "12px",
                border: "1px solid #FFA737",
                marginX: "16px",
              }}
              component={"div"}
              onClick={handClose}
            >
              <Typography
                sx={{
                  display: "block",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "18.2px",
                  color: "#000000",
                  alignItems: "center",
                  textAlign: "center",
                  paddingY: "12px",
                }}
              >
                View{" "}
                {selectedOptions4.length > 0
                  ? `${selectedOptions4.length}`
                  : ""}{" "}
                results
              </Typography>
            </Box>
          </Box>
        )}

        {selectedBox === "Subject" && (
          <Box>
            <Box>
              <Typography
                sx={{
                  display: "block",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "18.2px",
                  paddingX: "16px",
                  paddingTop: "12px",
                  color: "#000000",
                }}
              >
                Filter by Subjects
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingX: "16px",
                borderBottom: "1px solid #D2D2D0",
                paddingY: "12px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "15px",
                  color: "#696969",
                }}
              >
                {selectedOptions1.length > 0
                  ? `${selectedOptions1.length} Selected`
                  : "0 Selected"}
              </Typography>
              {selectedOptions1.length > 0 && (
                <button
                  style={{
                    padding: "0px",
                    background: "transparent",
                    border: "none",
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#333",
                    borderBottom: "2px solid #FFA737",
                    cursor: "pointer",
                    marginLeft: "auto",
                  }}
                  onClick={handleClearAll}
                >
                  Clear All
                </button>
              )}
            </Box>
            {subjects.map((option: any) => (
              <Box
                key={option.subject_id}
                sx={{
                  "&.MuiBox-root": {
                    paddingX: "6px",
                    borderBottom: "1px solid #D2D2D0",
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                style={{
                  background: selectedOptions1.includes(
                    option.subject_id,
                  )
                    ? "#FFF8EC"
                    : "",
                }}
                onClick={() => handleOptionClick1(option.subject_id)}
              >
                <Checkbox
                  checked={selectedOptions1.includes(
                    option.subject_id.toString(),
                  )}
                  icon={<CustomCheckbox checked={false} />}
                  checkedIcon={
                    <CustomCheckbox checked style={{ fill: "#333333" }} />
                  }
                />
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#333",
                  }}
                >
                  {option.sub_group_code}
                </span>
              </Box>
            ))}
            <Box
              sx={{
                marginY: "12px",
                borderRadius: "12px",
                border: "1px solid #FFA737",
                marginX: "16px",
              }}
              component={"div"}
              onClick={handClose}
            >
              <Typography
                sx={{
                  display: "block",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "18.2px",
                  color: "#000000",
                  alignItems: "center",
                  textAlign: "center",
                  paddingY: "12px",
                }}
              >
                View{" "}
                {selectedOptions1.length > 0
                  ? `${selectedOptions1.length}`
                  : ""}{" "}
                results
              </Typography>
            </Box>
          </Box>
        )}
      </BottomDrawer>
    </Box>
  );
};
export default withErrorBoundary(MobileFilterComponent,<>Something went wrong with MobileFilterComponent Component</>);
