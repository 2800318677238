import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography, Skeleton } from "@mui/material";
import Button from "../Button";
import IconComponent from "../../components/Icons";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import "../../pages/WorkBook/workbook.css";
import Lady from "../../assets/images/lady.png";
import ResponsiveWave from "../../assets/images/ResponsiveWave.png";
import "../../index.css";
import { useNavigate } from "react-router-dom";

import { secondsToDuration } from "../../utils/utilfns";
import moment from "moment";
import NoImageFound from "../../assets/images/NoImageFound2.png"
import withErrorBoundary from "../../utils/withErrorBoundary";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: "-4px",
  height: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

interface SimpleSliderProps {
  recentActivityData: any[];
  loadingRecentView: boolean;
}
const CourseSlider: React.FC<SimpleSliderProps> = ({
  recentActivityData,
  loadingRecentView,
}) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: recentActivityData.length > 3 ? 3 : 1,
    variableWidth: true,
    centerMode: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
    prevArrow: (
      <IconComponent
        name="chevronLeft"
        fill={"#8E8D8A "}
      />
    ),
    nextArrow: (
      <IconComponent
        name="chevronRight"
        fill={"#8E8D8A "}
      />
    ),
    responsive: [
      {
        breakpoint: 1730,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          adaptiveHeight: false,

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          adaptiveHeight: false,
        },
      },
    ],
  };

  const navigate = useNavigate();

  const handleContent = (subject: any) => {
    console.log("handle", subject);
    const newSearch = new URLSearchParams();
    newSearch.set("sid", subject.subject_id);
    newSearch.set("aosID", subject.aos_id);
    newSearch.set("topicID", subject.topic_id);
    newSearch.set("typeID", subject.type_id);
    newSearch.set("fileID", subject.file_id);
    navigate(`/aos?${newSearch.toString()}`);
  };
  console.log(recentActivityData, "anil");
  return (
    <>
      <Box
        position={"relative"}
        sx={{
          background: {
            md: "transparent",
            xs: "linear-gradient(267deg, #FEE3BF 2.1%, #FBF2E3 95.53%)",
            sm: "linear-gradient(267deg, #FEE3BF 2.1%, #FBF2E3 95.53%)",
          },
          height: "auto",
        }}
        paddingBottom={{ md: "0", xs: "20px", sm: "20px" }}
      >
        <Box
          position={"relative"}
          zIndex={"10"}
          bgcolor={recentActivityData.length > 0 ? "white" : "transparent"}
          py={{ sm: "20px", md: "32px" }}
          px={{ sm: "16px", md: "16px" }}
          borderRadius={loadingRecentView ? "0" : "24px"}
          marginTop={{ md: "0", xs: "-53px", sm: "270px" }}
          marginX={{ xs: "16px", sm: "16px" }}
          sx={{ height: "100%" }}
        >{recentActivityData.length > 0 && (
          <Box
            sx={{
              marginBottom: { xs: "20px", md: "28px", sm: "20px" },
              marginX: "8px",
              paddingTop: { md: "0", xs: "20px", sm: "0" },
            }}
          >
            <Typography variant="title4" fontSize={"18px"}>
              Continue where you last left off...
            </Typography>
          </Box>)}
          {loadingRecentView ? (
            <Skeleton variant="rounded" width={"100%"} height={"170px"} />
          ) : (
            <Slider {...settings} className="courseSlider">
              {recentActivityData
                ?.sort((a: any, b: any) => b.last_opened - a.last_opened)
                .map((item: any) => {
                  const progress = (item.current_time / item.duration) * 100 || 0;
                  return (
                    <Box
                      key={item.subject + item.file_id}
                      sx={{ paddingX: "8px", cursor: "pointer" }}
                      onClick={() => handleContent(item)}
                    >
                      <Box
                        className="hoverDiv"
                        sx={{
                          borderRadius: "12px",
                          marginBottom: "12px",
                          width: "100%",
                          border: "1px solid #D2D2D0",
                          minWidth: '246px',
                          maxWidth: '246px',
                        }}
                      >
                        {item?.ext_type === "pdf" && (
                          <Box sx={{ position: "relative" }}>
                            <Box
                              className="hoverOpacity pdfThumb"
                              sx={{
                                paddingTop: "13px",
                                paddingX: "21px",
                                background: "#F4F6FF",
                                borderTopLeftRadius: "12px",
                                borderTopRightRadius: "12px",
                                position: "relative",
                                height: "90px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "90px",
                                  objectFit: "cover",
                                }}
                                component="img"
                                src={`${process.env.REACT_APP_API_URL}/${item.thumbnail_url}`}
                                crossOrigin="anonymous"
                                alt="LoginSmily"
                                onError={(e) => {
                                  const target = e.target as HTMLImageElement;
                                  target.onerror = null; // Prevents infinite loop if fallback image fails
                                  target.src = NoImageFound;
                                }}
                              />
                            </Box>
                            <Box
                              className="hoverShow"
                              sx={{
                                display: "none",
                                maxWidth: "168px",
                                position: "absolute",
                                bottom: "50%",
                                width: "100%",
                                left: "50%",
                                transform: "translate(-50%, 50%)",
                              }}
                            >
                              <Button
                                text={`Open ${item?.filetype}`}
                                textStyle={{ color: "#333" }}
                                handleClick={() =>
                                  console.log("Open workbook1")
                                }
                              />
                            </Box>
                          </Box>
                        )}
                        {item?.ext_type === "video" && (
                          <Box sx={{ position: "relative" }}>
                            <Box
                              sx={{
                                background: "#F4F6FF",
                                borderTopLeftRadius: "12px",
                                borderTopRightRadius: "12px",
                                position: "relative",
                                paddingTop: '13px',
                                paddingLeft: '21px',
                                paddingRight: '21px',
                              }}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  borderTopLeftRadius: "12px",
                                  borderTopRightRadius: "12px",
                                  height: "90px",
                                }}
                                component="img"
                                src={`${process.env.REACT_APP_API_URL}/${item.thumbnail_url}`}
                                crossOrigin="anonymous"
                                alt="LoginSmily"
                                onError={(e) => {
                                  const target = e.target as HTMLImageElement;
                                  target.onerror = null; // Prevents infinite loop if fallback image fails
                                  target.src = NoImageFound;
                                }}
                              />
                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, 50%)",
                                }}
                                component={"div"}
                                onClick={() => console.log("Video")}
                              >
                                <IconComponent
                                  name="videControl"
                                  fill={"#8E8D8A"}
                                />
                              </Box>
                            </Box>
                            <Box
                              className="hoverVideControl"
                              sx={{
                                display: "none",
                                position: "absolute",
                                bottom: "50%",
                                left: "50%",
                                transform: "translate(-50%, 50%)",
                                cursor: "pointer",
                              }}
                              component={"div"}
                              onClick={() => console.log("Video")}
                            >
                              <IconComponent
                                name="videControl"
                                fill={"#8E8D8A"}
                              />
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                lineHeight: "15px",
                                color: "#FFF",
                                position: "absolute",
                                right: "8px",
                                top: "8px",
                                background: "#2B3258",
                                paddingX: "4px",
                                borderRadius: "8px",
                              }}
                            >
                              {secondsToDuration(item?.duration)}
                            </Typography>
                            <BorderLinearProgress
                              variant="determinate"
                              value={progress}
                            />
                          </Box>
                        )}
                        <Box sx={{ padding: "16px" }}>
                          <Typography
                            sx={{
                              paddingX: "8px",
                              paddingY: "4px",
                              background: "#FFF0E2",
                              borderRadius: "4px",
                            }}
                            variant="detail"
                            color={"#333333"}
                            fontWeight={500}
                            lineHeight={"15px"}
                          >
                            {item.subject}
                          </Typography>
                          <Typography
                            sx={{
                              display: "-webkit-box",
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "18.2px",
                              marginTop: "8px",
                              color: "#000000",
                              maxHeight: '38px',
                              minHeight: '38px',
                              overflow: 'hidden',
                              '-webkit-line-clamp': '2',
                              '-webkit-box-orient': 'vertical',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            sx={{
                              display: "block",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "18.2px",
                              marginTop: "4px",
                              color: "#333333",
                              maxHeight: '18px',
                              minHeight: '18px',
                              overflow: 'hidden',
                              '-webkit-line-clamp': '1',
                              whiteSpace: "nowrap",
                              '-webkit-box-orient': 'vertical',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {item.filetype}
                          </Typography>
                          <Typography
                            sx={{
                              display: "block",
                              fontSize: "12px",
                              fontWeight: "400",
                              lineHeight: "18.2px",
                              marginTop: "4px",
                              color: "#696969",
                              height: "18px"
                            }}
                          >

                            {item.last_opened !== null ? (
                              `Last opened: ${moment(moment.utc(item.last_opened).toDate()).local().fromNow()}`
                            ) : ""}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            </Slider>
          )}
        </Box>
        <Box>
          <Box
            position={"absolute"}
            display={{ xs: "block", sm: "block", md: "none" }}
            top={{ xs: "-220px", sm: "-287px", md: "-287px" }}
            right={{ xs: "0", sm: "0", md: "" }}
            sx={{ right: 0, zIndex: 1, width: "208px", height: "204px" }}
            component={"img"}
            src={Lady}
          />
          <Box
            position={"absolute"}
            display={{ xs: "block", sm: "block", md: "none" }}
            bottom={{ xs: "100%", sm: "100%" }}
            right={{ xs: "0", sm: "0", md: "" }}
            sx={{ right: 0, width: "100%" }}
            component={"img"}
            src={ResponsiveWave}
          />
        </Box>
      </Box>
    </>
  );
};

export default withErrorBoundary(CourseSlider,<>Something went wrong with CourseSlider Component</>);
