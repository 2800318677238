import {
  Add,
  BookOpen,
  BugIcon,
  BugWhiteIcon,
  CameraOutline,
  ChevronLeft,
  ChevronRight,
  Close,
  CloseWhiteIcon,
  ExpandVideoFull,
  ExpandWhiteIcon,
  ExternalNormalScreen,
  FolderOpen,
  Forward15,
  GreenCheckCircle,
  CheckCircle,
  Hide,
  Home,
  Logout,
  MiniPlayLeftNext,
  MiniPlayRightNext,
  MiniPlayer,
  MiniPlayerGrey,
  Minus,
  Notification,
  OpenBookOutline,
  PauseSmallIcon,
  PlayIconCircle,
  PlaySmallIcon,
  RePlay15,
  RecordingBarIcon,
  RectangleBar,
  Search,
  Settings,
  Show,
  SkipNext,
  SkipPrevious,
  Student,
  Undo,
  UserArrowDown,
  VideControl,
  VideoPauseControl,
  WarningRed,
  TextFile,
  TextFileActive,
  Wave1,
  Wave2,
  RecordingBarBlackIcon,
  Pause,
  BlackDropDown,
  Copy,
  Download,
  CollapseWhite,
} from "../../utils/icons";
import withErrorBoundary from "../../utils/withErrorBoundary";
type IconComponentProps = {
  name: string;
  fill?: string;
  width?: number;
  height?: number;
  style?: object;
  onClick?: () => void;
};
type IconTypes = { [name: string]: ReactSVGComponent };

const iconTypes: IconTypes = {
  home: Home,
  folderOpen: FolderOpen,
  bookOpen: BookOpen,
  student: Student,
  add: Add,
  notification: Notification,
  userArrowDown: UserArrowDown,
  settings: Settings,
  bugIcon: BugIcon,
  logout: Logout,
  search: Search,
  Wave1: Wave1,
  Wave2: Wave2,
  chevronLeft: ChevronLeft,
  undo: Undo,
  hide: Hide,
  show: Show,
  warningRed: WarningRed,
  rectangleBar: RectangleBar,
  chevronRight: ChevronRight,
  videControl: VideControl,
  openBookOutline: OpenBookOutline,
  greenCheckCircle: GreenCheckCircle,
  checkCircle: CheckCircle,
  close: Close,
  minus: Minus,
  recordingBarIcon: RecordingBarIcon,
  cameraOutline: CameraOutline,
  bugWhiteIcon: BugWhiteIcon,
  expandWhiteIcon: ExpandWhiteIcon,
  closeWhiteIcon: CloseWhiteIcon,
  expandVideoFull: ExpandVideoFull,
  rePlay15: RePlay15,
  forward15: Forward15,
  playIconCircle: PlayIconCircle,
  playSmallIcon: PlaySmallIcon,
  pauseSmallIcon: PauseSmallIcon,
  skipNext: SkipNext,
  skipPrevious: SkipPrevious,
  miniPlayer: MiniPlayer,
  miniPlayLeftNext: MiniPlayLeftNext,
  miniPlayRightNext: MiniPlayRightNext,
  externalNormalScreen: ExternalNormalScreen,
  videoPauseControl: VideoPauseControl,
  miniPlayerGrey: MiniPlayerGrey,
  recordingBarBlackIcon: RecordingBarBlackIcon,
  textFile: TextFile,
  textFileActive: TextFileActive,
  pause: Pause,
  blackDropDown: BlackDropDown,
  copy: Copy,
  download: Download,
  collapseWhite: CollapseWhite,
};

const Icons = ({ name, ...props }: IconComponentProps) => {
  const Icons = iconTypes[name];
  return <Icons {...props} />;
};

export default withErrorBoundary(Icons,<>Something went wrong with Icons Component</>);
