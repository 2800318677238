import { Box, Typography } from "@mui/material";
import React from "react";
import WaveGroup from "../../assets/images/WaveBackground.png";
import CourseSlider from "../Slider/CourseSlider";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface ExistingSubjectsProps {
  recentActivityData: any;
  loadingRecentView: boolean;
}

const ExistingSubjects: React.FC<ExistingSubjectsProps> = ({
  recentActivityData,
  loadingRecentView,
}) => {
  const today = new Date();
  const formattedDate = `${today.toLocaleDateString("en-US", {
    weekday: "long",
  })} ${today.getDate()} ${today.toLocaleDateString("en-US", {
    month: "long",
  })} ${today.getFullYear()}`;
  const storedFirstName = localStorage.getItem("profileFirstName");
  const capitalizedFirstChar =
    storedFirstName && storedFirstName.charAt(0).toUpperCase();
  const capitalizedFirstName = storedFirstName && storedFirstName.slice(1);
  return (
    <Box
      position={"relative"}
      pt={{ md: "50px" }}
      pb={{ md: "65px" }}
      px={{ xs: "0px", sm: "0px", md: "100px" }}
      height={{ md: "370px", xs: "", sx: "" }}
      zIndex={1}
    >
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", sm: "column", md: "row" }}
      >
        <Box
          paddingTop={{ md: "23px", xs: "28px" }}
          width={{ sm: "100%", md: "40%" }}
        >
          <Typography
            paddingX={{ md: "0", xs: "16px" }}
            variant="bodyLg"
            fontWeight={"400"}
          >
            {formattedDate}
          </Typography>
          <Typography
            component={"div"}
            variant="title1"
            color={"contour.title2"}
            paddingX={{ md: "0", xs: "16px" }}
            sx={{
              fontSize: { xs: "28px", md: "40px" },
              maxWidth: { xs: "214px", md: "100%" },
            }}
          >
            Welcome back, <span style={{ textTransform: "capitalize" }}> {`${capitalizedFirstChar}${capitalizedFirstName}`}</span>
          </Typography>
        </Box>
        <Box
          width={{ sm: "100%", md: "60%" }}
          sx={{ marginTop: { sm: "220px", xs: "220px", md: "0px", lg: "0px" } }}
        >
          <CourseSlider
            recentActivityData={recentActivityData}
            loadingRecentView={loadingRecentView}
          />
        </Box>
      </Box>
      <Box
        position={"absolute"}
        display={{ md: "block", xs: "none", sm: "none" }}
        sx={{ bottom: "-35px", left: 0, right: 0, width: "100%", zIndex: 0 }}
      >
        <Box component={"img"} src={WaveGroup} sx={{ width: "100%" }} />
      </Box>
    </Box>
  );
};

export default withErrorBoundary(ExistingSubjects,<>Something went wrong with ExistingSubjects Component</>);
