import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

interface buttonProps {
  text: string;
  textStyle: object;
  icon?: React.ReactNode | null;
  handleClick?: () => void;
  disabled?: boolean;
  loading?:boolean;
}

const txtStyle = {
  fontSize: "14px",
  fontWeight: 600,
  fontFamily: "Inter",
  lineHeight: "14px",
};

const Button = ({
  text = "Button",
  icon = null,
  textStyle = {},
  handleClick,
  disabled,
  loading = false,
}: buttonProps) => {
  const btnStyle = {
    borderRadius: "8px",
    padding:"12px",
    bgcolor: "#FFA737",
    cursor: disabled ? "default" : "pointer",
    display: "flex",
    opacity: disabled ? 0.5 : 1,
    flexDirection: "row",
    gap: "8px",
    justifyContent: "center",
  };

  return (
    <Box
      sx={{
        ...btnStyle,
        opacity: loading ? 0.5 : 1,
        pointerEvents: loading ? "none" : "auto",
      }}
      onClick={handleClick}>
      <>
        {icon}
        <Typography sx={{ ...txtStyle, ...textStyle, display: "flex", alignItems: "center" }}>
          {text}
          {
            loading ? (
              <CircularProgress sx={{ marginLeft: "8px" }} size={14} color="inherit" />
            ) : ''
          }
        </Typography>
      </>
    </Box >
  );
};

export default Button;
