import {
  Avatar,
  Box,
  IconButton,
  Link,
  Popover,
  Typography,
  Modal,
  Skeleton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import logo from "../../assets/images/ContourLogo.png";
import { useNavigate } from "react-router-dom";
import Icons from "../Icons";
import Cookies from "js-cookie";
import BottomDrawer from "../BottomDrawer";
import HeaderPopover from "../Popover/HeaderPopover";
import { SearchBar } from "../SearchBar";
import Settings from "../Settings";
import MobileSettings from "../Settings/mobileSettings";
import BtmCloseHeader from "../BottomDrawer/BtmCloseHeader";
import { getProfile } from "../../services/api/profile";
import ToastMessage from "../ToastMessage";
import { getToken } from "../../services/api/login";
import { getAllStudentSelectedSubjects } from "../../services/api/subject";
import withErrorBoundary from "../../utils/withErrorBoundary";
interface HeaderProps {
  showPopover: boolean;
  selectedSubjectId?: string;
  onClickCallback?: (status: string) => void;
}

const Header: React.FC<HeaderProps> = ({
  showPopover = true,
  selectedSubjectId,
  onClickCallback,
}) => {
  const navigate = useNavigate();
  const verifyAccess = getToken();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [loadingProfile, setLoadingProfile] = React.useState(true);
  const [profileData, setProfileData] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    is_verified: false,
    record_status: "",
  });

  const [bottomDrawerOpen, setBottomDrawerOpen] = useState(false);
  const [bottomDrawerOpen2, setBottomDrawerOpen2] = useState(false);
  const [searchBarVisible, setSearchBarVisible] = useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [resetPass, setResetPass] = React.useState<boolean>(false);
  const [passUpdatePopup, setPassUpdatePopup] = React.useState<boolean>(false);
  const [studentSubjectsData, setStudentSubjectsData] = React.useState<any>([]);
  const [loadingSubDrop, setLoadingSubDrop] = React.useState(true);

  useEffect(() => {
    getAllSubjectsData();
  }, [selectedSubjectId]);

  const handlePopupClose = () => {
    setOpenPopup(false);
    setResetPass(false);
  };
  const handlePopupOpen = () => {
    setAnchorEl(null);
    setOpenPopup(true);
  };
  const handleBtnSheet = () => {
    setBottomDrawerOpen(false);
    setBottomDrawerOpen2(true);
  };
  const handleBottomDrawerOpen = () => {
    setBottomDrawerOpen(true);
    setBottomDrawerOpen2(false);
  };

  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);

  const handleLogut = () => {
    setAnchorEl(null);
    Cookies.remove("loginToken");
    Cookies.remove("authAccess");
    localStorage.removeItem("profileFirstName");
    navigate("/");
  };
  const menuData = [
    {
      label: "Settings",
      icon: "settings",
      onClick: handlePopupOpen,
    },
    {
      label: "Log out",
      icon: "logout",
      onClick: handleLogut,
    },
  ];
  const mobileeData = [
    {
      label: "Settings",
      icon: "settings",
      onClick: handleBtnSheet,
    },
    {
      label: "Log out",
      icon: "logout",
      onClick: handleLogut,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getProfile(verifyAccess.token);
      setProfileData(response.data.data[0]);
      setLoadingProfile(false);
    } catch (error) {
      setLoadingProfile(false);
      console.error("Error fetching data:", error);
    }
  };

  const getAllSubjectsData = async () => {
    try {
      const response = await getAllStudentSelectedSubjects(verifyAccess.token);
      setStudentSubjectsData(response.data.data);
      setLoadingSubDrop(false);
    } catch (error) {
      setLoadingSubDrop(false);
      console.error("Error fetching student subjects data:", error);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenSearch = () => {
    setSearchBarVisible(!searchBarVisible);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Box sx={{ marginTop: { md: "76px", sm: "61px", xs: "51px" } }}>
        <Box position={"fixed"} zIndex={"1000"} width={"100%"} top={"0"}>
          <Box
            sx={{
              bgcolor: "background.default",
              px: { xs: "16px", sm: "16px", md: "32px" },
              py: { xs: "8px", sm: "8px", md: "16px" },
              borderBottom: "1px solid #EFEFEF",
            }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={"24px"}
            >
              <Box
                width={{ xs: "171px", md: "214px" }}
                sx={{ cursor: "pointer" }}
                component="img"
                src={logo}
                alt="brand-logo"
                onClick={() => navigate("/dashboard")}
              />
              {showPopover && studentSubjectsData && (
                <HeaderPopover
                  studentSubjectsData={studentSubjectsData}
                  selectedSubjectId={selectedSubjectId}
                  loadingSubDrop={loadingSubDrop}
                  onClickCallback={(value) => {
                    if (onClickCallback) onClickCallback(value);
                  }}
                />
              )}
            </Box>

            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={{ md: "16px", xs: "8px", sm: "16px" }}
            >
              <Box display={{ sm: "none", md: "none" }}>
                <IconButton
                  size="medium"
                  aria-label="search"
                  color="inherit"
                  sx={{
                    background: "#007AFF26",
                    width: { xs: "34px", sm: "34px" },
                    height: { xs: "34px", sm: "34px" },
                  }}
                  onClick={handleOpenSearch}
                >
                  <Icons name="search" fill="#007AFF" width={24} height={24} />
                </IconButton>
              </Box>
              <Box display={{ sm: "block", xs: "none", md: "block" }}>
                <SearchBar />
              </Box>
              <Link
                component="button"
                variant="button"
                sx={{ display: { md: "flex", sm: "none", xs: "none" } }}
                onClick={handleClick}
                underline="none"
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                aria-describedby={id}
              >
                {loadingProfile ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      bgcolor: "#007AFF",
                      borderRadius: "100%",
                      width: "43px",
                      height: "43px",
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      bgcolor: "#007AFF",
                      textDecoration: "none",
                      width: { xs: "34px", sm: "34px", md: "44px" },
                      height: { xs: "34px", sm: "34px", md: "44px" },
                      color: "#FFFFFF",
                    }}
                  >
                    {profileData.first_name.charAt(0)}
                  </Avatar>
                )}
                <Icons name="userArrowDown" fill="#8E8D8A" />
              </Link>
              <Link
                component="button"
                variant="button"
                sx={{ display: { md: "none", sm: "flex", xs: "flex" } }}
                onClick={handleBottomDrawerOpen}
                underline="none"
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                aria-describedby={id}
              >
                <Avatar
                  sx={{
                    bgcolor: "#007AFF",
                    textDecoration: "none",
                    width: { xs: "34px", sm: "34px", md: "44px" },
                    height: { xs: "34px", sm: "34px", md: "44px" },
                    color: "#FFFFFF",
                  }}
                >
                  {profileData.first_name.charAt(0)}
                </Avatar>
                <Icons name="userArrowDown" fill="#8E8D8A" />
              </Link>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box
                  sx={{ borderRadius: "16px", width: "230px" }}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <Box
                    paddingX={"12px"}
                    paddingY={"8px"}
                    display={"flex"}
                    flexDirection={"column"}
                    borderBottom={"1px solid #D2D2D0"}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#333",
                        lineHeight: "15px",
                        textTransform: "capitalize",
                      }}
                    >
                      {`${profileData.first_name} ${profileData.last_name}`}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#696969",
                        lineHeight: "15px",
                      }}
                      noWrap={true}
                    >
                      {profileData.email}
                    </Typography>
                  </Box>
                  {menuData.map((item, id) => {
                    return (
                      <Box
                        paddingX={"12px"}
                        paddingY={"8px"}
                        display={"flex"}
                        flexDirection={"row"}
                        gap={"8px"}
                        alignItems={"center"}
                        borderBottom={
                          id != menuData.length - 1
                            ? "1px solid #D2D2D0"
                            : "0px"
                        }
                        component={"div"}
                        sx={{ cursor: "pointer" }}
                        onClick={item.onClick}
                      >
                        <Icons name={item.icon} />
                        <Typography>{item.label}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Popover>
              {/* <Modal /> */}
            </Box>
          </Box>
          {searchBarVisible && (
            <Box
              display={{ sm: "none", xs: "block", md: "none" }}
              sx={{
                paddingX: "16px",
                paddingY: "8px",
                background: "#FFF",
                borderBottom: "1px solid #EFEFEF",
              }}
            >
              <SearchBar />
            </Box>
          )}
          <BottomDrawer
            open={bottomDrawerOpen}
            onClose={() => setBottomDrawerOpen(false)}
          >
            <Box sx={{ paddingTop: "4px", paddingBottom: "28px" }}>
              <Box
                sx={{ borderRadius: "16px" }}
                display={"flex"}
                flexDirection={"column"}
              >
                <Box
                  paddingX={"16px"}
                  paddingY={"12px"}
                  display={"flex"}
                  flexDirection={"column"}
                  borderBottom={"1px solid #D2D2D0"}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "#333",
                      lineHeight: "15px",
                    }}
                  >
                    {`${profileData.first_name} ${profileData.last_name}`}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#696969",
                      lineHeight: "15px",
                    }}
                    noWrap={true}
                  >
                    {profileData.email}
                  </Typography>
                </Box>
                {mobileeData.map((item, id) => {
                  return (
                    <Box
                      paddingX={"16px"}
                      paddingY={"12px"}
                      display={"flex"}
                      flexDirection={"row"}
                      component={"div"}
                      gap={"8px"}
                      alignItems={"center"}
                      borderBottom={
                        id != menuData.length - 1 ? "1px solid #D2D2D0" : "0px"
                      }
                      sx={{ cursor: "pointer" }}
                      onClick={item.onClick}
                    >
                      <Icons name={item.icon} />
                      <Typography>{item.label}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </BottomDrawer>
          <BtmCloseHeader
            open={bottomDrawerOpen2}
            onClose={() => {
              setResetPass(false);
              setBottomDrawerOpen2(false);
            }}
            headerText={"Settings"}
          >
            <MobileSettings
              resetPass={resetPass}
              setResetPass={setResetPass}
              setPassUpdatePopup={setPassUpdatePopup}
              fetchData={fetchData}
              fName={profileData?.first_name}
              lName={profileData?.last_name}
              setToast={setToast}
              setBottomDrawerOpen2={setBottomDrawerOpen2}
            />
          </BtmCloseHeader>
        </Box>
      </Box>
      <Settings
        open={openPopup}
        handleClose={handlePopupClose}
        resetPass={resetPass}
        setResetPass={setResetPass}
        setPassUpdatePopup={setPassUpdatePopup}
        fetchData={fetchData}
        fName={profileData?.first_name}
        lName={profileData?.last_name}
        setToast={setToast}
      />
      <Modal
        open={passUpdatePopup}
        disableEscapeKeyDown={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #FFA737",
            boxShadow: 24,
            borderRadius: "8px",
            p: 4,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#007634" }}
          >
            Password successfully updated!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please login again to access your account.
          </Typography>
        </Box>
      </Modal>
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            position: "absolute",
            top: "85px",
          }}
        />
      )}
    </>
  );
};

export default withErrorBoundary(Header,<>Something went wrong with Header Component</>);
