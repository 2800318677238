import React, { useEffect, useState } from "react";
import Dashboard from "../../layouts/Dashboard";
import { Box } from "@mui/material";
import ExistingSubjects from "../../components/Home/ExistingSubjects";
import YourSubjects from "../../components/Home/YourSubjects";
import { getSubjects } from "../../services/api/dashboard";
import { getToken } from "../../services/api/login";
import { getRecentViewedDetails } from "../../services/api/subject";
import Toast from "../../components/ToastMessage/Toast";

export const StudentDashboard = () => {
  const [subjects, setSubjects] = React.useState<[]>([]);
  const [recentViewData, setRecentViewData] = useState([]);
  const [loadingSubject, setLoadingSubject] = useState(true);
  const [loadingRecentView, setLoadingRecentView] = useState(true);
  const verifyAccess = getToken();
  useEffect(() => {
    getSubjectsData();
    getRecententlyViewedData();
  }, []);

  const getSubjectsData = async () => {
    try {
      const response = await getSubjects(verifyAccess.token);
      setSubjects(response?.data.data);
      setLoadingSubject(false);
    } catch (error) {
      setLoadingSubject(false);
      console.error("Error fetching subjects:", error);
    }
  };

  const getRecententlyViewedData = async () => {
    try {
      const response = await getRecentViewedDetails(verifyAccess.token);
      setRecentViewData(response?.data.data);
      setLoadingRecentView(false);
    } catch (error) {
      setLoadingRecentView(false);
      console.error("Error fetching subjects:", error);
    }
  };

  return (
    <Dashboard showPopover={false}>
      <Toast/>
      <Box
        display={"flex"}
        flexDirection={"column"}
        bgcolor={"#FBF2E4"}
      >
        <ExistingSubjects
          recentActivityData={recentViewData}
          loadingRecentView={loadingRecentView}
        />
        <YourSubjects subjectsData={subjects} loadingSubject={loadingSubject} />
      </Box>
    </Dashboard>
  );
};
