import { Box, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Icons from "../Icons";
import Waves from "../SVG/Waves";
import { useNavigate } from "react-router-dom";
import { updateSubjectAccessTime } from "../../services/api/subject";
import { getToken } from "../../services/api/login";
import { formatDate } from "../../utils/utilfns";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface SubjectCardProps {
  label: string;
  color: string;
  progress: string;
  total_task: any;
  subject: string;
  subjectGroupCode: string;
  subjectId: number;
  icon: string;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    border: "1px solid #D2D2D2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    border: "1px solid #308fe8",
  },
}));
function adjust(color: string, amount: number) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
}

const Subjects: React.FC<SubjectCardProps> = ({
  label,
  color,
  progress,
  total_task,
  subjectGroupCode,
  subjectId,
  icon,
}) => {
  const percentage =
    ((parseInt(progress) || 0) / (parseInt(total_task) || 1)) * 100;
  const navigate = useNavigate();
  const handleSubjectSceen = () => {
  const verifyAccess = getToken();

    const date=formatDate()
    updateSubjectAccessTime({subject_id:subjectId,last_access_date:date},verifyAccess.token)
    navigate(`/subject/${subjectGroupCode}?sid=${subjectId}`);
    window.scrollTo(0, 0);
  };

  return (
    <Box
      flexGrow={1}
      width={"100%"}
      height={"100%"}
      sx={{ cursor: "pointer" }}
      borderRadius={"12px"}
      bgcolor={"#FFF"}
      border={"1px solid #D2D2D2"}
      overflow={"hidden"}
      component={"div"}
      onClick={handleSubjectSceen}
    >
      <Box bgcolor={color} height={"170px"} position={"relative"}>
        {icon !== null ? (
          <Box
            zIndex={2}
            borderRadius={"50px"}
            bgcolor={"white"}
            width={"45px"}
            height={"45px"}
            position={"absolute"}
            top={"18px"}
            left={"16px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src={icon}
              alt="Subject Icon"
              width={22}
              height={22}
              crossOrigin="anonymous"
            />
          </Box>
        ) : (
          <Box
            zIndex={2}
            borderRadius={"50px"}
            bgcolor={"white"}
            width={"45px"}
            height={"45px"}
            position={"absolute"}
            top={"18px"}
            left={"16px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Icons name="bookOpen" width={45} height={45} />
          </Box>
        )}
        <Box
          zIndex={1}
          sx={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        >
          <Waves
            color={adjust("#E59DFF", 40)}
          />
        </Box>
      </Box>
      <Box
        py={{ xs: "32px", md: "32px" }}
        px={{ xs: "16px", md: "16px" }}
        display={"flex"}
        flexDirection={"column"}
        gap={"8px"}
      >
        <Typography variant="title4" color={"#000"}>
          {label.replace(/(\d)\s*([⁄/])\s*(\d)/g, "$1$2$3")}
        </Typography>
        {total_task !== 0 && (
          <>
            <Typography variant="detail" color={"#696969"}>
              {`${progress}/${total_task}`} Resources Complete
            </Typography>
            <BorderLinearProgress variant="determinate" value={percentage} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default withErrorBoundary(Subjects,<>Something went wrong with Subjects Component</>);
