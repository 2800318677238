import { Box } from "@mui/material";
import React from "react";
import IconComponent from "../Icons";

type IconBtnComponentProps = {
  name: string;
  width?: number;
  height?: number;
  clickButton: () => void;
};

const IconBtnComponent: React.FC<IconBtnComponentProps> = ({
  name,
  width,
  height,
  clickButton,
}) => {
  return (
    <Box
      sx={{
        padding: "10px",
        display: "flex",
        alignItems: "center",
        border: "1px solid transparent",
        ":Hover": {
          borderRadius: "8px",
          cursor: "pointer",
        },
        "@media screen and (max-width:600px)": {
          padding: "0px",
        },
      }}
      onClick={clickButton}
    >
      <IconComponent
        name={name}
        fill={"#FFFFFF"}
        width={width}
        height={height}
      />
    </Box>
  );
};

export default IconBtnComponent;
