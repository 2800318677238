import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MiniMizeScreenState {
  showMiniVideo: boolean;
}

const initialState: MiniMizeScreenState = {
  showMiniVideo: false,
};

export const miniMizeSliceScreen = createSlice({
  name: "minimizeScreen",
  initialState,
  reducers: {
    setShowMiniVideo: (state, action: PayloadAction<boolean>) => {
      state.showMiniVideo = action.payload;
    },
  },
});

export const { setShowMiniVideo } = miniMizeSliceScreen.actions;
