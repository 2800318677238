import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authSlice, authSliceOtp } from "./AuthSlice";
import { miniMizeSliceScreen } from "./MiniMizeSlice";
import { errorSlice } from "./error.slice";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  authOtp: authSliceOtp.reducer,
  minimizeScreen: miniMizeSliceScreen.reducer,
    error: errorSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
