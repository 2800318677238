import React from "react";

export default function Waves({
  //   size = 18, // or any default size of your choice
  color = "black", // or any color of your choice
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="170"
      viewBox="0 0 398 170"
      fill="none"
    >
      <path
        d="M-502.601 170.228C-464.371 -52.9484 -226.685 -62.0899 -43.4133 117.921C112.821 230.943 225.84 -91.4995 491.769 121.246C513.548 138.667 532.208 154.969 548.149 170.228H-502.601Z"
        fill="url(#paint0_linear_1988_31348)"
      />
      <path
        opacity="0.6"
        d="M-239.982 170.228C-201.753 -52.9482 35.9329 -62.0896 219.205 117.922C375.439 230.943 488.458 -91.4992 754.387 121.246C776.166 138.667 794.826 154.97 810.767 170.228H-239.982Z"
        fill="url(#paint1_linear_1988_31348)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1988_31348"
          x1="53.3852"
          y1="46.7799"
          x2="-82.9103"
          y2="365.911"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color} stop-opacity="0.4" />
          <stop offset="1" stop-color={color} stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1988_31348"
          x1="316.003"
          y1="46.7802"
          x2="179.708"
          y2="365.911"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color} stop-opacity="0.53" />
          <stop offset="1" stop-color={color} stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
