import { Box, Typography, Skeleton } from "@mui/material";
import React from "react";
import Subjects from "../Cards/Subjects";
import Grid from "@mui/material/Unstable_Grid2";
import Lady from "../../assets/images/lady.png";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface YourSubjectsProps {
  subjectsData: [];
  loadingSubject: boolean;
}

const YourSubjects: React.FC<YourSubjectsProps> = ({
  subjectsData,
  loadingSubject,
}) => {
  return (
    <Box
      position={"relative"}
      display={"flex"}
      flexDirection={"column"}
      mt={"20px"}
      py={{ xs: "20px", sm: "40px", md: "70px" }}
      px={{ xs: "0px", sm: "0px", md: "100px" }}
      sx={{
        borderTopLeftRadius: "48px",
        borderTopRightRadius: "48px",
        bgcolor: "#FFFFFF",
        gap: "24px",
        zIndex: "10",
      }}
    >
      <Typography
        component={"div"}
        variant="title2"
        color={"#333"}
        sx={{
          marginLeft: { md: "12px", sm: "24px", xs: "16px" },
          fontSize: { xs: "24px", sm: "24px" },
        }}
      >
        Your Subjects
      </Typography>
      {loadingSubject ? (
        <>
          <Skeleton variant="rounded" width={"100%"} height={"331px"} />
        </>
      ) : (
        <Grid
          container
          sx={{ marginX: { md: "0px", sm: "16px", xs: "16px" } }}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {subjectsData.map(
            (
              subject: {
                subject: string;
                subject_colour: string;
                sub_group_code: string;
                subject_id: number;
                task_completed: string;
                total_task: string;
                subject_icon: string;
              },
              index: React.Key | null | undefined
            ) => (
              <Grid key={index} xs={12} sm={12} md={4}>
                <Subjects
                  label={`${subject.subject.replace(/(\d)\s*([⁄/])\s*(\d)/g, "$1$2$3")}`}
                  color={subject.subject_colour}
                  icon={subject.subject_icon}
                  progress={subject.task_completed}
                  total_task={subject.total_task}
                  subject={subject.subject}
                  subjectGroupCode={subject.sub_group_code}
                  subjectId={subject.subject_id}
                />
              </Grid>
            )
          )}
        </Grid>
      )}
      <Box
        position={"absolute"}
        display={{ xs: "none", sm: "none", md: "block" }}
        top={{ xs: "-287px", sm: "-287px", md: "-287px" }}
        left={{ xs: "0", sm: "0", md: "82px" }}
        sx={{ left: "82px", right: 0, zIndex: 0 }}
        component={"img"}
        src={Lady}
      />
    </Box>
  );
};

export default withErrorBoundary(YourSubjects,<>Something went wrong with YourSubjects Component</>);
