import { Link } from "@mui/material";
import React from "react";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface ViewProps {
  text: string;
  onClick?: () => void;
}

const BorderText = ({ text, onClick }: ViewProps) => {
  return (
    <Link
      underline="none"
      component={"button"}
      sx={{
        color: "contour.title3",
        borderBottom: "2px solid ",
        borderColor: "contour.orange",
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 600,
      }}
      onClick={onClick}
    >
      {text}
    </Link>
  );
};

export default withErrorBoundary(BorderText,<>Something went wrong with BorderText Component</>);
