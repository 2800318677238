// src/theme.ts
import { TypographyOptions } from "@mui/material/styles/createTypography";

import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    contour?: {
      title2: string;
      title3: string;
      title4: string;
      inactive: string;
      orange: string;
    };
  }
  interface PaletteOptions {
    contour?: {
      title2: string;
      title3: string;
      title4: string;
      inactive: string;
      orange: string;
    };
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body1Medium: true;
    body2Medium: true;
    title1: true;
    title2: true;
    title3: true;
    title4: true;
    bodySmBold: true;
    bodySm: true;
    detailBold: true;
    detail: true;
    bodyLg: true;
    cbutton: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  body1Medium: React.CSSProperties;
  body2Medium: React.CSSProperties;
  title1: React.CSSProperties;
  title2: React.CSSProperties;
  title3: React.CSSProperties;
  title4: React.CSSProperties;
  bodySmBold: React.CSSProperties;
  bodySm: React.CSSProperties;
  detailBold: React.CSSProperties;
  detail: React.CSSProperties;
  bodyLg: React.CSSProperties;
  cbutton: React.CSSProperties;
}

const convertPxToRem = (pxValue: number) => {
  const baseFontSize = 16; // Replace this with your base font size in pixels (default is 16px)
  const remValue = pxValue / baseFontSize;
  return remValue;
};

// Function to calculate line height using percentage of font size
const calculateLineHeight = (fontSize: number, percentage: number) => {
  const lineHeight = (fontSize * percentage) / 100;
  return `${lineHeight}rem`;
};

const fontSizes = {
  h1: convertPxToRem(24),
  h2: convertPxToRem(20),
  h3: convertPxToRem(18),
  h4: convertPxToRem(16),
  h5: convertPxToRem(14),
  h6: convertPxToRem(12),
  body1: convertPxToRem(16),
  body2: convertPxToRem(14),
  caption: convertPxToRem(12),
  title1: convertPxToRem(40),
  title2: convertPxToRem(32),
  title3: convertPxToRem(28),
  title4: convertPxToRem(22),
  detailBold: convertPxToRem(15),
};
// Define font weight variables
const FontWeight = {
  Bold: 700,
  Semibold: 600,
  Medium: 500,
  Regular: 400,
};
// Define your custom typography with calculated line height (using a percentage value)
const typography: ExtendedTypographyOptions = {
  fontFamily: "Inter",
  h1: {
    fontFamily: "PolySans_Neutral",
    fontSize: `${fontSizes.h1}rem`,
    fontWeight: FontWeight.Bold,
    lineHeight: calculateLineHeight(fontSizes.h1, 120), // 120% line height based on 2.5rem font size
  },
  h2: {
    fontSize: `${fontSizes.h2}rem`,
    fontWeight: FontWeight.Bold,
    lineHeight: calculateLineHeight(fontSizes.h2, 125), // 130% line height based on 2rem font size
  },
  h3: {
    fontSize: `${fontSizes.h3}rem`,
    fontWeight: FontWeight.Bold,
    lineHeight: calculateLineHeight(fontSizes.h3, 120), // 130% line height based on 2rem font size
  },
  h4: {
    fontSize: `${fontSizes.h4}rem`,
    fontWeight: FontWeight.Bold,
    lineHeight: calculateLineHeight(fontSizes.h4, 120), // 130% line height based on 2rem font size
  },
  h5: {
    fontSize: `${fontSizes.h5}rem`,
    fontWeight: FontWeight.Bold,
    lineHeight: calculateLineHeight(fontSizes.h5, 120), // 130% line height based on 2rem font size
  },
  body1: {
    fontSize: `${fontSizes.body1}rem`,
    lineHeight: calculateLineHeight(fontSizes.body1, 160),
    fontWeight: 400, // Regular
  },
  body1Medium: {
    fontSize: `${fontSizes.body1}rem`,
    lineHeight: calculateLineHeight(fontSizes.body1, 160),
    fontWeight: 500, // Medium
  },
  body2: {
    fontSize: `${fontSizes.body2}rem`,
    lineHeight: calculateLineHeight(fontSizes.body2, 160),
    fontWeight: 400, // Regular
  },
  body2Medium: {
    fontSize: `${fontSizes.body2}rem`,
    lineHeight: calculateLineHeight(fontSizes.body2, 160),
    fontWeight: 500, // Medium
  },
  caption: {
    fontSize: `${fontSizes.caption}rem`,
    lineHeight: calculateLineHeight(fontSizes.caption, 160),
    fontWeight: 501, // Medium
  },
  title1: {
    fontFamily: "PolySans_Median",
    fontSize: `${fontSizes.title2}rem`,
    lineHeight: calculateLineHeight(fontSizes.title2, 120),
    fontWeight: 600,
  },
  title2: {
    fontFamily: "PolySans_Neutral",
    fontSize: `${fontSizes.title2}rem`,
    lineHeight: calculateLineHeight(fontSizes.title2, 120),
    fontWeight: 600,
  },
  title3: {
    fontFamily: "PolySans_Neutral",
    fontSize: `${fontSizes.title3}rem`,
    lineHeight: calculateLineHeight(fontSizes.title3, 120),
    fontWeight: 600,
    // paddingBottom:'4px',
    // paddingTop:'4px'
  },
  title4: {
    fontFamily: "PolySans_Neutral",
    fontSize: `${fontSizes.title4}rem`,
    lineHeight: calculateLineHeight(fontSizes.title4, 120),
    fontWeight: 600,
  },
  bodySmBold: {
    fontFamily: "PolySans_Neutral",
    fontSize: `${fontSizes.h5}rem`,
    lineHeight: calculateLineHeight(fontSizes.h5, 130),
    fontWeight: 600,
  },
  bodySm: {
    fontFamily: "Inter",
    fontSize: `${fontSizes.h5}rem`,
    lineHeight: calculateLineHeight(fontSizes.h5, 130),
    fontWeight: 400,
  },
  detailBold: {
    fontFamily: "Inter",
    fontSize: `${fontSizes.h6}rem`,
    lineHeight: calculateLineHeight(fontSizes.h6, 125),
    fontWeight: 500,
  },
  detail: {
    fontFamily: "Inter",
    fontSize: `${fontSizes.h6}rem`,
    lineHeight: calculateLineHeight(fontSizes.detailBold, 125),
    fontWeight: 400,
  },
  bodyLg: {
    fontFamily: "Inter",
    fontSize: `${fontSizes.h4}rem`,
    lineHeight: calculateLineHeight(fontSizes.h4, 150),
    fontWeight: 600,
  },
  cbutton: {
    fontFamily: "Inter",
    fontSize: `${fontSizes.h5}rem`,
    lineHeight: calculateLineHeight(fontSizes.h5, 150),
    fontWeight: 600,
  },
  // Add more typography variants as needed
};

const colors = {
  bgcolor: "#FFFFFF",
  primary: "#2B3258",
  secondary: "#6C757D",
  error: "#B21200",
  warning: "#AB6402",
  success: "#12753A",
  btndisabled: "#BCC0C7",
  btnhover: "#2B3258",
  bellBg: "#FF527F",
  bellCount: "#FFFFFF",
};

const theme = createTheme({
  // Add your custom theme configurations here
  breakpoints: {},
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warning,
    },
    background: {
      default: colors.bgcolor,
    },
    contour: {
      title2: "#2B3258",
      title4: "#2B3258",
      title3: "#333",
      inactive: "#696969",
      orange: "#FFA737",
    },
  },
  typography,
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#4C525B",
          "&.Mui-focused": {
            color: "#4C525B",
            fontWeight: 500,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline ": {
              border: "1px solid",
              borderColor: "#FFA737",
            },
          },
        },
        multiline: {
          fontSize: `${convertPxToRem(14)} rem`,
          height: `${convertPxToRem(48)} rem`,
          fontWeight: "400",
          lineHeight: calculateLineHeight(24, 1),
          // borderColor: "#BCC0C7",
          // color: "#4C525B",
          // ":hover": {
          //   borderColor: "#808895",
          //   color: "#4C525B",
          // },
          // ":active": {
          //   color: "#1A1C1F",
          //   borderColor: "#BCC0C7",
          // },

          // ":disabled": {
          //   borderColor: "#BCC0C7",
          //   color: "#BCC0C7",
          // },
          // ":invalid": {
          //   borderColor: "#B21200",
          //   color: "#B21200",
          // },
        },
        input: {
          fontSize: `${convertPxToRem(14)} rem`,
          height: `${convertPxToRem(48)} rem`,
          fontWeight: "400",
          lineHeight: calculateLineHeight(24, 1),
          borderColor: "red",
          color: "#696969",
          ":hover": {
            borderColor: "#808895",
            // color: "#4C525B",
          },
          ":active": {
            // color: "#1A1C1F",
            borderColor: "#BCC0C7",
          },
          ":disabled": {
            borderColor: "#BCC0C7",
            color: "#BCC0C7",
          },
          ":invalid": {
            borderColor: "#B21200",
            color: "#B21200",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Override default Button styles here
        root: {
          borderRadius: "4px", // Change border radius for all buttons
          padding: "12px 24px", // Change padding for all buttons
          height: "50px",
          ":disabled": {
            backgroundColor: `${colors.btndisabled}`, // Change background color when disabled
            color: "#ffffff", // Change text color when disabled
          },
          ":hover": {
            backgroundColor: `${colors.btnhover}`, // Change background color on hover
          },
          "&.MuiButton-containedPrimary": {
            backgroundColor: colors.primary, // Change background color for primary button
            color: "#ffffff", // Change text color for primary button
          },
        },
        containedSizeSmall: {
          height: "36px",
          padding: "8px 32px 8px 32px",
        },
        text: {
          ":hover": {
            backgroundColor: "transparent",
            color: colors.btnhover,
          },
          ":disabled": {
            backgroundColor: "transparent",
            color: colors.btndisabled,
          },
          "&.MuiButton-textWarning": {
            ":hover": {
              backgroundColor: "transparent",
              color: colors.warning,
            },
          },
        },
        outlined: {
          border: "2px solid",
          borderColor: colors.primary,
          ":hover": {
            border: "2px solid",
            backgroundColor: "transparent",
            color: colors.btnhover,
            borderColor: colors.btnhover,
          },
          ":disabled": {
            border: "2px solid",
            backgroundColor: "transparent",
            color: colors.btndisabled,
            borderColor: colors.btndisabled,
          },
        },
        outlinedSizeSmall: {
          height: "36px",
          padding: "8px 32px 8px 32px",
        },
        containedPrimary: {
          color: "#fff", // Change text color for primary contained button
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: "8px !important",
          border: "2px solid #EEEFF2",
          boxShadow: "none",
          marginBottom: "24px !important",
          "&:before": {
            backgroundColor: "transparent", // Change background color on hover
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#EEEFF2",
          height: "72px",
          border: "0",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          border: "0",
          padding: "48px 32px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          fill: "#000000",
        },
        outlined: {
          "&.MuiSelect-outlined": {
            lineHeight: "22px",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#B21200",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledWarning: {
          background: "#F6EEE3 !important",
          color: "#AB6402 !important",
        },
        filledError: {
          background: "#F7E5E3 !important",
          color: "#B21200 !important",
        },
        filledSuccess: {
          background: "#E5F0EA !important",
          color: "#12753A !important",
        },
        filledInfo: {
          background: "#DBDDEF !important",
          color: "#212D98 !important",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.bgcolor,
          // color: "red",
          // marginTop:'20px',
          border: "none",
          paddingTop: "20px",
        },
      },
    },
  },
});

export default theme;
