import React from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
  width: number;
  height: number;
}

const withErrorBoundary = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  fallback?: React.ReactNode
) => {
  return class ErrorBoundaryHOC extends React.Component<P, ErrorBoundaryState> {
    private containerRef: React.RefObject<HTMLDivElement>;

    constructor(props: P) {
      super(props);
      this.state = { hasError: false, width: 0, height: 0 };
      this.containerRef = React.createRef();
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        console.log("error",error)
      return { hasError: true, width: 0, height: 0 };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
      console.error("Error caught in ErrorBoundaryHOC:", error, errorInfo);
    }



    render() {
      if (this.state.hasError) {
      if (this.containerRef.current) {

        const { width, height } = this.containerRef.current.getBoundingClientRect();

        const fallbackStyle: React.CSSProperties = {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: `${width}px`,
          height: `${height}px`,
          flexDirection: 'column',
          textAlign: 'center',
          backgroundColor: '#f8d7da',
          color: '#721c24',
          padding: '20px',
          boxSizing: 'border-box',
        };

        return (
          <div ref={this.containerRef} style={{ width: '100%', height: '100%' }}>
            <div style={fallbackStyle}>
              <span style={{ fontSize: '48px', marginBottom: '20px' }}>⚠️</span>
              <div>
                {fallback}
              </div>
            </div>
          </div>
        );
      }
    }
      return (
        <div ref={this.containerRef}>
          <WrappedComponent {...(this.props as P)} />
        </div>
      );
    }
  };
};

export default withErrorBoundary;
