import React, { ReactNode } from "react";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import Icons from "../Icons";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface BottomDrawerProps {
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
}

const BottomDrawer: React.FC<BottomDrawerProps> = ({
  open,
  onClose,
  children,
}) => {
  return (
    <Box>
      {(["bottom"] as const).map((anchor) => (
        <Box key={anchor}>
          <Drawer
            anchor={anchor}
            open={open}
            onClose={onClose}
            sx={{
              ".MuiDrawer-paper": {
                paddingTop: "12px",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
              },
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Icons name={"rectangleBar"} />
            </Box>
            {children}
          </Drawer>
        </Box>
      ))}
    </Box>
  );
};

export default withErrorBoundary(BottomDrawer,<>Something went wrong with BottomDrawer Component</>);
