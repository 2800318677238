import client from "./client";

interface profilePayload {
  first_name: string;
  last_name: string;
}

export const updateProfile = async (payload: profilePayload, token: string) =>
  await client.put("/employees/student-account-update", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getProfile = async (token: string) =>
  await client.get("/employees/view-students", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
