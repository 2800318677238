import React, { useState, MouseEvent, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import IconComponent from "../Icons";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { getAllFileType, getAllSubjects } from "../../services/api/dashboard";
import { getToken } from "../../services/api/login";

interface FilterComponentProps {
  selectedOptions1: string[];
  setSelectedOptions1: React.Dispatch<React.SetStateAction<string[]>>;
  selectedOptions4: string[];
  setSelectedOptions4: React.Dispatch<React.SetStateAction<string[]>>;
  handleSearchChange: () => void;
  resetSearchData: () => void;
}

interface CustomCheckboxProps extends SvgIconProps {
  checked: boolean;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  ...props
}) => (
  <SvgIcon {...props}>
    {checked ? (
      <>
        <rect width="20" height="20" rx="6" fill="#FFA737" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7535 5.20711C16.0605 5.50374 16.0839 6.00965 15.8058 6.3371L9.01173 14.3371C8.86955 14.5045 8.66765 14.6 8.45588 14.6C8.24412 14.6 8.04222 14.5045 7.90004 14.3371L4.19416 9.97346C3.91607 9.64601 3.9395 9.1401 4.24648 8.84347C4.55346 8.54684 5.02776 8.57182 5.30585 8.89927L8.45588 12.6084L14.6942 5.26291C14.9722 4.93546 15.4465 4.91048 15.7535 5.20711Z"
          fill="#333333"
        />
      </>
    ) : (
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="5.5"
        fill="white"
        stroke="#D2D2D0"
      />
    )}
  </SvgIcon>
);

const FilteredComponent: React.FC<FilterComponentProps> = ({
  selectedOptions1,
  setSelectedOptions1,
  selectedOptions4,
  setSelectedOptions4,
  handleSearchChange,
  resetSearchData,
}) => {
  console.log("selectedOptions4", selectedOptions4);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);

  const [anchorEl4, setAnchorEl4] = useState<null | HTMLElement>(null);

  const handleClick1 = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl1(event.currentTarget);
    handleSearchChange();
  };
  const verifyAccess = getToken();
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  useEffect(() => {
    getAllData();
    getAllSub();
  }, []);
  const [filtype, setFiltype] = useState([]);
  const [subjects, setSubjects] = useState<any>([]);
  const getAllSub = async () => {
    try {
      const response = await getAllSubjects(verifyAccess.token);
      setSubjects(response?.data?.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const getAllData = async () => {
    try {
      const response = await getAllFileType(verifyAccess.token);
      setFiltype(response?.data.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };
  const handleClearAll1 = () => {
    setSelectedOptions1([]);
  };
  const handleClick4 = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl4(event.currentTarget);
    handleSearchChange();
  };

  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  const handleOptionClick4 = (optionId: string) => {
    setSelectedOptions4((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId)) {
        return prevSelectedOptions.filter((item) => item !== optionId);
      } else {
        return [...prevSelectedOptions, optionId];
      }
    });
    handleSearchChange();
  };

  const handleOptionClick1 = (optionId: number) => {
    setSelectedOptions1((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId.toString())) {
        return prevSelectedOptions.filter(
          (item) => item !== optionId.toString()
        );
      } else {
        return [...prevSelectedOptions, optionId.toString()];
      }
    });
    handleSearchChange();
  };
  const handleClearAll4 = () => {
    setSelectedOptions4([]);
  };

  const handleClearAll = () => {
    setSelectedOptions1([]);
    setSelectedOptions4([]);
    resetSearchData();
  };

  console.log("aaaa", filtype, subjects);

  const open1 = Boolean(anchorEl1);
  const open4 = Boolean(anchorEl4);
  const id1 = open1 ? "filter-popover1" : undefined;
  const id4 = open4 ? "filter-popover4" : undefined;

  return (
    <Box
      sx={{
        display: { md: "flex", sm: "flex", xs: "none" },
        alignItems: "center",
      }}
    >
      <Box
        onClick={handleClick4}
        sx={{
          width: {
            md: "190px",
            sm: "140px",
            xs: "114px",
          },
          "@media screen and (max-width:374px)": {
            width: "100px",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
          border:
            anchorEl4 !== null ? "1px solid #FFA737" : "1px solid #D2D2D0",
          padding: "4.5px 12px 4.5px 12px",
          fontSize: "14px",
          fontWeight: "400",
          marginRight: "8px",
          color: "#696969",
          cursor: "pointer",
          backgroundColor:
            anchorEl4 !== null
              ? "#FFF8EC"
              : selectedOptions4.length > 0
                ? "#F4F6FF"
                : "transparent",
          "&:hover": {
            backgroundColor: selectedOptions4.length > 0 ? "" : "#ffa7371f",
          },
        }}
      >
        File type
        {selectedOptions4.length > 0 && `(${selectedOptions4.length})`}
        <Box sx={{ display: "flex" }}>
          <IconComponent name="userArrowDown" fill={"#8E8D8A"} />
        </Box>
      </Box>

      <Popover
        id={id4}
        open={open4}
        anchorEl={anchorEl4}
        onClose={handleClose4}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          maxHeight: "380px",
          ".css-0": {
            cursor: "pointer",
            borderBottom: "1px solid #D2D2D0",
            "&:hover": {
              background: "#ffa7371f",
            },
          },
          ".css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
            border: "1px solid #D2D2D0",
            borderRadius: "8px",
            width: "210px",
          },
          ".css-1s8ndmz": {
            padding: "8px 16px 8px 12px",
            borderBottom: "1px solid #D2D2D0",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ffa7371f",
            },
          },
          ".css-12xgrea-MuiButtonBase-root-MuiCheckbox-root": {
            padding: "0px",
            marginRight: "8px",
          },
        }}
      >
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F4F6FF",
              padding: "12px 16px 12px 12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#696969",
              }}
            >
              {selectedOptions4.length > 0
                ? `${selectedOptions4.length} Selected`
                : "0 Selected"}
            </Typography>
            {selectedOptions4.length > 0 && (
              <button
                style={{
                  padding: "0px",
                  background: "transparent",
                  border: "none",
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#333",
                  borderBottom: "2px solid #FFA737",
                  cursor: "pointer",
                }}
                onClick={handleClearAll4}
              >
                Clear All
              </button>
            )}
          </div>

          {filtype.map((option: any, index: number) => (
            <Box
              key={index}
              style={{
                background: selectedOptions4.includes(option.file_type)
                  ? "#FFF8EC"
                  : "",
              }}
              onClick={() => handleOptionClick4(option.file_type)}
            >
              <Checkbox
                checked={selectedOptions4.includes(option.file_type)}
                onChange={() => {}}
                sx={{":hover":{backgroundColor:"transparent"}}}
                icon={<CustomCheckbox checked={false} />}
                checkedIcon={
                  <CustomCheckbox checked style={{ fill: "#333333" }} />
                }
              />
              <span
                style={{ fontSize: "14px", fontWeight: "400", color: "#333" }}
              >
                {option.file_type}
              </span>
            </Box>
          ))}
        </>
      </Popover>

      <Box
        onClick={handleClick1}
        sx={{
          width: { md: "190px", sm: "140px", xs: "114px" },
          "@media screen and (max-width:374px)": {
            width: "100px",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
          border:
            anchorEl1 !== null ? "1px solid #FFA737" : "1px solid #D2D2D0",
          padding: "4.5px 12px 4.5px 12px",
          fontSize: "14px",
          fontWeight: "400",
          marginRight: "8px",
          color: "#696969",
          cursor: "pointer",
          backgroundColor:
            anchorEl1 !== null
              ? "#FFF8EC"
              : selectedOptions1.length > 0
                ? "#F4F6FF"
                : "transparent",
          "&:hover": {
            backgroundColor: selectedOptions1.length > 0 ? "" : "#ffa7371f",
          },
        }}
      >
        Subject {selectedOptions1.length > 0 && `(${selectedOptions1.length})`}
        <Box sx={{ display: "flex" }}>
          <IconComponent name="userArrowDown" fill={"#8E8D8A"} />
        </Box>
      </Box>

      <Popover
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
            width: "210px",
            border: "1px solid #D2D2D0",
            borderRadius: "8px",
            maxHeight: "380px",
            overflowY: "scroll",
          },
          ".css-1s8ndmz": {
            padding: "8px 16px 8px 12px",
            borderBottom: "1px solid #D2D2D0",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ffa7371f",
            },
          },
          ".css-12xgrea-MuiButtonBase-root-MuiCheckbox-root": {
            padding: "0px",
            marginRight: "8px",
          },
          ".css-oq7pu-MuiButtonBase-root-MuiCheckbox-root": {
            padding: "0px",
            marginRight: "8px",
            marginTop: "3px",
          },
        }}
      >
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F4F6FF",
              padding: "12px 16px 12px 12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#696969",
              }}
            >
              {selectedOptions1.length > 0
                ? `${selectedOptions1.length} Selected`
                : "0 Selected"}
            </Typography>
            {selectedOptions1.length > 0 && (
              <button
                style={{
                  padding: "0px",
                  background: "transparent",
                  border: "none",
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#333",
                  borderBottom: "2px solid #FFA737",
                  cursor: "pointer",
                }}
                onClick={handleClearAll1}
              >
                Clear All
              </button>
            )}
          </div>

          {subjects.map((option: any) => (
            <Box
              key={option.subject_id}
              sx={{
                ".MuiBox-root": {
                  padding: "12px 12px 16px 12px",
                },
              }}
              style={{
                background: selectedOptions1.includes(
                  option.subject_id.toString()
                )
                  ? "#FFF8EC"
                  : "",
              }}
              onClick={() => handleOptionClick1(option.subject_id)}
            >
              <Checkbox
                checked={selectedOptions1.includes(
                  option.subject_id.toString()
                )}
                onChange={() => {}}
                sx={{":hover":{backgroundColor:"transparent"}}}
                icon={<CustomCheckbox checked={false} />}
                checkedIcon={
                  <CustomCheckbox checked style={{ fill: "#333333" }} />
                }
              />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#333",
                }}
              >
                {option.sub_group_code}
              </span>
            </Box>
          ))}
        </Box>
      </Popover>

      <Typography
        onClick={handleClearAll}
        sx={{
          fontSize: { md: "14px", sm: "14px", xs: "12px" },
          lineHeight: { md: "21px", sm: "15px", xs: "15px" },
          fontWeight: { md: "600", sm: "500", xs: "500" },
          color: "#333",
          borderBottom: "2px solid #FFA737",
          cursor: "pointer",
          "@media screen and (max-width:374px)": {
            fontSize: "10px",
          },
        }}
      >
        Clear all
      </Typography>
    </Box>
  );
};

export default FilteredComponent;
