import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { StudentDashboard } from "./pages/StudentDashboard";
import StudentLogin from "./pages/StudentLogin";
import StudentVerification from "./pages/StudentVerification";
import ForgottenPassword from "./pages/StudentFogottenPassword";
import { StudentSubject } from "./pages/StudentSubject";
import { WorkBook } from "./pages/WorkBook";
import SearchResults from "./pages/SearchResults";
import ProtectedRoute from "./utils/ProtectedRoutes";
import ResetPassword from "./pages/ResetPassword";
import Hotjar from '@hotjar/browser';
import ErrorBoundary from "./utils/ErrorBoundary";

function App() {
const siteId = 3918210;
const hotjarVersion = 6;
{process.env?.REACT_APP_PUBLIC_URL?.includes("-uat") && Hotjar.init(siteId, hotjarVersion);}
  return (
    <>
      <Routes>
        <Route path={`/`} element={
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <StudentLogin />
          </ErrorBoundary>
          } />
        <Route path={`/verify-login`} element={
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <StudentVerification />
          </ErrorBoundary>
          } />
        <Route path={`/forgot-password`} element={
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <ForgottenPassword />
          </ErrorBoundary>
          } />
        <Route path={`/reset-password`} element={
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
             <ResetPassword />
          </ErrorBoundary>
         } />
        <Route
          path={`/dashboard`}
          element=
          {
            <ProtectedRoute>
              <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <StudentDashboard />
              </ErrorBoundary>
            </ProtectedRoute>
          }
        />
        <Route
          path={`/subject/:keyword`}
          element=
          {
            <ProtectedRoute>
               <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <StudentSubject />
               </ErrorBoundary>
            </ProtectedRoute>
          }
        />
        <Route
          path={`/aos`}
          element=
          {
            <ProtectedRoute>
               <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <WorkBook />
               </ErrorBoundary>
            </ProtectedRoute>
          }
        />
        <Route
          path={`/search-results`}
          element=
          {
            <ProtectedRoute>
               <ErrorBoundary fallback={<div>Something went wrong</div>}>
                  <SearchResults />
               </ErrorBoundary>
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
