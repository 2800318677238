import buttonStyles from '../styles/button.module.css';
import tooltipStyles from '../styles/tooltip.module.css';

import {
  FullscreenButton,
  MuteButton,
  PIPButton,
  PlayButton,
  SeekButton,
  Tooltip,
  useMediaState,
  type TooltipPlacement,
} from '@vidstack/react';
import {
  FullscreenExitIcon,
  FullscreenIcon,
  MuteIcon,
  PauseIcon,
  PictureInPictureExitIcon,
  PictureInPictureIcon,
  PlayIcon,
  SeekBackward10Icon,
  SeekForward10Icon,
  VolumeHighIcon,
  VolumeLowIcon,
} from '@vidstack/react/icons';
import IconBtnComponent from '../../Video/IconBtnComponent';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
export interface MediaButtonProps {
  tooltipPlacement: TooltipPlacement;
}
export interface NextPrevButtonProps {
  tooltipPlacement: TooltipPlacement;
  fileName: string;
  title: string;
  thumnail: string;
  playNextVideo?:()=>void;
  playPrevVideo?: ()=>void;
}

export function OnHover ({thumbnailUrl,type,title}:any){
  return (<Box
      sx={{
        background: "#FFF",
        padding: "8px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        border: "1px solid #D2D2D0",
        position: "fixed",
        bottom: "12px",
        zIndex: "10",
      }}
    >
      <Box
        sx={{
          maxWidth: "85px",
          maxheight: "60px",
          borderRadius: "4px",
        }}
        component="img"
        src={`${thumbnailUrl}`}
        alt="thumnail"
        crossOrigin="anonymous"
      />
      <Box sx={{ marginLeft: "12px", textAlign: "start !important",
      }}>
        <Typography
          color={"#696969"}
          sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "15px" }}
        >
          {type}
        </Typography>
        <Typography
          color={"#333333"}
          sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "15px",}}
        >
          {title}
        </Typography>
      </Box>
    </Box>)
}

export function Play({ tooltipPlacement }: MediaButtonProps) {
  const isPaused = useMediaState('paused');
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <PlayButton className={`play-button ${buttonStyles.button}`}>
          {isPaused ? <PlayIcon /> : <PauseIcon />}
        </PlayButton>
      </Tooltip.Trigger>
      <Tooltip.Content className={tooltipStyles.tooltip} placement={tooltipPlacement}>
        {isPaused ? 'Play' : 'Pause'}
      </Tooltip.Content>
    </Tooltip.Root>
  );
}
export function Forward10({ tooltipPlacement }: MediaButtonProps) {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <SeekButton className={`vds-button ${buttonStyles.button}`} seconds={10}>
          <SeekForward10Icon className="vds-icon" />
        </SeekButton>
      </Tooltip.Trigger>
      <Tooltip.Content className={tooltipStyles.tooltip} placement={tooltipPlacement}>
        forward
      </Tooltip.Content>
    </Tooltip.Root>
  );
}
export function Prev({ tooltipPlacement,fileName,title,thumnail,playPrevVideo }: NextPrevButtonProps) {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <SeekButton className={`vds-button ${buttonStyles.button}`} seconds={10}>
        <IconBtnComponent
                      name="skipPrevious"
                      clickButton={()=>playPrevVideo && playPrevVideo()}
                      width={24}
                      height={24}
                    />
        </SeekButton>
      </Tooltip.Trigger>
      <Tooltip.Content className={tooltipStyles.tooltip} placement={tooltipPlacement}>
                      <OnHover
                        type={"Prev:"}
                        title={
                            <div>
                              <div>
                                {fileName}
                              </div>
                              <div>{title}</div>
                            </div>
                         
                        }
                        thumbnailUrl={thumnail}
                      />
      </Tooltip.Content>
    </Tooltip.Root>
  );
}
export function Next({ tooltipPlacement,fileName,title,thumnail,playNextVideo }: NextPrevButtonProps) {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <SeekButton className={`vds-button ${buttonStyles.button}`} seconds={10}>
                    <IconBtnComponent
                      name="skipNext"
                      clickButton={()=>playNextVideo && playNextVideo()}
                      width={24}
                      height={24}
                    />
        </SeekButton>
      </Tooltip.Trigger>
      <Tooltip.Content className={tooltipStyles.tooltip} placement={tooltipPlacement}>
      <OnHover
                        type={"Next:"}
                        title={
                            <div>
                              <div>
                                {fileName}
                              </div>
                              <div style={{maxWidth:"300px"}}>{title}</div>
                            </div>
                         
                        }
                        thumbnailUrl={thumnail}
                      />
      </Tooltip.Content>
    </Tooltip.Root>
  );
}
export function Backward({ tooltipPlacement }: MediaButtonProps) {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
      <SeekButton className={`vds-button ${buttonStyles.button}`} seconds={-10}>
          <SeekBackward10Icon className="vds-icon" />
        </SeekButton>
      </Tooltip.Trigger>
      <Tooltip.Content className={tooltipStyles.tooltip} placement={tooltipPlacement}>
        backward
      </Tooltip.Content>
    </Tooltip.Root>
  );
}

export function Mute({ tooltipPlacement }: MediaButtonProps) {
  const volume = useMediaState('volume'),
    isMuted = useMediaState('muted');
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <MuteButton className={`mute-button ${buttonStyles.button}`}>
          {isMuted || volume == 0 ? (
            <MuteIcon />
          ) : volume < 0.5 ? (
            <VolumeLowIcon />
          ) : (
            <VolumeHighIcon />
          )}
        </MuteButton>
      </Tooltip.Trigger>
      <Tooltip.Content className={tooltipStyles.tooltip} placement={tooltipPlacement}>
        {isMuted ? 'Unmute' : 'Mute'}
      </Tooltip.Content>
    </Tooltip.Root>
  );
}

export function PIP({ tooltipPlacement }: MediaButtonProps) {
  const isActive = useMediaState('pictureInPicture');
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <PIPButton className={`pip-button ${buttonStyles.button}`}>
          {isActive ? <PictureInPictureExitIcon /> : <PictureInPictureIcon />}
        </PIPButton>
      </Tooltip.Trigger>
      <Tooltip.Content className={tooltipStyles.tooltip} placement={tooltipPlacement}>
        {isActive ? 'Exit PIP' : 'Enter PIP'}
      </Tooltip.Content>
    </Tooltip.Root>
  );
}

export function Fullscreen({ tooltipPlacement }: MediaButtonProps) {
  const isActive = useMediaState('fullscreen');
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <FullscreenButton className={`fullscreen-button ${buttonStyles.button}`}>
          {isActive ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </FullscreenButton>
      </Tooltip.Trigger>
      <Tooltip.Content className={tooltipStyles.tooltip} placement={tooltipPlacement}>
        {isActive ? 'Exit Fullscreen' : 'Enter Fullscreen'}
      </Tooltip.Content>
    </Tooltip.Root>
  );
}
