import React from "react";
import { Box, Typography, Popover, Skeleton } from "@mui/material";
import Icons from "../Icons";
import { useNavigate } from "react-router-dom";

interface dropProps {
  dropdown?: boolean;
  onClickCallback?: (status: string) => void;
  studentSubjectsData?: any;
  selectedSubjectId?: string;
  loadingSubDrop?: boolean;
}

const HeaderPopover = ({
  onClickCallback,
  studentSubjectsData,
  selectedSubjectId,
  loadingSubDrop,
}: dropProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCHange = (dynamicValue: string, subCode: string) => {
    const subjectCode = subCode.split(" ")[0];
    navigate(`/subject/${subjectCode}`, {
      state: {
        subjectId: dynamicValue,
      },
    });
    setAnchorEl(null);
    if (onClickCallback) onClickCallback(dynamicValue);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Box
        sx={{
          cursor: "pointer",
          "@media screen and (max-width:768px)": {
            display: "none",
          },
        }}
        aria-describedby={id}
        component={"button"}
        bgcolor={anchorEl !== null ? "#FFF8EC" : "white"}
        display={"flex"}
        flexDirection={"row"}
        gap={"8px"}
        alignItems={"center"}
        padding={"8px"}
        borderRadius={"8px"}
        border={
          anchorEl !== null ? "1px solid #FFA737" : "1px solid transparent"
        }
        onClick={handleClick}
      >
        {loadingSubDrop ? (
          <Skeleton variant="rounded" width={"200px"} height={"30px"} />
        ) : (
          <>
            <Typography color={"#333"} variant="bodySm">
              {studentSubjectsData &&
                studentSubjectsData[0]?.subjects?.filter(
                  (subject: any) => subject?.subject_id == selectedSubjectId,
                )[0]?.subject}
            </Typography>
            <Icons name="userArrowDown" fill={"#8E8D8A"} />
          </>
        )}
      </Box>
      <Popover

        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",

        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",

        }}

        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "12px"
          }
        }}
      >
        <Box
          sx={{ borderRadius: "8px", width: "208px" }}
          display={"flex"}
          flexDirection={"column"}
        >
          <Box
            paddingX={"12px"}
            paddingY={"8px"}
            bgcolor={"#F4F6FF"}
            borderBottom={"1px solid #D2D2D0"}
          >
            <Typography
              color={"#333333"}
              fontWeight={"500"}
              fontSize={"12px"}
              lineHeight={"15px"}
            >
              You’re currently in:
            </Typography>
          </Box>
          <Box
            component={"div"}
            paddingX={"16px"}
            paddingY={"12px"}
            display={"flex"}
            flexDirection={"row"}
            gap={"8px"}
            alignItems={"center"}
            borderBottom={"1px solid #D2D2D0"}
          >
            <Typography
              variant="body2Medium"
              fontWeight={"400"}
              sx={{ cursor: "pointer" }}
            >
              {studentSubjectsData &&
                studentSubjectsData[0]?.subjects?.filter(
                  (subject: any) => subject?.subject_id == selectedSubjectId,
                )[0]?.subject}
            </Typography>
          </Box>
          <Box
            paddingX={"12px"}
            paddingY={"8px"}
            bgcolor={"#F4F6FF"}
            borderBottom={"1px solid #D2D2D0"}
          >
            <Typography
              color={"#333333"}
              fontWeight={"500"}
              fontSize={"12px"}
              lineHeight={"15px"}
            >
              Other subjects:
            </Typography>
          </Box>
          {studentSubjectsData &&
            studentSubjectsData[0]?.subjects
              ?.filter((item: any) => item?.subject_id != selectedSubjectId)
              ?.map((sub: any) => (
                <Box
                  component={"div"}
                  paddingX={"16px"}
                  paddingY={"12px"}
                  display={"flex"}
                  flexDirection={"row"}
                  sx={{ cursor: "pointer" }}
                  gap={"8px"}
                  alignItems={"center"}
                  borderBottom={
                    sub.subject_id !=
                      studentSubjectsData[0]?.subjects?.length - 1
                      ? "1px solid #D2D2D0"
                      : "0px"
                  }
                  onClick={() => handleCHange(sub.subject_id, sub.subject)}
                >
                  <Typography variant="body2Medium" fontWeight={"400"}>
                    {sub?.subject}
                  </Typography>
                </Box>
              ))}
        </Box>
      </Popover>
    </>
  );
};

export default HeaderPopover;
