import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import LoginDots from "../assets/images/LoginBannerDots.png";
import LoginLadyImg from "../assets/images/LoginLadyImg.png";

interface ForgottenPasswordProps {
  children?: ReactNode;
}

const Loginlayout: React.FC<ForgottenPasswordProps> = ({ children }) => {
  return (
    <Box
      sx={{ backgroundColor: "#FBF2E4", height: "100%", position: "relative" ,  minHeight:"100vh",}}
    >
      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          height: "100%",
          zIndex: "1",
          objectFit:"cover",
          width: "70%",
          "@media screen and (max-width:767px)": {
            display: "none",
          },
        }}
        component="img"
        src={LoginDots}
        alt="LoginSmily"
      />
      <Box
        sx={{
          position: "absolute",
          bottom: "0",
          right: "0",
          width: "100%",
          zIndex: "10",
          height: "100%",
          objectFit:"cover",
          "@media screen and (max-width:767px) ": {
            height: '242px',
            width: '435px'
          },
        }}
        component="img"
        src={LoginLadyImg}
        alt="LoginSmily"
      />
      {children}
    </Box>
  );
};

export default Loginlayout;
