import Box from "@mui/material/Box";
import React from "react";
import Header from "../components/Header";

interface dashboardprops {
  children: React.ReactNode;
  showPopover: boolean;
  selectedSubjectId?: string;
  onClickCallback?: (status: string) => void;
}

const Dashboard = ({
  children,
  showPopover,
  selectedSubjectId,
  onClickCallback,
}: dashboardprops) => {
  return (
    <Box
      sx={{
        bgcolor: "background.default",
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Header
        showPopover={showPopover}
        selectedSubjectId={selectedSubjectId}
        onClickCallback={(value) => {
          if (onClickCallback) onClickCallback(value);
        }}
      />

      <Box
      >
        {children}
      </Box>
    </Box>
  );
};

export default Dashboard;
