import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface ErrorState {
    type:string|null
    message:string|null
    time:string|null
}

const initialState: ErrorState = {
    type:null,
    message:null,
    time:null
};
export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ErrorState>) => {
      const { type, message, time } = action.payload;
      state.type = type;
      state.time = time;
      state.message = message;
    },
    clearNotification: (state) => {
        state.message=null
        state.type=null
        state.time=null
    },
  },
});


export const { setError,clearNotification } = errorSlice.actions;
export const errorReducer = errorSlice.reducer;
