import captionStyles from "../../styles/captions.module.css";
import styles from "../../styles/video-layout.module.css";

import { Captions, Controls, Gesture } from "@vidstack/react";

import * as Buttons from "../buttons";
import * as Sliders from "../sliders";
import { TimeGroup } from "../time-group";


interface NEXTPREVDETAILS {
  nextVideo:{title:string,filename:string,thumnail:string}|undefined;
  prevVideo:{title:string,filename:string,thumnail:string}|undefined;
}
export interface VideoLayoutProps {
  thumbnails?: string;
  nextprevvideDetails:NEXTPREVDETAILS
  playNextVideo: ()=>void;
  playPrevVideo: ()=>void;
}

export function VideoLayout({
  thumbnails,
  nextprevvideDetails,
  playNextVideo,
  playPrevVideo,
}: VideoLayoutProps) {
  const {nextVideo,prevVideo}=nextprevvideDetails
  return (
    <>
      <Gestures />
      <Captions className={captionStyles.captions} />
      <Controls.Root className={styles.controls}>
        <div className={styles.spacer} />
        {thumbnails !== null && (
          <Controls.Group className={styles.controlsGroup}>
            <Sliders.Time thumbnails={thumbnails} />
          </Controls.Group>
        )}
        <Controls.Group className={styles.controlsGroup}>
          <Buttons.Play tooltipPlacement="top start" />
          {prevVideo &&<Buttons.Prev
            tooltipPlacement="top start"
            thumnail={prevVideo.thumnail}
            fileName={prevVideo.filename}
            title={prevVideo.title}
            playPrevVideo={()=>playPrevVideo()}
          />}
          {nextVideo && <Buttons.Next
            tooltipPlacement="top end"
            thumnail={nextVideo.thumnail}
            fileName={nextVideo.filename}
            title={nextVideo.title}
            playNextVideo={()=>playNextVideo()}
          />}
          <Buttons.Backward tooltipPlacement="top start" />
          <Buttons.Forward10 tooltipPlacement="top start" />
          <Buttons.Mute tooltipPlacement="top" />
          <Sliders.Volume />
          <TimeGroup />
          <div className={styles.spacer} />
          <Buttons.PIP tooltipPlacement="top" />
          <Buttons.Fullscreen tooltipPlacement="top end" />
        </Controls.Group>
      </Controls.Root>
    </>
  );
}

function Gestures() {
  return (
    <>
      <Gesture
        className={styles.gesture}
        event="pointerup"
        action="toggle:paused"
      />
      <Gesture
        className={styles.gesture}
        event="dblpointerup"
        action="toggle:fullscreen"
      />
      <Gesture
        className={styles.gesture}
        event="pointerup"
        action="toggle:controls"
      />
      <Gesture
        className={styles.gesture}
        event="dblpointerup"
        action="seek:-10"
      />
      <Gesture
        className={styles.gesture}
        event="dblpointerup"
        action="seek:10"
      />
    </>
  );
}
