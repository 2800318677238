import React from "react";
import { Box, Link } from "@mui/material";
import BugleftPopup from "../Modal/BugLeftPopup";
import BugDrawer from "../BottomDrawer/BugDrawer";
import MinimizedBugPop from "../BugReport/MinimizedBugPop";
import withErrorBoundary from "../../utils/withErrorBoundary";

type ToastState = {
  type: string;
  message: string;
};

interface RecordingComponentProps {
  bugTitle?: string;
  bugDescription?: string;
  attachment?: (File | string)[];
  handleBugTitleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBugDescriptionChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  captureScreenshot?: () => void;
  setAttachment?: React.Dispatch<React.SetStateAction<(File | string)[]>>;
  setToast?: React.Dispatch<React.SetStateAction<ToastState | null>>;
  bugRepId?: string;
  setBugTitle?: React.Dispatch<React.SetStateAction<string>>;
  setBugDescription?: React.Dispatch<React.SetStateAction<string>>;
  selectedSubModule?: {
    id: string;
    module: string;
  };
  selectedType?: string | undefined;
  titleError: boolean;
  descriptionError: boolean;
  open: any;
  setOpen: any;
  openBottomSheet: any;
  setOpenBottomSheet: any;
}

const ReportBugBtn: React.FC<RecordingComponentProps> = ({
  bugTitle,
  bugDescription,
  attachment,
  handleBugTitleChange,
  handleBugDescriptionChange,
  captureScreenshot,
  setAttachment,
  setToast,
  bugRepId,
  setBugTitle,
  setBugDescription,
  selectedSubModule,
  selectedType,
  titleError,
  descriptionError,
  open,
  setOpen,
  openBottomSheet,
  setOpenBottomSheet,
}) => {
  const [showMinimizedBugPop, setShowMinimizedBugPop] = React.useState(false);
  const [showMobileMinimize, setShowMobileMinimize] = React.useState(false);

  const handleClickOpen = () => {
    setShowMinimizedBugPop(false);
    setOpen(true);
  };
  const handleOpenSheet = () => {
    setShowMobileMinimize(false);
    setOpenBottomSheet(true);
  };
  const handleMinimize = () => {
    setShowMinimizedBugPop(true);
    setOpen(false);
  };
  const handleCloseMinimizedBugPop = () => {
    setShowMinimizedBugPop(false);
    setBugTitle && setBugTitle("");
    setBugDescription && setBugDescription("");
    setAttachment && setAttachment([]);
  };
  const handleMobileMax = () => {
    setShowMobileMinimize(false);
  };
  const handleMobileminize = () => {
    setShowMobileMinimize(true);
    setOpenBottomSheet(false);
  };
  const handleCloseAll = () => {
    setShowMobileMinimize(false);
    setOpenBottomSheet(false);
  };

  const handleClose = () => {
    setOpen(false);
    setBugTitle && setBugTitle("");
    setBugDescription && setBugDescription("");
    setAttachment && setAttachment([]);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            marginTop: "12px",
            display: { md: "flex", sm: "flex", xs: "none" },
          }}
        >
          <Link
            underline="none"
            component={"button"}
            sx={{
              color: "contour.title3",
              borderBottom: "2px solid ",
              borderColor: "contour.orange",
              fontSize: "14px",
              fontFamily: "Inter",
              fontWeight: 600,
              width: "95px",
            }}
            onClick={handleClickOpen}
          >
            Report a bug
          </Link>
        </Box>
        <BugleftPopup
          open={open}
          handleClose={handleMinimize}
          bugRepId={bugRepId}
          cancel={() => handleClose()}
          bugTitle={bugTitle}
          bugDescription={bugDescription}
          handleBugTitleChange={handleBugTitleChange}
          handleBugDescriptionChange={handleBugDescriptionChange}
          titleError={titleError}
          descriptionError={descriptionError}
          attachment={attachment}
          captureScreenshot={captureScreenshot}
          setAttachment={setAttachment}
          setToast={setToast}
          selectedSubModule={selectedSubModule}
          selectedType={selectedType}
        />
        {showMinimizedBugPop && (
          <MinimizedBugPop
            expand={handleClickOpen}
            close={handleCloseMinimizedBugPop}
          />
        )}
        <Box
          sx={{
            marginTop: "12px",
            display: { md: "none", sm: "none", xs: "flex" },
          }}
        >
          <Link
            underline="none"
            component={"button"}
            sx={{
              color: "contour.title3",
              borderBottom: "2px solid ",
              borderColor: "contour.orange",
              fontSize: "14px",
              fontFamily: "Inter",
              fontWeight: 600,
              width: "95px",
            }}
            onClick={handleOpenSheet}
          >
            Report a bug
          </Link>
        </Box>
        {showMobileMinimize && (
          <MinimizedBugPop
            expand={() => setOpenBottomSheet(true)}
            close={handleMobileMax}
          />
        )}
        <BugDrawer
          open={openBottomSheet}
          onClose={() => setOpenBottomSheet(false)}
          headerText={"Report a bug"}
          cancel={handleCloseAll}
          bugRepId={bugRepId}
          minimized={handleMobileminize}
          bugTitle={bugTitle}
          bugDescription={bugDescription}
          handleBugTitleChange={handleBugTitleChange}
          handleBugDescriptionChange={handleBugDescriptionChange}
          titleError={titleError}
          descriptionError={descriptionError}
          attachment={attachment}
          captureScreenshot={captureScreenshot}
          setAttachment={setAttachment}
        />
        {/* Mobile  */}
      </Box>
    </>
  );
};

export default withErrorBoundary(ReportBugBtn,<>Something went wrong with ReportBugBtn Component</>);
