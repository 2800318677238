import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BottomDrawer from "../BottomDrawer";
import Icons from "../Icons";
import { getAllStudentSelectedSubjects } from "../../services/api/subject";
import { getToken } from "../../services/api/login";
import { useNavigate } from "react-router-dom";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface SubjectBtnBtmSheetProps {
  currentSubjectId?: string;
}



const SubjectBtnBtmSheet: React.FC<SubjectBtnBtmSheetProps> = ({
  currentSubjectId,
}) => {
  const [bottomDrawerOpen, setBottomDrawerOpen] = useState(false);
  const verifyAccess = getToken();
  const [studentSubjectsData, setStudentSubjectsData] = React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const navigate = useNavigate();
  const handleClick = () => {
    setBottomDrawerOpen(true);
  };

  const getAllSubjectsData = async () => {
    try {
      const response = await getAllStudentSelectedSubjects(verifyAccess.token);
      setStudentSubjectsData(response.data.data);
    } catch (error) {
      console.error("Error fetching student subjects data:", error);
    }
  };

  useEffect(() => {
    getAllSubjectsData();
  }, [currentSubjectId]);

  const handleCHange = (dynamicValue: string, subCode: string) => {
    const newSearch = new URLSearchParams();
    newSearch.set('sid', dynamicValue);
    // Push new URL with updated query parameter
    const subjectCode = subCode.split(" ")[0];

    navigate(`/subject/${subjectCode}?${newSearch.toString()}`);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box
      sx={{
        "@media screen and (min-width:768px)": {
          display: "none",
        },
      }}
      aria-describedby={id}
    >
      <Box
        component={"div"}
        onClick={handleClick}
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          padding: "12px",
          border: "1px solid #D2D2D0",
          borderRadius: "8px",
          marginX: "16px",
          marginY: "8px",
          gap: "8px",
        }}
      >
        <Typography
          color={"#333"}
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "18.2px",
            marginRight: "auto",
          }}
        >
          Subject:{" "}
          {studentSubjectsData &&
            studentSubjectsData[0]?.subjects?.filter(
              (subject: any) => subject?.subject_id == currentSubjectId
            )[0]?.subject}
        </Typography>
        <Icons name="userArrowDown" fill={"#8E8D8A"} />
      </Box>
      <BottomDrawer
        open={bottomDrawerOpen}
        onClose={() => setBottomDrawerOpen(false)}
      >
        <Typography
          color={"#333"}
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "18.2px",
            paddingTop: "16px",
            paddingX: "16px",
          }}
        >
          You’re currently in:{" "}
          {studentSubjectsData &&
            studentSubjectsData[0]?.subjects?.filter(
              (subject: any) => subject?.subject_id == currentSubjectId
            )[0]?.subject}
        </Typography>
        <Typography
          color={"#333"}
          sx={{
            fontFamily: "PolySans_Neutral",
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "18.2px",
            paddingTop: "28px",
            paddingBottom: "8px",
            paddingX: "16px",
          }}
        >
          Other subjects:
        </Typography>

        {studentSubjectsData &&
            studentSubjectsData[0]?.subjects
              ?.filter((item: any) => item?.subject_id != currentSubjectId)
              ?.map((sub: any, index:any) => (
                <Box
                key={index}
                component={"div"}
                onClick={() => handleCHange(sub.subject_id, sub.subject)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingY: "12px",
                  paddingX: "16px",
                  borderBottom: "1px solid #EFEFEF",
                  cursor: "pointer",
                }}
              >
                <Typography
                  color={"#333"}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "18.2px",
                    marginRight: "auto",
                  }}
                >
                  {sub?.subject}
                </Typography>
                <Icons
                  name="chevronRight"
                  fill={"#8E8D8A"}
                  width={24}
                  height={24}
                />
              </Box>
              ))}
        <Box sx={{ paddingBottom: "24px" }} />
      </BottomDrawer>
    </Box>
  );
};

export default withErrorBoundary(SubjectBtnBtmSheet,<>Something went wrong with SubjectBtnBtmSheet Component</>);
