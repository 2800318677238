import React, { memo, useRef } from "react";
import screenfull from "screenfull";
import { Box, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import DocViewer, { PDFRenderer } from "@cyntler/react-doc-viewer";
import "../../components/PdfViewer/pdfFile.css";
import IconComponent from "../Icons";
import { usePreviousValue } from "../../store/hooks";
import { aosLogFileAccessTime, decodePDF } from "../../services/api/subject";
import { getToken } from "../../services/api/login";
import { formatDate } from "../../utils/utilfns";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface FilesPreviewProps {
  fileUrl: string;
  fileId: number;
  password: string;
  isFullContainer:boolean;
}
const FilesPreview: React.FC<FilesPreviewProps> = memo(({ fileUrl, fileId, password, isFullContainer }) => {
  const [docs, setDocs] = React.useState<any>([{ uri: "" }]);
  const previousValue = usePreviousValue(fileUrl)
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const docViewerRef = useRef<HTMLDivElement>(null);
  const pdfRef = useRef<any>(null);
  const [isFullscreen, setIsFullscreen] = React.useState(screenfull.isFullscreen);
  const [pdfLoading, setpdfLoading] = React.useState(true)
  const [pdfLoadingError, setpdfLoadingError] = React.useState(false)
  const handleFileLogTime = async (file_id: any, startTime: any, endTime: any) => {
    const verifyAccess = getToken();
    try {
      const payload = {
        file_id: file_id,
        starttime: startTime,
        endtime: endTime,
      };
      const response = await aosLogFileAccessTime(payload, verifyAccess.token);
      console.log("file log time", response?.data.data);
    } catch (error) {
      console.error("Error fetching file type details:", error);
    }
  };
  React.useEffect(() => {
    setDocs([{ uri: ""}]);
    setpdfLoading(true)
    const verifyAccess = getToken();
    decodePDF(verifyAccess.token,fileId,password).then(async(res)=>{
      if(res.status){
        console.log("respdf",res)
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(pdfBlob);
         setDocs([{ uri: url}]);
         setpdfLoading(false)

      }else{
      setDocs([{ uri: ""}]);
      setpdfLoadingError(true)
      setpdfLoading(false)


      }
    }).catch(()=>{
      setDocs([{ uri: ""}]);
      setpdfLoadingError(true)

    setpdfLoading(false)

    })
  }, [fileUrl]);
  React.useEffect(() => {
    console.log("pdfviewer mounted", previousValue, fileId)
    const currentTime = formatDate()
    return () => {
      const endtime = formatDate()
      handleFileLogTime(
        fileId,
        currentTime,
        endtime
      );
      console.log("pdfviewer un mounted", previousValue, endtime, currentTime)
    }
  }, [fileUrl])
  React.useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(screenfull.isFullscreen);
    };

    screenfull.on("change", handleFullscreenChange);

    return () => {
      screenfull.off("change", handleFullscreenChange);
    };
  }, []);

  React.useEffect(()=>{
    if(docViewerRef.current){
      docViewerRef.current.addEventListener('copy', (event)=>event.preventDefault());
    }
  },[docViewerRef])
  const handleFullScreenMode = () => {
    if (docViewerRef.current) {
      screenfull.toggle(docViewerRef.current);
    }
  };

  const MyLoadingRenderer = () => {
  
    if (pdfLoading) {
      return <div>Loading ...</div>;
    }
  
    return <div>Loading Renderer...</div>;
  };
  const MyNoRenderer = ({ document, fileName }:any) => {
    const fileText = fileName || document?.fileType || "";
    console.log("renderer",fileName,document)
    if (document.uri!=="") {
      return <div>No Renderer Error! ({fileText})</div>;
    }
  
    return <></>;
  };
  
  return (
    <Box className={"pdfMain"} sx={{ position: "relative" }} ref={docViewerRef}>
      {isDesktop ? pdfLoading ? (
        <Skeleton variant="rounded" width={"100%"} height={'calc(100vh - 180px)'} animation="wave" />
      ): (
        pdfLoadingError?
          (<Box width={"100%"} height={'calc(100vh - 176px)'} sx={{display:'flex',justifyContent:'center',alignItems:'center'}} >
            <Typography variant="caption">
              No PDF to render
            </Typography>
          </Box>)
        :
      <DocViewer
        pluginRenderers = { [PDFRenderer] }
        ref = { pdfRef }
        documents = { docs }
        config = {{
        pdfVerticalScrollByDefault: true,
        loadingRenderer: {
          overrideComponent: MyLoadingRenderer
        },
        noRenderer: {
          overrideComponent: MyNoRenderer
        },
        header: {
          disableHeader: true,
          disableFileName: false,
          retainURLParams: false,
        },
        pdfZoom: {
          defaultZoom: isFullContainer? 1:0.7,// 1 as default,
          zoomJump: 0.1, // 0.1 as default,
        },
      }}
      style={{
        // height: "100vh",
        height: isFullscreen ? "100vh" : "calc(100vh - 200px)",
        backgroundColor: "#2B3258",
        overflowY: "hidden",
        borderRadius: isFullscreen ? "0px" : "16px",
      }}
      />)
       :
        (pdfLoading ? (
        <Skeleton variant="rounded" width={"100%"} height={'calc(100vh - 176px)'} animation="wave" />
        ) : (pdfLoadingError?
          (<Box width={"100%"} height={'calc(100vh - 176px)'} >
            <Typography variant="caption">
              No PDF to render
            </Typography>
          </Box>)
        :
        <DocViewer
          pluginRenderers={[PDFRenderer]}
          documents={docs}
          ref={pdfRef}
          config={{
            pdfVerticalScrollByDefault: true,
            loadingRenderer:{
              overrideComponent:MyLoadingRenderer
            },
            noRenderer:{
              overrideComponent:MyNoRenderer
            },
            header: {
              disableHeader: true,
              disableFileName: false,
              retainURLParams: false,
            },
            pdfZoom: {
              defaultZoom: 0.5,// 1 as default,
              zoomJump: 0.1, // 0.1 as default,
            },
          }}
          style={{
            height: isFullscreen ? "100vh" : "calc(100vh - 200px)",
            backgroundColor: "#2B3258",
            overflowY: "hidden",
            borderRadius: isFullscreen ? "0px" : "16px",
          }}
        />)
      )
        }
      {pdfLoading ? ''  :
        <Box
        sx={{
          position: "absolute",
            top: { md: "8px", sm: "6px", xs: "6px" },
          right:{md:"30px",sm:"20px",xs:"20px"},
          padding: "4px",
          display: "flex",
          border: "1px solid transparent",
          zIndex: "10",
          ":hover": {
            border: "1px solid #D2D2D0",
            background: "#FFF8EC1A",
            borderRadius: "8px",
          },
        }}
        onClick={handleFullScreenMode}
      >
        <IconComponent
          name="expandVideoFull"
          fill={"#FFFFFF"}
          width={20}
          height={20}
        />
      </Box>}
    </Box>
  );
});

export default withErrorBoundary(FilesPreview,<>Something went wrong with FilesPreview Component</>);
