import React, { useState, useEffect } from "react";
import Loginlayout from "../../layouts/LoginLayout";
import { Box, TextField, Typography } from "@mui/material";
import Button from "../../components/Button";
import BorderText from "../../components/Label/BorderText";
import IconComponent from "../../components/Icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyOtpThunk } from "../../store/AuthSlice";
import { AppDispatch } from "../../store/index";
import Cookies from "js-cookie";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken, resendOtp } from "../../services/api/login";

interface FormData {
  otp: string;
}

const StudentVerification = () => {
  const loginToken = Cookies.get("loginToken");
  const dispatch: AppDispatch = useDispatch();
  const verifyAccess = getToken();
  const [loading, setLoading] = useState(false);
  const [emailData, setEmailData] = useState({ email: "" });
  const [formData, setFormData] = useState<FormData>({
    otp: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!loginToken) {
      navigate("/");
    } else if (verifyAccess) {
      navigate("/dashboard");
    }
  }, [loginToken, verifyAccess]);

  const handleBack = () => {
    Cookies.remove("loginToken");
    navigate("/");
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const handleVerifyStudent = async () => {
    if (formData.otp.length === 6) {
      setLoading(true);
      try {
        const action = await dispatch(
          verifyOtpThunk({ otp: formData.otp, token: loginToken ?? "" })
        );
        if (verifyOtpThunk.fulfilled.match(action)) {
          localStorage.setItem(
              "profileFirstName",
              action?.payload?.first_name,
            );
          navigate("/dashboard");
          sessionStorage.removeItem("userData");
          setLoading(false);
          toast.success(`${action.payload.data.message}`, {
            position: "top-right",
            autoClose: 2500,
          });
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            otp: "",
          }));
          setLoading(false);
          const errorMessage = (action.payload as string) || "Invalid OTP";
          toast.error(`${errorMessage}`, {
            position: "top-right",
            autoClose: 2500,
          });
        }
      } catch (error) {
        console.error("Error during API call:", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const savedUserData = sessionStorage.getItem("userData");
    if (savedUserData) {
      setEmailData(JSON.parse(savedUserData));
    }
  }, []);

  const handleResendOtp = async () => {
    try {
      const payload = {
        email: emailData.email,
      };
      const response = await resendOtp(payload, loginToken ?? "");
      if (response && response.data) {
        toast.success(`${response.data.message}`, {
          position: "top-right",
          autoClose: 2500,
        });
      } else {
        toast.error(`${response.data.message}`, {
          position: "top-right",
          autoClose: 2500,
        });
      }
    } catch (error) {
      console.error("Forgot api", error);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (formData.otp.length === 6) {
        handleVerifyStudent();
      }
    }
  };

  return (
    <>
      <Loginlayout>
        <Box
          sx={{
            background: "#FFF",
            maxWidth: "350px",
            width: "100%",
            paddingX: "36px",
            paddingTop: "40px",
            paddingBottom: "10px",
            borderRadius: "16px",
            transform: "translate(50%, 11%)",
            top: "20%",
            bottom: "-50%",
            zIndex: "1000",
            position: "relative",
            boxShadow: "0px 4px 20px 0px rgba(45, 45, 45, 0.16)",
            "@media screen and (max-width:767px)": {
              top: "40px",
              left: "50%",
              transform: "translate(-50%, 0%)",
              paddingX: "16px",
              maxWidth: "310px",
            },
            "@media screen and (min-width:767px) and (max-width:1024px)": {
              top: "40%",
              right: "100px",
            },
            "@media screen and (max-width:350px) ": {
              maxWidth: "265px",
            },
          }}
        >
          <div
            style={{ maxWidth: "54px", cursor: "pointer" }}
            onClick={handleBack}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconComponent name="chevronLeft" fill={"#8E8D8A"} />
              <BorderText text={"Back"} />
            </Box>
          </div>
          <Box
            sx={{
              marginTop: "40px",
              marginBottom: "78px",
              "@media screen and (max-width:768px)": {
                marginY: "24px",
              },
            }}
          >
            <Typography
              color={"contour.title3"}
              variant="title2"
              sx={{
                "@media screen and (max-width:768px)": {
                  fontSize: "24px",
                  letterSpacing: "0.48px",
                },
              }}
            >
              Enter a verification code
            </Typography>
            <Typography
              sx={{ marginTop: "8px" }}
              color={"#333333"}
              variant="h6"
              fontSize={16}
            >
              The code was just sent to your email
            </Typography>
            <Box
              width={"100%"}
              sx={{
                paddingY: "40px",
                "@media screen and (max-width:768px)": {
                  paddingY: "24px",
                },
              }}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{ marginBottom: "4px" }}
              >
                Verification code
              </Typography>
              <TextField
                id="otp"
                value={formData.otp}
                onChange={handleInputChange}
                variant="outlined"
                onKeyDown={handleKeyPress}
                inputProps={{
                  pattern: "[0-9]*",
                  inputMode: "numeric",
                  maxLength: 6,
                  autoComplete: "off",
                }}
                sx={{
                  marginBottom: "8px",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                  ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #C61341",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                    color: "#181A20",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root": {
                    marginLeft: 0,
                  },
                }}
              />
            </Box>
            <Box sx={{ maxWidth: "168px" }}>
                <Button
                loading={loading}
                  text="Verify"
                  textStyle={{
                    color: "#333",
                    cursor:
                      formData.otp.length !== 6 ? "not-allowed" : "pointer",
                    opacity: formData.otp.length !== 6 ? "0.5" : "1",
                    pointerEvents: formData.otp.length !== 6 ? "none" : "auto",
                  }}
                  handleClick={handleVerifyStudent}
                />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                marginTop: "20px",
              }}
            >
              <IconComponent
                name="undo"
                fill={"#333333"}
                width={16}
                height={16}
              />
              <BorderText text={"Resend code"} onClick={handleResendOtp} />
            </Box>
          </Box>
        </Box>
      </Loginlayout>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        pauseOnHover
        hideProgressBar
        transition={Zoom}
      />
    </>
  );
};

export default StudentVerification;
