import React, { ChangeEvent, useState, useEffect } from "react";
import Loginlayout from "../../layouts/LoginLayout";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import LoginBanner from "../../assets/images/ContourLogo.png";
import Button from "../../components/Button";
import BorderText from "../../components/Label/BorderText";
import IconComponent from "../../components/Icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authenticateWithCredentials } from "../../store/AuthSlice";
import { AppDispatch } from "../../store/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "../../services/api/login";

interface FormData {
  email: string;
  password: string;
}

const StudentLogin = () => {
  const navigate = useNavigate();
  const verifyAccess = getToken();
  const dispatch: AppDispatch = useDispatch();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleForgotPswrd = () => {
    sessionStorage.setItem("userData", JSON.stringify(formData));
    navigate("/forgot-password");
  };

  const validateEmail = (email: string): boolean => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password: string): boolean => {
    const specialChars = /^(?=.*[a-z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    return specialChars.test(password);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    if (id === "email") {
      const isValid = validateEmail(value);
      setIsEmailValid(isValid);
    } else {
      const isValidd = validatePassword(value);
      setIsPasswordValid(isValidd);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    if (verifyAccess) {
      navigate("/dashboard");
    }
  }, [verifyAccess]);

  useEffect(() => {
    const savedUserData = sessionStorage.getItem("userData");
    if (savedUserData) {
      setFormData(JSON.parse(savedUserData));
    }
  }, []);

  const handleSignIn = async () => {
    if (formData.email !== "" && formData.password !== "") {
      const isEmailValid = validateEmail(formData.email);
      const isPasswordValid = validatePassword(formData.password);

      setIsEmailValid(isEmailValid);
      setIsPasswordValid(isPasswordValid);
      if (isEmailValid && isPasswordValid) {
        setLoading(true);
        try {
          const action = await dispatch(
            authenticateWithCredentials({
              email: formData.email,
              password: formData.password,
            })
          );
          if (authenticateWithCredentials.fulfilled.match(action)) {
            navigate("/verify-login");
            sessionStorage.setItem("userData", JSON.stringify(formData));
            toast.success(`${action.payload.data.message}`, {
              position: "top-right",
              autoClose: 2500,
            });
            setLoading(false);
          } else {
            const errorMessage =
              (action.error.message as string) || "Unknown error";
            setLoading(false);
            toast.error(`${errorMessage}`, {
              position: "top-right",
              autoClose: 2500,
            });
          }
        } catch (error) {
          console.error("Error during API call:", error);
          setLoading(false);
        }
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (formData.email.trim() !== "" && formData.password.trim() !== "") {
        handleSignIn();
      }
    }
  };

  const isResetButtonDisabled =
    formData.email && formData.password && isEmailValid && isPasswordValid;

  return (
    <>
      <Loginlayout>
        <Box
          sx={{
            background: "#FFF",
            maxWidth: "350px",
            width: "100%",
            paddingX: "36px",
            paddingTop: "40px",
            paddingBottom: "10px",
            borderRadius: "16px",
            transform: "translate(50%, 11%)",
            top: "20%",
            bottom: "-50%",
            zIndex: "1000",
            position: "relative",
            boxShadow: "0px 4px 20px 0px rgba(45, 45, 45, 0.16)",
            "@media screen and (max-width:767px)": {
              top: "40px",
              left: "50%",
              transform: "translate(-50%, 0%)",
              paddingX: "16px",
              maxWidth: "310px",
            },
            "@media screen and (min-width:767px) and (max-width:1024px)": {
              top: "40%",
              right: "100px",
            },
            "@media screen and (max-width:350px) ": {
              maxWidth: "265px",
            },
          }}
        >
          <Box
            sx={{
              width: "78%",
              marginRight: "96px",
              "@media screen and (max-width:768px)": {
                marginRight: "57px",
              },
            }}
            component="img"
            src={LoginBanner}
            alt="LoginBanner"
          />
          <Box
            sx={{
              marginY: "60px",
              "@media screen and (max-width:768px)": {
                marginTop: "24px",
                marginBottom: "0px",
              },
            }}
          >
            <Typography
              color={"contour.title3"}
              variant="title2"
              sx={{
                "@media screen and (max-width:768px)": {
                  fontSize: "24px",
                  letterSpacing: "0.48px",
                },
              }}
            >
              Sign in
            </Typography>
            <Box
              width={"100%"}
              sx={{
                paddingY: "40px",
                "@media screen and (max-width:768px)": {
                  paddingY: "24px",
                },
              }}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{ marginBottom: "4px" }}
              >
                Email
              </Typography>
              <TextField
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                variant="outlined"
                error={!isEmailValid}
                onKeyDown={handleKeyPress}
                helperText={
                  !isEmailValid ? (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      <IconComponent name="warningRed" fill={"#8E8D8A"} />
                      Enter a valid email address
                    </span>
                  ) : (
                    ""
                  )
                }
                sx={{
                  marginBottom: "8px",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                  ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #C61341",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                    color: "#181A20",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root": {
                    marginLeft: 0,
                  },
                }}
              />
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{ marginBottom: "4px" }}
              >
                Password
              </Typography>
              <TextField
                id="password"
                value={formData.password}
                onChange={handleInputChange}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                error={!isPasswordValid}
                onKeyDown={handleKeyPress}
                helperText={
                  !isPasswordValid ? (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      <IconComponent name="warningRed" fill={"#8E8D8A"} />
                      Enter a valid Password
                    </span>
                  ) : (
                    ""
                  )
                }
                sx={{
                  marginBottom: "8px",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                  ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #C61341",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                    color: "#181A20",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root": {
                    marginLeft: 0,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? (
                          <IconComponent name="show" fill={"#8E8D8A"} />
                        ) : (
                          <IconComponent name="hide" fill={"#8E8D8A"} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div>
                <BorderText
                  text={"Forgot password"}
                  onClick={handleForgotPswrd}
                />
              </div>
            </Box>
            <Box sx={{ maxWidth: "168px" }}>
                <Button
                loading={loading}
                  text="Sign in"
                  textStyle={{
                    color: "#333",
                    cursor: !isResetButtonDisabled ? "not-allowed" : "pointer",
                    opacity: !isResetButtonDisabled ? "0.5" : "1",
                    pointerEvents: !isResetButtonDisabled ? "none" : "auto",
                  }}
                  handleClick={handleSignIn}
                />
            </Box>
          </Box>
        </Box>
      </Loginlayout>
    </>
  );
};

export default StudentLogin;
