import React from "react";
import Link from "@mui/material/Link";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface BreadCrumbresComponentProps {
  subjectAOSDetails?: any[];
  selectedSubModule?: {
    id: string;
    module: string;
  };
  selectedType?: string | undefined;
  setShowRightPanel: any;
}

const BreadCrumbresComponent: React.FC<BreadCrumbresComponentProps> = ({
  subjectAOSDetails,
  selectedSubModule,
  selectedType,
  setShowRightPanel,
}) => {
  const navigate = useNavigate();

  const handleClickSub = (subCode: string) => {
    navigate(
      `/subject/${subCode}?sid=${
        subjectAOSDetails && subjectAOSDetails[0]?.subject_id
      }`
    );
  };

  const handleClickAOS = () => {
    setShowRightPanel(false);
  };

  const breadcrumbsResp = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={() =>
        handleClickSub(subjectAOSDetails && subjectAOSDetails[0]?.sub_code)
      }
      sx={{
        fontSize: 12,
        color: "#333",
        fontFamily: "Inter",
        fontWeight: 500,
      }}
    >
      {subjectAOSDetails && subjectAOSDetails[0]?.sub_code}
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      onClick={handleClickAOS}
      sx={{
        fontSize: 12,
        color: "#333",
        fontFamily: "Inter",
        fontWeight: 500,
      }}
    >
      {subjectAOSDetails &&
        subjectAOSDetails[0]?.area_of_study[0]?.area_of_study}
    </Link>,
    <Typography
      key="3"
      sx={{
        fontSize: 12,
        color: "#333",
        fontFamily: "Inter",
        fontWeight: 500,
      }}
    >
      {selectedSubModule?.module.replace('-', '')}
      {selectedType && ' - ' + selectedType.charAt(0).toUpperCase()}
      {selectedType && selectedType.substring(1)}
    </Typography>,
  ];

  return (
    <Box sx={{ display: { md: "none", sm: "block", xs: "block" } }}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbsResp}
      </Breadcrumbs>
    </Box>
  );
};

export default withErrorBoundary(BreadCrumbresComponent,<>Something went wrong with BreadCrumbresComponent Component</>);
