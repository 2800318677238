import { Box, Typography, Skeleton } from "@mui/material";
import React from "react";
import IconComponent from "../../components/Icons";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface YourSubjectDetailsProps {
  subjectDetails: any[];
  loadingSubject: boolean;
}

const SubjectContentGrid: React.FC<YourSubjectDetailsProps> = ({
  subjectDetails,
  loadingSubject,
}) => {
  return (
    <>
      {loadingSubject ? (
        <Box sx={{ padding: "25px" }}>
          <Skeleton variant="rounded" width={"100%"} height={"300px"} />
        </Box>
      ) : (
        <>
          {subjectDetails && subjectDetails.length > 0 && (
            <Box
              sx={{
                paddingY: { md: "28px", sm: "24px", xs: "24px" },
                paddingX: { md: "45px", sm: "16px", xs: "16px" },
              }}
            >
              <Typography
                variant="detail"
                color={"#333333"}
                fontWeight={600}
                lineHeight={"15px"}
              >
                {
                subjectDetails[0].sub_code 
                  }
              </Typography>
              {subjectDetails[0]?.subject_icon !== null ? (
                <Box
                  borderRadius={"50px"}
                  width={{ md: "35px", xs: "30px", sm: "30px" }}
                  height={{ md: "35px", xs: "30px", sm: "30px" }}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  marginTop={"24px"}
                  sx={{ background: subjectDetails[0]?.subject_colour }}
                >
                  <Box sx={{ display: { md: "flex", xs: "none", sm: "none" } }}>
                    <img
                      src={subjectDetails[0]?.subject_icon}
                      alt="Subject Icon"
                      width={23}
                      height={23}
                      crossOrigin="anonymous"
                    />
                  </Box>
                  <Box sx={{ display: { md: "none", xs: "flex", sm: "flex" } }}>
                    <img
                      src={subjectDetails[0]?.subject_icon}
                      alt="Subject Icon"
                      width={15}
                      height={15}
                      crossOrigin="anonymous"
                    />
                  </Box>
                </Box>
              ) : (
                <Box
                  borderRadius={"50px"}
                  width={{ md: "35px", xs: "30px", sm: "30px" }}
                  height={{ md: "35px", xs: "30px", sm: "30px" }}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  marginTop={"24px"}
                  sx={{ background: subjectDetails[0]?.subject_colour }}
                >
                  <Box sx={{ display: { md: "flex", xs: "none", sm: "none" } }}>
                    <IconComponent
                      name="openBookOutline"
                      width={23}
                      height={23}
                    />
                  </Box>
                  <Box sx={{ display: { md: "none", xs: "flex", sm: "flex" } }}>
                    <IconComponent
                      name="openBookOutline"
                      width={15}
                      height={15}
                    />
                  </Box>
                </Box>
              )}
              <Typography
                component={"div"}
                variant="title2"
                color={"#333"}
                sx={{
                  fontSize: { xs: "24px", sm: "24px", md: "32px" },
                  marginY: "12px",
                }}
              >
                  {subjectDetails[0].subject.replace(/(\d)\s*([⁄/])\s*(\d)/g, "$1$2$3")}
              </Typography>
              <Typography
                variant="bodyLg"
                fontWeight={"400"}
                color={"#333333"}
                fontSize={{ md: "16px", xs: "14px", sm: "14px" }}
                lineHeight={{ xs: "18.2px", sm: "18.2px" }}
              >
                {subjectDetails[0].subject_description}
              </Typography>
              <Box
                sx={{
                  marginTop: "12px",
                  cursor: "not-allowed",
                  opacity: "0.7",
                }}
              >
                <Typography
                  sx={{
                    color: "contour.title3",
                    borderBottom: "2px solid ",
                    borderColor: "contour.orange",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: 600,
                    width: "fit-content",
                    display:"none",
                  }}
                >
                  Watch introduction
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default withErrorBoundary(SubjectContentGrid,<>Something went wrong with SubjectContentGrid Component</>);
