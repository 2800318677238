import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import IconComponent from "../Icons";
import { Box, Typography } from "@mui/material";
import SearchList from "../List/SearchList";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../services/api/login";
import { globalSearch } from "../../services/api/subject";
import { debounceFunction } from "../../utils/utilfns";

export const SearchBar = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [isSearchListVisible, setIsSearchListVisible] = useState(false);
  const verifyAccess = getToken();
  const [totalCount, setTotalCount] = useState<any>()

  const inputRef = useRef<HTMLInputElement | null>(null);

  const debounceSearchData = React.useCallback(
    debounceFunction((nextValue) => getSearchData(nextValue), 1000),
    []
  );

  const getSearchData = async (value: any) => {
    const token = verifyAccess.token;
    const payload = {
      FileType: [],
      Subject: [],
      Search: value,
    };

    const response = await globalSearch(payload, token);

    setSearchData(response.data || []);
    setTotalCount(response.total_count);
  };
  console.log('ll', totalCount)

  const handleSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const value = event.target.value;
      setSearchQuery(value);
      setIsSearchListVisible(value !== "");

      value === "" ? setSearchData([]) : debounceSearchData(value);
    } catch (error) {
      console.error("There was a problem with the API call:", error);
      setSearchData([]);
    }
  };

  const handleSearchResults = () => {
    navigate("/search-results", { state: { searchData, searchQuery } });
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setIsSearchListVisible(false);
    inputRef.current && inputRef.current.focus();
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setIsSearchListVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <Box position={"relative"}>
      <TextField
        inputRef={inputRef}
        type="text"
        placeholder="Search"
        variant="outlined"
        sx={{
          width: "100%",
          ".Mui-focused": {
            background: "#FFF8EC",
            ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover": {
              background: "transparent",
            },
            ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover": {
              background: "transparent",
            },
            ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input": {
              color: "#333",
              fontSize: "14px",
              fontWeight: "400",
            },
          },
          ".MuiInputBase-root": {
            maxHeight: "44px",
            borderRadius: "8px",
            "&:focus-visible": {
              outline: "none !important",
            },
          },
          ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
            padding: "0px",
            marginLeft: "-7px",
            pointerEvents: "none",
          },
        }}
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton aria-label="search icon" edge="start">
                <IconComponent name="search" fill={"#8E8D8A"} />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: searchQuery && (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear search"
                onClick={handleClearSearch}
                edge="end"
              >
                <IconComponent name="close" fill={"#8E8D8A"} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {isSearchListVisible && (
        <Box
          position={"absolute"}
          sx={{
            height: { md: "auto", sm: "auto", xs: "calc(100vh - 135px)" },
            width: { md: "372px", sm: "372px", xs: "100%" },
            background: "#FFF",
            left: "50%",
            transform: "translate(-50%, 0px)",
            paddingY: "12px",
            paddingX: { md: "12px", sm: "12px", xs: "16px" },
            top: { md: "inherit", sm: "inherit", xs: "53px" },
            boxShadow: {
              md: "0px 4px 20px 0px rgba(45, 45, 45, 0.16)",
              sm: "0px 4px 20px 0px rgba(45, 45, 45, 0.16)",
              xs: "none",
            },
            borderRadius: { md: "16px", sm: "16px", xs: "0" },
          }}
        >
          <SearchList filter={searchData} />
          {searchData.length === 0 && (
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                marginTop: "8px",
                padding: "7px",
              }}
            >
              No match found
            </Typography>
          )}
          <Box
            sx={{
              paddingTop: "12px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
            component={"div"}
            onClick={handleSearchResults}
          >
            {searchData.length > 0 && (
              <Typography sx={{ display: "flex", color: "#8b8b8b", gap: "4px", alignItems: "center" }}>
                Showing Results: <Typography sx={{ color: "#000", fontSize: "14px", marginTop: "2px" }}>{searchData.length}</Typography>
              </Typography>
            )}
            {searchData.length > 0 && (
              <Typography
                sx={{
                  display: "block",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "18.2px",
                  color: "#000000",
                  alignItems: "center",
                  textAlign: "center",
                  paddingY: { md: "6.5px", sm: "6.5px", xs: "12px" },
                  border: "1px solid #FFA737",
                  borderRadius: "8px",
                  paddingX: "12px",
                }}
              >
                View all  ({totalCount})
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
