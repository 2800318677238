import {
  Box,
  Grid,
  Typography,
  Button,
  Popover,
  styled,
  LinearProgress,
  linearProgressClasses,
  Skeleton,
} from "@mui/material";
import NoImageFound from "../../assets/images/NoImageFound2.png"
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from "react-slick";
import "../WorkBook/workbook.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useRef, useState } from "react";
import BottomDrawer from "../../components/BottomDrawer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as ExpandMoreIcon } from "../../../src/assets/images/Aos/accordianArrow.svg";
import ReportBugBtn from "../../components/Button/ReportBugBtn";
import IconComponent from "../../components/Icons";
import BreadCrumbresComponent from "../../components/BreadCrumbsResponsive";
import { AccordionBtmSheetRes } from "../../components/BottomDrawer/AccordionbtmSheet";
import FilesPreview from "../../components/PdfViewer";
import {
  getAOSRelatedResource,
  getFileTypesByAOSID,
  markAsComplete,
} from "../../services/api/subject";
import { getToken } from "../../services/api/login";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getSubjectFormat, secondsToDuration } from "../../utils/utilfns";
interface AccordionItem {
  id: number;
  heading: string;
  content: string;
  fileCount: string;
  listItemData: string[];
}

type ToastState = {
  type: string;
  message: string;
};

interface WorkBookProps {
  aos_id: string;
  subject_id: string;
  selectedTopic?: any;
  selectedModuleTypeId?: string;
  selectedSubModule?: {
    id: string;
    module: string;
  };
  selectedType?: string | undefined;
  topic_id: number;
  setBugRepId: React.Dispatch<React.SetStateAction<string>>;
  bugTitle?: string;
  bugDescription?: string;
  attachment?: (File | string)[];
  handleBugTitleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBugDescriptionChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  titleError: boolean;
  descriptionError: boolean;
  captureScreenshot?: () => void;
  setAttachment?: React.Dispatch<React.SetStateAction<(File | string)[]>>;
  setToast?: React.Dispatch<React.SetStateAction<ToastState | null>>;
  bugRepId?: string;
  file_id: string | undefined;
  setBugTitle?: React.Dispatch<React.SetStateAction<string>>;
  setBugDescription?: React.Dispatch<React.SetStateAction<string>>;
  subjectAOSDetails?: any[];
  setShowRightPanel: any;
  open: any;
  openBottomSheet: any;
  setOpen: any;
  setOpenBottomSheet: any;
  bottomDrawerOpen2: any;
  setBottomDrawerOpen2: any;
  setCompletedTasks: React.Dispatch<React.SetStateAction<number>>;
}

export const WorkBookComponent: React.FC<WorkBookProps> = ({
  aos_id,
  subject_id,
  selectedTopic,
  selectedModuleTypeId,
  subjectAOSDetails,
  selectedSubModule,
  selectedType,
  setBugRepId,
  bugTitle,
  bugDescription,
  attachment,
  handleBugTitleChange,
  handleBugDescriptionChange,
  titleError,
  descriptionError,
  captureScreenshot,
  setAttachment,
  setBugTitle,
  setBugDescription,
  setToast,
  bugRepId,
  file_id,
  setShowRightPanel,
  open,
  openBottomSheet,
  setOpen,
  setOpenBottomSheet,
  bottomDrawerOpen2,
  setBottomDrawerOpen2,
  setCompletedTasks
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const verifyAccess = getToken();
  const [bottomDrawerOpen, setBottomDrawerOpen] = useState(false);
  const [selectedModuleFileData, setSelectedModuleFileData] = useState<any>([]);
  const [fileId, setFileId] = useState();
  const [fileTaskId, setFileTaskId] = useState();
  const [aosRelatedDetails, setAOSRelatedDetails] = useState<any>([]);
  const fileUrl = selectedModuleFileData[0]?.files?.file_url;
  const [prevFileId, setPrevFileId] = useState();
  const prevFileIdRef = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedModuleTypeId) {
      getSubjectAOSDetailsByIdd(subject_id, aos_id, file_id || null);
    }
  }, [selectedModuleTypeId, file_id]);

  useEffect(() => {
    getSubjectRelatedResource(subject_id, aos_id, selectedType);
  }, [subject_id, aos_id, file_id]);
  const [markComplete, setMarkComplete] = useState();
  const getSubjectAOSDetailsByIdd = async (
    subject_id: any,
    aos_id: any,
    fileId: any
  ) => {
    try {
      const payload: any = {
        subject_id: subject_id,
        aos_id: aos_id,
        topic_id: selectedTopic?.id,
        type_id: selectedModuleTypeId,
      };
      if (fileId !== null) {
        payload.file_id = fileId as string;
      }
      const response = await getFileTypesByAOSID(payload, verifyAccess.token);
      setSelectedModuleFileData(response?.data.data);
      setMarkComplete(response?.data?.data[0]?.files?.test_completion_status);
      setFileTaskId(response?.data?.data[0]?.files?.task_id);
      setFileId(response?.data?.data[0]?.files?.id);
      setBugRepId(response?.data?.data[0]?.files?.id);
    } catch (error) {
      console.error("Error fetching file type details:", error);
    }
  };

  const getSubjectRelatedResource = async (subject_id: any, aos_id: any, selectedType: any) => {
    try {
      setLoading(true);
      const payload = {
        subject_id: subject_id,
        aos_id: aos_id,
        file_type: selectedType,
      };
      setAOSRelatedDetails([]);
      const response = await getAOSRelatedResource(payload, verifyAccess.token);
      setAOSRelatedDetails(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching subject aos details by ID:", error);
    }
  };

  const handleOnResourceClick = (items: any) => {
    const newSearch = new URLSearchParams();
    newSearch.set("sid", items.subject_id);
    newSearch.set("aosID", items.area_of_study_id);
    newSearch.set("topicID", items.topic_id);
    newSearch.set("typeID", items.file_type_id);
    newSearch.set("fileID", items.file_id);
    navigate(`/aos?${newSearch.toString()}`);
  };
  
  useEffect(() => {
    if (fileId !== prevFileId) {
      setPrevFileId(fileId);
      prevFileIdRef.current = fileId;
    }
  }, [fileId]);

  const handClose = () => {
    setBottomDrawerOpen(false);
  };
  const settings = {
    infinite: false,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
  };

  const accordionData: AccordionItem[] = [
    {
      id: 1,
      heading: "[1.1] Gravity",
      fileCount: "5 Files",
      content: "Content for Accordion Item 1",
      listItemData: [
        "Recording",
        "Workbook",
        "Workbook solution",
        "Test",
        "Test solution",
      ],
    },
    {
      id: 2,
      heading: "[1.2] Gravitational Fields",
      fileCount: "5 Files",
      content: "Content for Accordion Item 2",
      listItemData: ["Item 1", "Item 2", "Item 3"],
    },
    {
      id: 3,
      heading: "[1.3] Orbital Mechanics - Longer Name Example",
      fileCount: "5 Files",
      content: "Content for Accordion Item 3",
      listItemData: ["Option A", "Option B", "Option C"],
    },
  ];

  const [selectedItem, setSelectedItem] = useState<string | null>("Recording");

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
  };

  const [expanded, setExpanded] = useState<Set<string>>(
    new Set([`panel${accordionData[0].id}`])
  );

  const handleChange =
    (panel: string) =>
      (event: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => {
        setExpanded((prevExpanded) => {
          const newSet = new Set(prevExpanded);

          if (isExpanded) {
            // If the panel is expanded, add it to the set
            newSet.add(panel);
          } else {
            // If the panel is collapsed, remove it from the set
            newSet.delete(panel);
          }

          return newSet;
        });
      };
  const handleClose = () => {
    setAnchorEl2(null);
  };
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [codeCopied, setCodeCopied] = useState(false);

  const handleCopyClick = (codeToCopy: string) => {
    navigator.clipboard
      .writeText(codeToCopy)
      .then(() => {
        setCodeCopied(true);
        setTimeout(() => {
          setCodeCopied(false);
        }, 1500);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
    if (setToast) {
      setToast({ type: "success", message: "Password is copied to clipboard!" });
    }
  };
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    marginTop: "-4px",
    height: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));
  const handleMark = async (id: any, status: any) => {
    const idFile = parseInt(id, 10);
    console.log("taskComplete", idFile, status);

    try {
      const newStatus = !status;

      const payload = {
        task_id: idFile,
        test_completion_status: newStatus,
      };
      const response = await markAsComplete(payload, verifyAccess.token);
      console.log("taskComplete", response?.data.data.test_completion_status);
      setMarkComplete(response?.data.data.test_completion_status);
      // window.location.reload();
      if (status) {
        setCompletedTasks((prev) => prev - 1)
      } else {
        setCompletedTasks((prev) => prev + 1)
      }

      console.log("file log time", response?.data.data);
    } catch (error) {
      console.error("Error fetching file type details:", error);
    }
  };

  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover" : undefined;

  const handleDownloadClick = () => {
    setTimeout(() => {
      handleCopyClick(
        selectedModuleFileData &&
        selectedModuleFileData[0]?.files.file_password
      )
    }, 1500);
    const baseUrl = process.env.REACT_APP_API_URL;
    const url =
      selectedModuleFileData[0]?.files?.file_protected_url !== null
        ? selectedModuleFileData[0]?.files?.file_protected_url
        : selectedModuleFileData[0]?.files?.file_url;
    const fileUrl = baseUrl + "/" + url;
    if (fileUrl) {
      fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          const fileName = selectedModuleFileData[0]?.files?.file_name;
          a.href = url;
          a.download = fileName; // Specify the desired file name here
          document.body.appendChild(a); // Append anchor to body
          a.click(); // Trigger the download
          document.body.removeChild(a); // Remove anchor from body
          window.URL.revokeObjectURL(url); // Release the object URL
        })
        .catch(error => console.error('Error downloading file:', error));
    } else {
      console.error("File URL not found.");
    }
  };

  console.log("aosRelatedDetails", aosRelatedDetails);
  return (
    <Box>
      {isDesktop && (
        <Box>
          {
            loading ? <Skeleton variant="rounded" width={"100%"} height={"calc(100vh - 130px)"} /> :
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Grid
                  item
                  width={{ md: aosRelatedDetails.length > 1 ? "70%" : '100%' }}
                  sx={{ height: "calc(100vh - 100px)", overflowY: "scroll" }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        marginBottom: "18px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 22,
                          color: "#2B3258",
                          fontFamily: "PolySans_Neutral",
                          fontWeight: 600,
                          lineHeight: "26px",
                          paddingRight: "10px",
                          "@media screen and (min-width: 1600px) ": {
                          },
                        }}
                      >
                        {selectedSubModule?.module.replace('-', '')}
                        {selectedType && ' - ' + selectedType.charAt(0).toUpperCase()}
                        {selectedType && selectedType.substring(1)}
                      </Typography>
                      <Box sx={{ display: "flex", gap: "12px", marginLeft: "auto" }}>
                        <ReportBugBtn
                          open={open}
                          openBottomSheet={openBottomSheet}
                          setOpen={setOpen}
                          setOpenBottomSheet={setOpenBottomSheet}
                          bugTitle={bugTitle}
                          bugDescription={bugDescription}
                          attachment={attachment}
                          handleBugTitleChange={handleBugTitleChange}
                          handleBugDescriptionChange={handleBugDescriptionChange}
                          titleError={titleError}
                          descriptionError={descriptionError}
                          bugRepId={bugRepId}
                          selectedSubModule={selectedSubModule}
                          selectedType={selectedType}
                          captureScreenshot={captureScreenshot}
                          setAttachment={setAttachment}
                          setToast={setToast}
                          setBugTitle={setBugTitle}
                          setBugDescription={setBugDescription}
                        />
                        {selectedModuleFileData[0]?.files
                          .student_acknowledgement_status && (
                            <Button
                              onClick={() => handleMark(fileTaskId, markComplete)}
                              sx={{
                                border: markComplete
                                  ? "1px solid #007634"
                                  : "1px solid #FFA737",
                                padding: "10px",
                                height: "34px",
                                textTransform: "capitalize",
                                background: markComplete ? "#E6F6ED" : "#FFF",
                                borderRadius: "8px",
                                ":hover": { background: "#FFEDD1" },
                                marginLeft: "auto",
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              {markComplete ? (
                                <IconComponent
                                  name="greenCheckCircle"
                                  fill={"#8E8D8A"}
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                ""
                              )}
                              {markComplete ? (
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    color: "#333",
                                    fontFamily: "Inter",
                                    fontWeight: 600,
                                    lineHeight: "21px",
                                  }}
                                >
                                  Completed
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    color: "#333",
                                    fontFamily: "Inter",
                                    fontWeight: 600,
                                    lineHeight: "21px",
                                    width: "125px",
                                  }}
                                >
                                  Mark as complete
                                </Typography>
                              )}
                            </Button>
                          )}
                        <div>
                          <Box
                            sx={{
                              border: "1px solid #FFA737",
                              height: "34px",
                              textTransform: "capitalize",
                              padding: "0px",
                              borderRadius: "8px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              onClick={handleDownloadClick}
                              sx={{
                                fontSize: "14px",
                                color: "#333",
                                fontFamily: "Inter",
                                fontWeight: 600,
                                lineHeight: "21px",
                                borderRight: "1px solid #FFA737",
                                padding: "6px 8px 6px 4px",
                                paddingLeft: "8px",
                                borderRadius: "8px 0 0 8px",
                                cursor: "pointer",
                                ":hover": { background: "#FFEDD1" },
                              }}
                            >
                              Download
                            </Typography>
                            <Button aria-describedby={id2}
                              onClick={handleClick2} sx={{ padding: "0 8px", borderRadius: "0 8px 8px 0", display: "flex", height: 'inherit', minWidth: '0px', ":hover": { background: "#FFEDD1" }, }}
                            >
                              <IconComponent
                                name="blackDropDown"
                                fill={"#8E8D8A"}
                                width={20}
                                height={20}
                              />
                            </Button>
                          </Box>
                          <Popover
                            id={id2}
                            open={open2}
                            anchorEl={anchorEl2}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            sx={{
                              ".MuiPaper-rounded": {
                                borderRadius: "8px",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                padding: "20px 16px",
                                borderBottom: "1px solid #D2D2D0",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  lineHeight: "18.2px",
                                  color: "#333",
                                }}
                              >
                                Access the dowloaded file using the password:
                              </Typography>
                              <Box
                                sx={{
                                  background: "#F4F6FF",
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "12px",
                                  borderRadius: "8px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    padding: "0px 12px",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                    color: "#333",
                                  }}
                                >
                                  {selectedModuleFileData &&
                                    selectedModuleFileData[0]?.files.file_password}
                                </Typography>
                                <Button
                                  onClick={() =>
                                    handleCopyClick(
                                      selectedModuleFileData &&
                                      selectedModuleFileData[0]?.files.file_password
                                    )
                                  }
                                  sx={{
                                    border: codeCopied
                                      ? "1px solid #007634"
                                      : "1px solid #FFA737",
                                    padding: "12px",
                                    height: "34px",
                                    textTransform: "capitalize",
                                    background: codeCopied ? "#E6F6ED" : "#FFF",
                                    borderRadius: "8px",
                                    ":hover": { background: "#FFEDD1" },
                                    marginLeft: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  {codeCopied ? (
                                    <IconComponent
                                      name="greenCheckCircle"
                                      fill={"#8E8D8A"}
                                      width={16}
                                      height={16}
                                    />
                                  ) : (
                                    <IconComponent
                                      name="copy"
                                      fill={"#8E8D8A"}
                                      width={16}
                                      height={16}
                                    />
                                  )}
                                  {codeCopied ? (
                                    <Typography
                                      sx={{
                                        fontSize: 14,
                                        color: "#333",
                                        fontFamily: "Inter",
                                        fontWeight: 600,
                                        lineHeight: "21px",
                                      }}
                                    >
                                      Copied
                                    </Typography>
                                  ) : (
                                    <Typography
                                      sx={{
                                        fontSize: 14,
                                        color: "#333",
                                        fontFamily: "Inter",
                                        fontWeight: 600,
                                        lineHeight: "21px",
                                      }}
                                    >
                                      Copy
                                    </Typography>
                                  )}
                                </Button>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                padding: "13px 16px",
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                cursor: "pointer",
                                ":hover": { background: "#FFF8EC" },
                              }}
                              component={"div"}
                              onClick={handleDownloadClick}
                            >
                              <IconComponent
                                name="download"
                                fill={"#8E8D8A"}
                                width={20}
                                height={20}
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "18.2px",
                                  color: "#333",
                                  maxWidth: "213px",
                                }}
                              >
                                Download Workbook
                              </Typography>
                            </Box>
                          </Popover>
                        </div>
                      </Box>
                    </Box>
                    <Box id="videoSec">
                      {selectedModuleFileData &&
                        selectedModuleFileData[0]?.files?.file_url && (
                          <FilesPreview isFullContainer={aosRelatedDetails.length > 1} fileUrl={fileUrl} fileId={selectedModuleFileData[0]?.files?.id} password={selectedModuleFileData[0]?.files.file_password} />
                        )}
                    </Box>
                  </Box>
                </Grid>
                {aosRelatedDetails.length > 1 && (
                  <Grid
                    item
                    width={{ md: "27%" }}
                    sx={{ height: "calc(100vh - 100px)", overflowY: "scroll" }}
                  >
                    <Box sx={{ width: "100%" }}>
                      {aosRelatedDetails.length > 0 && (
                        <Typography
                          sx={{
                            fontSize: 16,
                            color: "#2B3258",
                            fontFamily: "Inter",
                            fontWeight: 600,
                            lineHeight: "24px",
                            marginBottom: "12px",
                          }}
                        >
                          Related resources
                        </Typography>
                      )}
                      {aosRelatedDetails
                        .filter((item: any) => parseInt(item.file_id) !== parseInt(file_id || ""))
                        .map((item: any, index: any) => (
                          <Box
                            key={index}
                            onClick={() => {
                              handleOnResourceClick(item);
                            }}
                            sx={{
                              border: "1px solid var(--Border-Inactive, #D2D2D0)",
                              background: "#fff",
                              borderRadius: "12px",
                              marginBottom: "12px",
                              cursor:"pointer",
                            }}
                          >
                            {item.ext_type === "pdf" && (
                              <Box
                                sx={{
                                  position: "relative",
                                  maxWidth: { md: "100%", sm: "100%", xs: "156px" },
                                }}
                              >
                                <Box
                                  className="hoverOpacity"
                                  sx={{
                                    paddingTop: "13px",
                                    paddingX: "21px",
                                    background: "#F4F6FF",
                                    borderTopLeftRadius: {
                                      md: "12px",
                                      sm: "12px",
                                      xs: "0",
                                    },
                                    borderTopRightRadius: {
                                      md: "12px",
                                      sm: "12px",
                                      xs: "0",
                                    },
                                    position: "relative",
                                  }}
                                  component={"div"}
                                  onClick={() => console.log("Open workbook1")}
                                >
                                  <Box
                                    sx={{
                                      width: "100%",
                                      objectFit: "cover",
                                      objectPosition: "center",
                                      height: "123px",
                                    }}
                                    component="img"
                                    src={`${process.env.REACT_APP_API_URL}/${item.thumbnail_url}`}
                                    alt="LoginSmily"
                                    crossOrigin="anonymous"
                                    onError={(e) => {
                                      const target = e.target as HTMLImageElement;
                                      target.onerror = null; // Prevents infinite loop if fallback image fails
                                      target.src = NoImageFound;
                                    }}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: {
                                      md: "block ",
                                      sm: "block",
                                      xs: "none",
                                    },
                                  }}
                                >
                                  <Box
                                    className="hoverShow"
                                    sx={{
                                      display: "none",
                                      maxWidth: "168px",
                                      position: "absolute",
                                      bottom: "50%",
                                      width: "100%",
                                      left: "50%",
                                      transform: "translate(-50%, 50%)",
                                    }}
                                  ></Box>
                                </Box>
                              </Box>
                            )}
                            {item.ext_type === "video" && (
                              <Box
                                sx={{
                                  position: "relative",
                                  maxWidth: { md: "100%", sm: "100%", xs: "156px" },
                                }}
                              >
                                <Box
                                  sx={{
                                    background: "#F4F6FF",
                                    borderTopLeftRadius: {
                                      md: "12px",
                                      sm: "12px",
                                      xs: "0",
                                    },
                                    borderTopRightRadius: {
                                      md: "12px",
                                      sm: "12px",
                                      xs: "0",
                                    },
                                    position: "relative",
                                  }}
                                  component={"div"}
                                  onClick={() => console.log("Open workbook1")}
                                >
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: "123px",
                                      objectFit: "cover",
                                      objectPosition: "top",
                                      borderTopLeftRadius: {
                                        md: "12px",
                                        sm: "12px",
                                        xs: "0",
                                      },
                                      borderTopRightRadius: {
                                        md: "12px",
                                        sm: "12px",
                                        xs: "0",
                                      },
                                    }}
                                    component="img"
                                    crossOrigin="anonymous"
                                    src={`${process.env.REACT_APP_API_URL}/${item.thumbnail_url}`}
                                    alt="LoginSmily"
                                    onError={(e) => {
                                      const target = e.target as HTMLImageElement;
                                      target.onerror = null; // Prevents infinite loop if fallback image fails
                                      target.src = NoImageFound;
                                    }}
                                  />

                                  <Box
                                    sx={{
                                      position: "absolute",
                                      bottom: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, 50%)",
                                    }}
                                    component={"div"}
                                    onClick={() => console.log("Video")}
                                  >
                                    <IconComponent
                                      name="videControl"
                                      fill={"#8E8D8A"}
                                    />
                                  </Box>
                                </Box>
                                <Box
                                  className="hoverVideControl"
                                  sx={{
                                    display: "none",
                                    position: "absolute",
                                    bottom: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, 50%)",
                                    cursor: "pointer",
                                  }}
                                  component={"div"}
                                  onClick={() => console.log("Video")}
                                >
                                  <IconComponent
                                    name="videControl"
                                    fill={"#8E8D8A"}
                                  />
                                </Box>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    lineHeight: "15px",
                                    color: "#FFF",
                                    position: "absolute",
                                    right: "8px",
                                    top: "8px",
                                    background: "#2B3258",
                                    paddingX: "4px",
                                    borderRadius: "8px",
                                  }}
                                >
                                  {secondsToDuration(item?.duration)}
                                </Typography>
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={50}
                                />
                              </Box>
                            )}
                            <Box
                              sx={{
                                background: "FFF",
                                padding: "16px",
                              }}
                            >
                              {" "}
                              <Box
                                sx={{
                                  padding: "4px 8px",
                                  background: "#FFF0E2",
                                  borderRadius: "4px",
                                  marginBottom: "4px",
                                  fontSize: 12,
                                  color: "#333",
                                  fontFamily: "Inter",
                                  fontWeight: 500,
                                  lineHeight: "15px",
                                }}
                              >
                                {getSubjectFormat(item.subject)}
                              </Box>
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  color: "#000",
                                  fontFamily: "Inter",
                                  fontWeight: 600,
                                  lineHeight: "18px",
                                  marginBottom: "4px",
                                }}
                              >
                                {item.topic}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  color: "#333",
                                  fontFamily: "Inter",
                                  fontWeight: 400,
                                  lineHeight: "18px",
                                  marginBottom: "4px",
                                }}
                              >
                                {item.file_type}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  color: "#696969",
                                  fontFamily: "Inter",
                                  fontWeight: 400,
                                  lineHeight: "15px",
                                }}
                              >
                                {item.last_opened !== null ? `Last opened:
                            ${moment(moment.utc(item.last_opened).toDate()).local().fromNow()}` : ""}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </Grid>
                )}
              </Grid>
          }
        </Box>
      )
      }

      {!isDesktop && (
        <Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Box sx={{ padding: "0px 6px" }}>
              <BreadCrumbresComponent
                subjectAOSDetails={subjectAOSDetails}
                selectedSubModule={selectedSubModule}
                selectedType={selectedType}
                setShowRightPanel={setShowRightPanel}
              />
            </Box>

            <Box sx={{ marginBottom: "24px" }}>
              <Box
                sx={{
                  display: "flex",
                  marginBottom: "12px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginX: "16px",
                }}
              ></Box>
              <AccordionBtmSheetRes
                bottomDrawerOpen2={bottomDrawerOpen2}
                setBottomDrawerOpen2={setBottomDrawerOpen2}
                bugRepId={bugRepId}
                selectedModuleFileData={
                  selectedModuleFileData && selectedModuleFileData
                }
                selectedPassword={
                  selectedModuleFileData[0]?.files?.file_password
                }
                markComplete={markComplete}
                handleMark={handleMark}
                fileTaskId={fileTaskId}
                pdfCom={true}
                bugTitle={bugTitle}
                bugDescription={bugDescription}
                attachment={attachment}
                handleBugTitleChange={handleBugTitleChange}
                handleBugDescriptionChange={handleBugDescriptionChange}
                titleError={titleError}
                descriptionError={descriptionError}
                captureScreenshot={captureScreenshot}
                setAttachment={setAttachment}
                setBugTitle={setBugTitle}
                setBugDescription={setBugDescription}
                selectedSubModule={selectedSubModule}
                selectedType={selectedType}
                setToast={setToast}
                subjectAOSDetails={subjectAOSDetails}
              />
              {selectedModuleFileData &&
                selectedModuleFileData[0]?.files?.file_url && (
                  <Box id="videoSec2">
                    <FilesPreview
                      isFullContainer={aosRelatedDetails.length > 1}
                      fileUrl={selectedModuleFileData[0]?.files?.file_url}
                      fileId={selectedModuleFileData[0]?.files?.id}
                      password={selectedModuleFileData[0]?.files.file_password}
                    />
                  </Box>
                )}
            </Box>
            {aosRelatedDetails.length > 1 && (
              <Box sx={{ marginLeft: "20px", overflowX: "hidden" }}>
                {aosRelatedDetails.length > 0 && (
                  <Typography
                    sx={{
                      fontSize: 16,
                      color: "#2B3258",
                      fontFamily: "Inter",
                      fontWeight: 600,
                      lineHeight: "24px",
                      marginBottom: "12px",
                    }}
                  >
                    Related resources
                  </Typography>
                )}
                <Slider {...settings}>
                  {aosRelatedDetails
                    .filter((item: any) => item.file_url !== fileUrl)
                    .map((item: any, index: any) => (
                      <Box
                        key={index}
                        onClick={() => {
                          handleOnResourceClick(item);
                        }}
                        sx={{
                          border: "1px solid var(--Border-Inactive, #D2D2D0)",
                          background: "#fff",
                          borderRadius: "12px",
                          marginBottom: "12px",
                        }}
                      >
                        {item.ext_type === "pdf" && (
                          <Box
                            sx={{
                              position: "relative",
                              width: "100%",
                              maxWidth: "none",
                            }}
                          >
                            <Box
                              className="hoverOpacity"
                              sx={{
                                paddingTop: "13px",
                                paddingX: "21px",
                                background: "#F4F6FF",
                                borderTopLeftRadius: {
                                  md: "12px",
                                  sm: "12px",
                                  xs: "12px",
                                },
                                borderTopRightRadius: {
                                  md: "12px",
                                  sm: "12px",
                                  xs: "12px",
                                },
                                position: "relative",
                              }}
                              component={"div"}
                              onClick={() => console.log("Open workbook1")}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  objectFit: "cover",
                                  objectPosition: "center",
                                  height: "107px",
                                }}
                                component="img"
                                src={`${process.env.REACT_APP_API_URL}/${item.thumbnail_url}`}
                                alt="LoginSmily"
                                crossOrigin="anonymous"
                                onError={(e) => {
                                  const target = e.target as HTMLImageElement;
                                  target.onerror = null; // Prevents infinite loop if fallback image fails
                                  target.src = NoImageFound;
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: {
                                  md: "block ",
                                  sm: "block",
                                  xs: "none",
                                },
                              }}
                            >
                              <Box
                                className="hoverShow"
                                sx={{
                                  display: "none",
                                  maxWidth: "168px",
                                  position: "absolute",
                                  bottom: "50%",
                                  width: "100%",
                                  left: "50%",
                                  transform: "translate(-50%, 50%)",
                                }}
                              ></Box>
                            </Box>
                          </Box>
                        )}
                        {item.ext_type === "video" && (
                          <Box
                            sx={{
                              position: "relative",
                              width: "100%",
                              maxWidth: "none",
                            }}
                          >
                            <Box
                              sx={{
                                background: "#F4F6FF",
                                borderTopLeftRadius: {
                                  md: "12px",
                                  sm: "12px",
                                  xs: "0",
                                },
                                borderTopRightRadius: {
                                  md: "12px",
                                  sm: "12px",
                                  xs: "0",
                                },
                                position: "relative",
                              }}
                              component={"div"}
                              onClick={() => console.log("Open workbook1")}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "123px",
                                  objectFit: "fill",
                                  objectPosition: "top",
                                  borderTopLeftRadius: {
                                    md: "12px",
                                    sm: "12px",
                                    xs: "0",
                                  },
                                  borderTopRightRadius: {
                                    md: "12px",
                                    sm: "12px",
                                    xs: "0",
                                  },
                                }}
                                component="img"
                                crossOrigin="anonymous"
                                src={`${process.env.REACT_APP_API_URL}/${item.thumbnail_url}`}
                                alt="LoginSmily"
                                onError={(e) => {
                                  const target = e.target as HTMLImageElement;
                                  target.onerror = null; // Prevents infinite loop if fallback image fails
                                  target.src = NoImageFound;
                                }}
                              />

                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, 50%)",
                                }}
                                component={"div"}
                                onClick={() => console.log("Video")}
                              >
                                <IconComponent
                                  name="videControl"
                                  fill={"#8E8D8A"}
                                />
                              </Box>
                            </Box>
                            <Box
                              className="hoverVideControl"
                              sx={{
                                display: "none",
                                position: "absolute",
                                bottom: "50%",
                                left: "50%",
                                transform: "translate(-50%, 50%)",
                                cursor: "pointer",
                              }}
                              component={"div"}
                              onClick={() => console.log("Video")}
                            >
                              <IconComponent
                                name="videControl"
                                fill={"#8E8D8A"}
                              />
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                lineHeight: "15px",
                                color: "#FFF",
                                position: "absolute",
                                right: "8px",
                                top: "8px",
                                background: "#2B3258",
                                paddingX: "4px",
                                borderRadius: "8px",
                              }}
                            >
                              {secondsToDuration(item?.duration)}
                            </Typography>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                          </Box>
                        )}
                        <Box
                          sx={{
                            background: "FFF",
                            padding: "16px",
                          }}
                        >
                          {" "}
                          <Box
                            sx={{
                              padding: "4px 8px",
                              background: "#FFF0E2",
                              borderRadius: "4px",
                              marginBottom: "4px",
                              fontSize: 12,
                              color: "#333",
                              fontFamily: "Inter",
                              fontWeight: 500,
                              lineHeight: "15px",
                            }}
                          >
                            {getSubjectFormat(item.subject)}
                          </Box>
                          <Typography
                            sx={{
                              fontSize: 14,
                              color: "#000",
                              fontFamily: "Inter",
                              fontWeight: 600,
                              lineHeight: "18px",
                              marginBottom: "4px",
                              maxHeight: '38px',
                              minHeight: '38px',
                              overflow: 'hidden',
                              '-webkit-line-clamp': '2',
                              '-webkit-box-orient': 'vertical',
                              textOverflow: 'ellipsis',
                              display: "-webkit-box",
                            }}
                          >
                            {item.topic}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 14,
                              color: "#333",
                              fontFamily: "Inter",
                              fontWeight: 400,
                              lineHeight: "18px",
                              marginBottom: "4px",
                              maxHeight: '18px',
                              minHeight: '18px',
                              overflow: 'hidden',
                              '-webkit-line-clamp': 1,
                              whiteSpace: "nowrap",
                              '-webkit-box-orient': 'vertical',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {item.file_type}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 12,
                              color: "#696969",
                              fontFamily: "Inter",
                              fontWeight: 400,
                              lineHeight: "15px",
                              minHeight: "16px"
                            }}
                          >
                            {item.last_opened !== null ? `Last opened:
                            ${moment(moment.utc(item.last_opened).toDate()).local().fromNow()}` : ""}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                </Slider>
              </Box>)}
          </Box>
          <BottomDrawer open={bottomDrawerOpen} onClose={handClose}>
            <Box>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "Inter",
                  fontWeight: 600,
                  marginLeft: "16px",
                }}
              >
                AOS 2 [Name] Topics
              </Typography>
              <Box sx={{ padding: "16px" }}>
                <div>
                  {accordionData.map((item) => (
                    <Accordion
                      key={item.id}
                      expanded={expanded.has(`panel${item.id}`)}
                      onChange={handleChange(`panel${item.id}`)}
                      sx={{
                        border: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${item.id}-content`}
                        id={`panel${item.id}-header`}
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #EFEFEF",
                          background: "#fff",
                          padding: "16px 20px",
                          backgroundColor: expanded.has(`panel${item.id}`)
                            ? "#FFEDD1"
                            : "inherit",
                          height: "78px",
                        }}
                      >
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography
                            sx={{
                              fontSize: 16,
                              fontFamily: "Inter",
                              fontWeight: 600,
                            }}
                          >
                            {item.heading}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontFamily: "Inter",
                              fontWeight: 400,
                            }}
                          >
                            {item.fileCount}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ borderRadius: "8px", padding: "0" }}
                      >
                        <Box>
                          {item.listItemData.map(
                            (listItem: string, index: number) => (
                              <Box
                                key={index}
                                onClick={() => handleItemClick(listItem)}
                                sx={{
                                  padding: "12px 20px",
                                  fontSize: 14,
                                  fontFamily: "Inter",
                                  fontWeight:
                                    selectedItem === listItem ? "600" : "400",
                                  backgroundColor:
                                    selectedItem === listItem
                                      ? "#FFC071"
                                      : "transparent",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                }}
                              >
                                {listItem}
                              </Box>
                            )
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </Box>
            </Box>
          </BottomDrawer>
        </Box>
      )}
    </Box>
  );
};
