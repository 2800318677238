import React, { Dispatch, SetStateAction } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Icons from "../Icons/index";
import Popup from "../Popup";
import Cookies from "js-cookie";
import { getToken, resetPassword } from "../../services/api/login";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { updateProfile } from "../../services/api/profile";

type ToastState = {
  type: string;
  message: string;
};
interface settingPopupProps {
  open: boolean;
  resetPass: boolean;
  handleClose: () => void;
  setResetPass: Dispatch<SetStateAction<boolean>>;
  setPassUpdatePopup: Dispatch<SetStateAction<boolean>>;
  fetchData: () => void;
  fName: string;
  lName: string;
  setToast: Dispatch<SetStateAction<ToastState | null>>;
}

interface FormData {
  newPassword: string;
  confirmPassword: string;
}

const Settings = ({
  open,
  handleClose,
  resetPass,
  setResetPass,
  setPassUpdatePopup,
  fetchData,
  fName,
  lName,
  setToast,
}: settingPopupProps) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [isPasswordValid, setIsPasswordValid] = React.useState(true);
  const [passwordsMatch, setPasswordsMatch] = React.useState(true);
  const [passwordsErr, setPasswordsErr] = React.useState(true);
  const [formData, setFormData] = React.useState<FormData>({
    newPassword: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const isValidFirstName = (name: string) =>
    /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/.test(name);
  const isValidLastName = (name: string) =>
    /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/.test(name);

  const verifyAccess = getToken();
  const [firstName, setFirstName] = React.useState(fName);
  const [lastName, setLastName] = React.useState(lName);
  const [isValidFName, setIsValidFName] = React.useState(true);
  const [isValidLName, setIsValidLName] = React.useState(true);
  const [saveDisabled, setSaveDisabled] = React.useState(false);
  const [initialFirstName, setInitialFirstName] = React.useState("");
  const [initialLastName, setInitialLastName] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setFirstName(fName);
    setLastName(lName);
  }, [fName, lName]);

  React.useEffect(() => {
    setInitialFirstName(firstName);
    setInitialLastName(lastName);
  }, [firstName, lastName]);

  React.useEffect(() => {
    if (fName !== initialFirstName || lName !== initialLastName) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [firstName, lastName, initialFirstName, initialLastName]);

  const validatePassword = (password: string): boolean => {
    const specialChars = /^(?=.*[a-z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    return specialChars.test(password);
  };

  const handleBack = () => {
    setResetPass(false);
    setFormData({
      newPassword: "",
      confirmPassword: "",
    });
    setShowPassword(false);
    setShowPassword1(false);
  };

  const handleResetPassword = async () => {
    if (formData.newPassword === "") {
      setIsPasswordValid(false);
    }
    if (formData.confirmPassword === "") {
      setPasswordsErr(false);
    }
    setLoading(true);
    if (
      formData.newPassword.trim() !== "" &&
      formData.confirmPassword.trim() !== "" &&
      passwordsMatch &&
      isPasswordValid
    ) {
      try {
        const response = await resetPassword(
          {
            newpwd: formData.newPassword,
            confirmpwd: formData.confirmPassword,
          },
          verifyAccess.token
        );
        if (response && response.data) {
          setLoading(false);
          handleClose();
          setResetPass(false);
          setFormData({
            newPassword: "",
            confirmPassword: "",
          });
          setShowPassword(false);
          setShowPassword1(false);
          setPassUpdatePopup(true);
          setTimeout(() => {
            Cookies.remove("loginToken");
            Cookies.remove("authAccess");
            navigate("/");
          }, 5000);
        } else {
          // const errorMessage =
          //   response && response.data.message
          //     ? response.data.message
          //     : "An error occured";
          // setLoading(false);
          // setToast({
          //   type: "error",
          //   message: errorMessage,
          // });
        }
      } catch (error) {
        console.error("Forgot api", error);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (
        formData.newPassword.trim() !== "" &&
        formData.confirmPassword.trim() !== "" &&
        isPasswordValid
      ) {
        handleResetPassword();
      }
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleTogglePasswordVisibility1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  const handleReset = () => {
    setResetPass(true);
  };

  const handleInputNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, value } = event.target;
    if (id === "firstName") {
      setFirstName(value);
      setIsValidFName(isValidFirstName(value));
    }

    if (id === "lastName") {
      setLastName(value);
      setIsValidLName(isValidLastName(value));
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));

    if (id === "newPassword") {
      setIsPasswordValid(validatePassword(value));
      if (formData.confirmPassword && formData.confirmPassword === value) {
        setPasswordsMatch(true);
      } else {
        setPasswordsMatch(false);
      }
    }

    if (id === "confirmPassword") {
      if (formData.newPassword && formData.newPassword === value) {
        setPasswordsMatch(true);
        setPasswordsErr(true);
      } else {
        setPasswordsMatch(false);
        setPasswordsErr(false);
      }
    }
  };

  const handlePopupClose = () => {
    handleClose();
    setResetPass(false);
    setFormData({
      newPassword: "",
      confirmPassword: "",
    });
    setFirstName(fName);
    setLastName(lName);
  };

  const updataProfileData = async () => {
    if (firstName === "") {
      setIsValidFName(false);
    }
    if (lastName === "") {
      setIsValidLName(false);
    }
    if (
      (firstName.trim() !== fName || lastName.trim() !== lName) &&
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      isValidFName &&
      isValidLName
    ) {
      try {
        const response = await updateProfile(
          { first_name: firstName, last_name: lastName },
          verifyAccess.token
        );
        if (response && response.data) {
          localStorage.setItem("profileFirstName", firstName);
          window.location.reload();
          fetchData();
          handleClose();
          setResetPass(false);
          setFormData({
            newPassword: "",
            confirmPassword: "",
          });
          setShowPassword(false);
          setShowPassword1(false);
          const successMessage = response.data.message;
          setToast({
            type: "success",
            message: successMessage,
          });
        } else {
          const errorMessage =
            response && response.data.message
              ? response.data.message
              : "An error occured";
          setToast({
            type: "error",
            message: errorMessage,
          });
        }
      } catch (error) {
        console.error("Forgot api", error);
      }
    } else {
      handlePopupClose();
    }
  };

  return (
    <>
      <Popup
        open={open}
        handleClose={handlePopupClose}
        title="Settings"
        btnText={resetPass ? "Save new password" : !saveDisabled ? "" : "Save"}
        handleButton={resetPass ? handleResetPassword : updataProfileData}
        loading={loading}
        width="381px"
      >
        <>
          {!resetPass ? (
            <Box>
              <Box sx={{ marginBottom: "36px" }}>
                <Typography
                  color={"#333"}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Account
                </Typography>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "12px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "15px",
                        marginBottom: "4px",
                      }}
                    >
                      First Name
                    </label>
                    <TextField
                      id="firstName"
                      variant="outlined"
                      value={firstName}
                      onChange={handleInputNameChange}
                      sx={{
                        ".Mui-focused": {
                          background: "#FFF8EC",
                          ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover":
                          {
                            background: "transparent",
                          },
                          ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover":
                          {
                            background: "transparent",
                          },
                          ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            color: "#333",
                            fontSize: "14px",
                            fontWeight: "400",
                          },
                        },
                        ".MuiInputBase-root": {
                          maxHeight: "44px",
                          borderRadius: "8px",
                          "&:focus-visible": {
                            outline: "none !important",
                          },
                        },
                        "&.MuiFormControl-root .MuiInputBase-root .css-19z3763-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#333",
                          padding: "12px 16px",
                          textTransform: "capitalize",
                        },
                        "&.MuiFormControl-root .MuiInputBase-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #D2D2D0",
                        },
                        ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
                          padding: "0px",
                          marginLeft: "-7px",
                          pointerEvents: "none",
                        },
                      }}
                    />
                    {!isValidFName && (
                      <span
                        style={{
                          color: "red",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Icons
                          name="warningRed"
                          fill={"#8E8D8A"}
                          style={{ marginRight: "6px" }}
                        />{" "}
                        {firstName === ""
                          ? "Enter first name"
                          : "Invalid first name"}
                      </span>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "16px 0px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        marginBottom: "4px",
                      }}
                    >
                      Last Name
                    </label>
                    <TextField
                      id="lastName"
                      variant="outlined"
                      value={lastName}
                      onChange={handleInputNameChange}
                      sx={{
                        ".Mui-focused": {
                          background: "#FFF8EC",
                          ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover":
                          {
                            background: "transparent",
                          },
                          ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover":
                          {
                            background: "transparent",
                          },
                          ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            color: "#333",
                            fontSize: "14px",
                            fontWeight: "400",
                          },
                        },
                        ".MuiInputBase-root": {
                          maxHeight: "44px",
                          borderRadius: "8px",
                          "&:focus-visible": {
                            outline: "none !important",
                          },
                        },
                        "&.MuiFormControl-root .MuiInputBase-root .css-19z3763-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#333",
                          padding: "12px 16px",
                          textTransform: "capitalize",
                        },
                        "&.MuiFormControl-root .MuiInputBase-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #D2D2D0",
                        },
                        ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
                          padding: "0px",
                          marginLeft: "-7px",
                          pointerEvents: "none",
                        },
                      }}
                    />
                    {!isValidLName && (
                      <span
                        style={{
                          color: "red",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Icons
                          name="warningRed"
                          fill={"#8E8D8A"}
                          style={{ marginRight: "6px" }}
                        />{" "}
                        {lastName === ""
                          ? "Enter last name"
                          : "Invalid last name"}
                      </span>
                    )}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <label
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        marginBottom: "4px",
                      }}
                    >
                      Email
                    </label>
                    <TextField
                      id="email"
                      variant="outlined"
                      value={verifyAccess?.responseData.email}
                      disabled
                      sx={{
                        ".Mui-focused": {
                          background: "#FFF8EC",
                          ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover":
                          {
                            background: "transparent",
                          },
                          ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover":
                          {
                            background: "transparent",
                          },
                          ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            color: "#333",
                            fontSize: "14px",
                            fontWeight: "400",
                          },
                        },
                        ".MuiInputBase-root": {
                          maxHeight: "44px",
                          borderRadius: "8px",
                          "&:focus-visible": {
                            outline: "none !important",
                          },
                        },
                        "&.MuiFormControl-root .MuiInputBase-root .css-19z3763-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#333",
                          padding: "12px 16px",
                        },
                        ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
                          padding: "0px",
                          marginLeft: "-7px",
                          pointerEvents: "none",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography
                  color={"#333"}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Security
                </Typography>
                <Box
                  sx={{
                    borderBottom: "0.9px solid #D2D2D0",
                    padding: "12px 0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={handleReset}
                >
                  <Typography
                    color={"#333"}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Reset password
                  </Typography>
                  <Icons name="chevronRight" fill="#8E8D8A" />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography
                color={"#333"}
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginBottom: "36px",
                  width: "fit-content",
                }}
                onClick={handleBack}
              >
                <Icons
                  name="chevronLeft"
                  fill="#8E8D8A"
                  width={16}
                  height={16}
                />{" "}
                <span
                  style={{
                    borderBottom: "2px solid #FFA737",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#333",
                  }}
                >
                  Back
                </span>
              </Typography>
              <Typography
                color={"#333"}
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "36px",
                  fontFamily: "PolySans_Neutral",
                }}
              >
                Reset your password
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label style={{ fontSize: "12px", fontWeight: "500", marginBottom: "4px" }}>
                  New password
                </label>
                <TextField
                  id="newPassword"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  onChange={handleInputChange}
                  value={formData.newPassword}
                  onKeyDown={handleKeyPress}
                  sx={{
                    ".Mui-focused": {
                      ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover":
                      {
                        background: "transparent",
                      },
                      ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover":
                      {
                        background: "transparent",
                      },
                      ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        color: "#333",
                        fontSize: "14px",
                        fontWeight: "400",
                      },
                    },
                    ".MuiInputBase-root": {
                      maxHeight: "44px",
                      borderRadius: "8px",
                      "&:focus-visible": {
                        outline: "none !important",
                      },
                    },
                    "&.MuiFormControl-root .MuiInputBase-root .css-19z3763-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#333",
                      padding: "12px 16px",
                    },
                    "&.MuiFormControl-root .MuiInputBase-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                    {
                      border:
                        formData.newPassword === ""
                          ? "1px solid #D2D2D0"
                          : passwordsMatch
                            ? "1px solid #007634"
                            : "1px solid #C61341",
                    },
                    "&.MuiFormControl-root .MuiInputBase-root .css-70vwbq-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      color: "#333",
                    },
                    ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
                      padding: "0px",
                      marginLeft: "-7px",
                      pointerEvents: "none",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? (
                            <Icons name="show" fill={"#8E8D8A"} />
                          ) : (
                            <Icons name="hide" fill={"#8E8D8A"} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {!isPasswordValid && (
                  <span
                    style={{
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "10px",
                      marginTop: "4px"
                    }}
                  >
                    <Icons
                      name="warningRed"
                      fill={"#8E8D8A"}
                      style={{ marginRight: "6px" }}
                    />{" "}
                    {formData.newPassword === ""
                      ? "Enter new password"
                      : "Password: 8+ chars, 1 uppercase, 1 lowercase, 1 digit, 1 special."}
                  </span>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "12px",
                  marginBottom: "114px",
                }}
              >
                <label style={{ fontSize: "12px", fontWeight: "500", marginBottom: "4px" }}>
                  Confirm password
                </label>
                <TextField
                  id="confirmPassword"
                  variant="outlined"
                  type={showPassword1 ? "text" : "password"}
                  onChange={handleInputChange}
                  value={formData.confirmPassword}
                  onKeyDown={handleKeyPress}
                  sx={{
                    ".Mui-focused": {
                      ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover":
                      {
                        background: "transparent",
                      },
                      ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover":
                      {
                        background: "transparent",
                      },
                      ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        color: "#333",
                        fontSize: "14px",
                        fontWeight: "400",
                      },
                    },
                    ".MuiInputBase-root": {
                      maxHeight: "44px",
                      borderRadius: "8px",
                      "&:focus-visible": {
                        outline: "none !important",
                      },
                    },
                    "&.MuiFormControl-root .MuiInputBase-root .css-19z3763-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#333",
                      padding: "12px 16px",
                    },
                    "&.MuiFormControl-root .MuiInputBase-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                    {
                      border:
                        formData.confirmPassword === ""
                          ? "1px solid #D2D2D0"
                          : passwordsMatch
                            ? "1px solid #007634"
                            : "1px solid #C61341",
                    },
                    "&.MuiFormControl-root .MuiInputBase-root .css-70vwbq-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      color: "#333",
                    },
                    ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
                      padding: "0px",
                      marginLeft: "-7px",
                      pointerEvents: "none",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility1}
                          edge="end"
                        >
                          {showPassword1 ? (
                            <Icons name="show" fill={"#8E8D8A"} />
                          ) : (
                            <Icons name="hide" fill={"#8E8D8A"} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={!validatePassword(formData.newPassword)}
                />
                {!passwordsErr && (
                  <span
                    style={{
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                        fontSize: "12px",
                        marginTop: "4px"
                    }}
                  >
                    <Icons
                      name="warningRed"
                      fill={"#8E8D8A"}
                      style={{ marginRight: "6px" ,}}
                    />{" "}
                    {formData.confirmPassword === ""
                      ? "Enter confirm password"
                      : "Passwords don’t match"}
                  </span>
                )}
                {passwordsMatch && formData.confirmPassword !== "" && (
                  <span
                    style={{
                      color: "green",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icons
                      name="greenCheckCircle"
                      fill={"#007634"}
                      style={{
                        marginRight: "6px",
                        width: "17px",
                        height: "17px",
                      }}
                    />{" "}
                    Passwords match
                  </span>
                )}
              </Box>
            </Box>
          )}
        </>
      </Popup>
    </>
  );
};

export default Settings;
