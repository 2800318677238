import client from "./client";

export const getSubjects = async (token: string) =>
  await client.get("student/view-subjects", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getAllFileType = async (token: string) =>
  await client.get("filetype/viewfiletypes", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const getAllSubjects = async (token: string) =>
  await client.get("student/view-subjects", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const reportBug = async (payload: object, token: string) =>
  await client.post("/bug-report/add-bug-report", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
