import React, { useState, useEffect, ChangeEvent } from "react";
import Loginlayout from "../../layouts/LoginLayout";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import LoginBanner from "../../assets/images/ContourLogo.png";
import Button from "../../components/Button";
import BorderText from "../../components/Label/BorderText";
import IconComponent from "../../components/Icons";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/api/login";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setError } from "../../store/error.slice";
import { useDispatch } from "react-redux";

interface FormData {
  newpwd: string;
  confirmpwd: string;
}

const validatePassword = (password: string): boolean => {
  const specialChars =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
  return specialChars.test(password);
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    newpwd: "",
    confirmpwd: "",
  });
  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [token, setToken] = useState<string>("");

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    const getTokenFromURL = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const tokenVal = searchParams.get("token");
      if (tokenVal !== null) {
        setToken(tokenVal);
      }
    };

    getTokenFromURL();
  }, []);

  const handleConTogglePasswordVisibility = () => {
    setShowConPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleBack = () => {
    navigate("/");
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    if (id === "newpwd") {
      setIsPasswordValid(validatePassword(value));

      if (formData.confirmpwd.trim() !== "") {
        setPasswordsMatch(value === formData.confirmpwd);
      }
    }

    if (id === "confirmpwd") {
      setPasswordsMatch(value === formData.newpwd);
    }
  };

  const handleResetPassword = async () => {
    if (
      formData.newpwd !== "" &&
      formData.confirmpwd !== "" &&
      isPasswordValid &&
      passwordsMatch
    ) {
      setLoading(true);
      try {
        const response = await resetPassword(
          { newpwd: formData.newpwd, confirmpwd: formData.newpwd },
          token
        );
        if (response && response.data) {
          setLoading(false);
          setFormData({ newpwd: "", confirmpwd: "" });
          toast.success(`${response?.data?.message}`, {
            position: "top-right",
            autoClose: 2000,
          });
          handleBack();
        } else {
          setLoading(false);
          const errorMessage = response?.data?.message || "Something went wrong";
          dispatch(
            setError({
              message: errorMessage,
              type: "error",
              time: new Date().toLocaleString(),
            })
          );
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 2000,
          });
        }
      } catch (error: any) {
        console.error("Forgot api", error);
        const errorMessage =
          error.response?.data?.message || "Something went wrong";
        dispatch(
          setError({
            message: errorMessage,
            type: "error",
            time: new Date().toLocaleString(),
          })
        );
        setLoading(false);
        if (errorMessage === "Authentication token missing") {
          toast.error("Authentication token missing", {
            position: "top-right",
            autoClose: 2000,
          });
        } else {
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 2000,
          });
        }
      }
    }
  };

  const isResetButtonDisabled =
    !formData.newpwd ||
    !formData.confirmpwd ||
    !passwordsMatch ||
    !isPasswordValid;

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (
        formData.newpwd.trim() !== "" &&
        formData.confirmpwd.trim() !== "" &&
        passwordsMatch
      ) {
        handleResetPassword();
      }
    }
  };

  return (
    <>
      <Loginlayout>
        <Box
          sx={{
            background: "#FFF",
            maxWidth: "350px",
            width: "100%",
            paddingX: "36px",
            paddingY: "40px",
            borderRadius: "16px",
            transform: "translate(50%, 11%)",
            top: "50%",
            bottom: "-50%",
            zIndex: "1000",
            position: "relative",
            boxShadow: "0px 4px 20px 0px rgba(45, 45, 45, 0.16)",
            "@media screen and (max-width:767px)": {
              top: "16px",
              left: "50%",
              transform: "translate(-50%, 0%)",
              paddingX: "16px",
              maxWidth: "310px",
            },
            "@media screen and (min-width:767px) and (max-width:1024px)": {
              top: "40%",
              right: "100px",
            },
            "@media screen and (max-width:350px) ": {
              maxWidth: "265px",
            },
          }}
        >
          <Box
            sx={{
              width: "78%",
              marginRight: "96px",
              marginBottom: "24px",
              "@media screen and (max-width:768px)": {
                marginRight: "57px",
              },
            }}
            component="img"
            src={LoginBanner}
            alt="LoginBanner"
          />
          <div
            style={{ maxWidth: "54px", cursor: "pointer" }}
            onClick={handleBack}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconComponent name="chevronLeft" fill={"#8E8D8A"} />
              <BorderText text={"Back"} />
            </Box>
          </div>
          <Box
            sx={{
              marginBottom: "60px",
              marginTop: "24px",
              "@media screen and (max-width:768px)": {
                marginY: "24px",
              },
            }}
          >
            <Typography
              color={"contour.title3"}
              variant="title2"
              sx={{
                "@media screen and (max-width:768px)": {
                  fontSize: "24px",
                  letterSpacing: "0.48px",
                },
              }}
            >
              Reset Password
            </Typography>
            <Box
              width={"100%"}
              sx={{
                paddingY: "40px",
                "@media screen and (max-width:768px)": {
                  paddingY: "24px",
                },
              }}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{ marginBottom: "4px" }}
              >
                New Password
              </Typography>
              <TextField
                id="newpwd"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                value={formData.newpwd}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                helperText={
                  !isPasswordValid ? (
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                        fontSize: "10px",
                        '&.MuiTypography-root': {
                          color: "#B21200",
                          fontWeight: "600",
                        }
                      }}
                    >
                      <IconComponent name="warningRed" fill={"#8E8D8A"} />
                      Password: 8+ chars, 1 uppercase, 1 lowercase, 1 digit, 1 special.
                    </Typography>
                  ) : (
                    ""
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? (
                          <IconComponent name="show" fill={"#8E8D8A"} />
                        ) : (
                          <IconComponent name="hide" fill={"#8E8D8A"} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  marginBottom: "8px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                  "&.MuiFormControl-root .MuiFormHelperText-root": {
                    marginLeft: "0px",
                  },
                }}
              />
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{ marginBottom: "4px" }}
              >
                Confirm Password
              </Typography>
              <TextField
                id="confirmpwd"
                type={showConPassword ? "text" : "password"}
                variant="outlined"
                value={formData.confirmpwd}
                onChange={handleInputChange}
                error={!isPasswordValid}
                onKeyDown={handleKeyPress}
                helperText={
                  !passwordsMatch ? (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                        fontSize: "10px",
                        color: "#B21200",
                      }}
                    >
                      <IconComponent name="warningRed" fill={"#8E8D8A"} />
                      {`Password doesn't match`}
                    </span>
                  ) : (
                    ""
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleConTogglePasswordVisibility}
                        edge="end"
                      >
                        {showConPassword ? (
                          <IconComponent name="show" fill={"#8E8D8A"} />
                        ) : (
                          <IconComponent name="hide" fill={"#8E8D8A"} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  marginBottom: "8px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                  "&.MuiFormControl-root .MuiFormHelperText-root": {
                    marginLeft: "0px",
                  },
                }}
              />
            </Box>
            <Box sx={{ maxWidth: "168px" }}>
                <Button
                loading={loading}
                  text="Reset"
                  textStyle={{
                    color: "#333",
                    opacity: isResetButtonDisabled ? "0.5" : "1",
                    pointerEvents: isResetButtonDisabled ? "none" : "auto",
                  }}
                  handleClick={handleResetPassword}
                />
            </Box>
          </Box>
        </Box>
      </Loginlayout>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        pauseOnHover
        hideProgressBar
        transition={Zoom}
      />
    </>
  );
};

export default ResetPassword;
