import { Box, Button, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moreButton from "../../../src/assets/images/Aos/moreButton.svg";
import React, { useState } from "react";
import dropdownIcon from "../../../src/assets/images/Aos/arrow-down.svg";
import BottomDrawer from "../../components/BottomDrawer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as ExpandMoreIcon } from "../../../src/assets/images/Aos/accordianArrow.svg";

import IconComponent from "../../components/Icons";
import BugDrawer from "./BugDrawer";
import MinimizedBugPop from "../BugReport/MinimizedBugPop";
import { useNavigate } from "react-router-dom";

interface AccordionItem {
  id: number;
  heading: string;
  content: string;
  fileCount: string;
  listItemData: string[];
}

type ToastState = {
  type: string;
  message: string;
};

interface AccordionBtmSheetProps {
  bugTitle?: string;
  bugDescription?: string;
  attachment?: (File | string)[];
  handleBugTitleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBugDescriptionChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  captureScreenshot?: () => void;
  setAttachment?: React.Dispatch<React.SetStateAction<(File | string)[]>>;
  selectedSubModule?: {
    id: string;
    module: string;
  };
  selectedPassword: string;
  bugRepId?: string;
  selectedType?: string | undefined;
  setBugTitle?: React.Dispatch<React.SetStateAction<string>>;
  setBugDescription?: React.Dispatch<React.SetStateAction<string>>;
  setToast?: React.Dispatch<React.SetStateAction<ToastState | null>>;
  titleError: boolean;
  descriptionError: boolean;
  selectedModuleFileData?: any;
  markComplete?: any;
  handleMark?: (id: any,status:any) => Promise<void>;
  fileTaskId?: any;
  pdfCom?: boolean;
  subjectAOSDetails?: any[];
  bottomDrawerOpen2: any;
  setBottomDrawerOpen2: any;
}

export const AccordionBtmSheetRes: React.FC<AccordionBtmSheetProps> = ({
  bugTitle,
  bugDescription,
  attachment,
  selectedModuleFileData,
  selectedPassword,
  pdfCom,
  markComplete,
  handleMark,
  fileTaskId,
  handleBugTitleChange,
  handleBugDescriptionChange,
  titleError,
  descriptionError,
  captureScreenshot,
  setAttachment,
  selectedSubModule,
  selectedType,
  setToast,
  setBugTitle,
  setBugDescription,
  bugRepId,
  subjectAOSDetails,
  bottomDrawerOpen2,
  setBottomDrawerOpen2,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [codeCopied, setCodeCopied] = useState(false);
  const [bottomDrawerOpen, setBottomDrawerOpen] = useState(false);
  const [bottomDrawerOpen3, setBottomDrawerOpen3] = useState(false);
  const [showMobileMinimize, setShowMobileMinimize] = React.useState(false);

  console.log("selectedModuleFileData", subjectAOSDetails);

  const handleClick = () => {
    handleBottomDrawerOpen();
  };

  const handleBottomDrawerOpen = () => {
    setBottomDrawerOpen(true);
  };

  const handClose = () => {
    setBottomDrawerOpen(false);
    setBottomDrawerOpen2(false);
    setBottomDrawerOpen3(false);
    setShowMobileMinimize(false);
  };

  const handCloseBugDrawer = () => {
    setBottomDrawerOpen2(false);
    setBottomDrawerOpen(false);
    setShowMobileMinimize(false);
    setBugTitle && setBugTitle("");
    setBugDescription && setBugDescription("");
    setAttachment && setAttachment([]);
  };

  const accordionData: AccordionItem[] = [
    {
      id: 1,
      heading: "[1.1] Gravity",
      fileCount: "5 Files",
      content: "Content for Accordion Item 1",
      listItemData: [
        "Recording",
        "Workbook",
        "Workbook solution",
        "Test",
        "Test solution",
      ],
    },
    {
      id: 2,
      heading: "[1.2] Gravitational Fields",
      fileCount: "5 Files",
      content: "Content for Accordion Item 2",
      listItemData: ["Item 1", "Item 2", "Item 3"],
    },
    {
      id: 3,
      heading: "[1.3] Orbital Mechanics - Longer Name Example",
      fileCount: "5 Files",
      content: "Content for Accordion Item 3",
      listItemData: ["Option A", "Option B", "Option C"],
    },
  ];
  const [expanded, setExpanded] = useState<Set<string>>(
    new Set([`panel${accordionData[0].id}`])
  );

  const handleChange =
    (panel: string) =>
      (event: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => {
        setExpanded((prevExpanded) => {
          const newSet = new Set(prevExpanded);

          if (isExpanded) {
            newSet.add(panel);
          } else {
            newSet.delete(panel);
          }

          return newSet;
        });
      };

  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: string | null;
  }>({});
  const navigate = useNavigate();

  const handleItemClick = (
    subject_id: string,
    aos_id: string,
    topic_id: string,
    file_type_id: string,
    file_id: string,
    ext_type: string,
    file_type: string,
    topic_name: string
  ) => {
    const updatedSelectedItems: { [key: string]: string | null } = {};

    updatedSelectedItems[`panel${topic_id}`] = file_id;
    console.log(
      "ext_type",
      ext_type,
      "file_type",
      file_type,
      "topic_name",
      topic_name,
      "topic_id",
      topic_id,
      "sub_module_id",
      file_type_id,
      "aos_id",
      aos_id,
      "subject_id",
      subject_id,
      "file_id",
      file_id,
      "subjectAOSDetails",
      subjectAOSDetails,
      "updatedSelectedItems",
      updatedSelectedItems
    );
    setSelectedItems(updatedSelectedItems);
    const newSearch = new URLSearchParams();
    newSearch.set("sid", subject_id);
    newSearch.set("aosID", aos_id);
    newSearch.set("topicID", topic_id);
    newSearch.set("typeID", file_type_id);
    newSearch.set("fileID", file_id);
    navigate(`?${newSearch.toString()}`);
    setBottomDrawerOpen(false)
  };

  const handleCopyClick = (codeToCopy: string) => {
    navigator.clipboard
      .writeText(codeToCopy)
      .then(() => {
        setCodeCopied((prev) => !prev);
        setTimeout(() => {
          setCodeCopied(false);
        }, 1500);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  const options = [
    { name: "Report a bug", icon: "bugIcon" },
  ];

  const open = Boolean(anchorEl);
  const handleClickButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickDownload = () => {
    setBottomDrawerOpen3(true);
  };
  const handleSelectOption = (index: number) => {
    setAnchorEl(null);
    const onClickFunctions = [
      () => {
        setBottomDrawerOpen2(true);
      },
      () => {
        setCodeCopied(false);
      },
    ];
    onClickFunctions[index]();
  };
  const handleMobileminize = () => {
    setShowMobileMinimize(true);
    setBottomDrawerOpen2(false);
  };

  const handleDownloadClick = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const url = selectedModuleFileData[0]?.files?.file_protected_url !== null
      ? selectedModuleFileData[0]?.files?.file_protected_url
      : selectedModuleFileData[0]?.files?.file_url
    const fileUrl = baseUrl + "/" + url;
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    } else {
      console.error("File URL not found.");
    }
  };

  return (
    <Box>
      {!isDesktop && (
        <Box>
          <Box>
            <Box sx={{ marginBottom: "16px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      fontSize: 18,
                      color: "#333",
                      fontFamily: "PolySans_Neutral",
                      fontWeight: 600,
                      lineHeight: "21px",
                    }}
                  >
                    {selectedSubModule?.module.replace('-', '')}
                    {selectedType && ' - ' + selectedType.charAt(0).toUpperCase()}
                    {selectedType && selectedType.substring(1)}
                  </Typography>
                  <img
                    src={dropdownIcon}
                    alt="moreButton"
                    onClick={handleClick}
                  />
                </Box>
                <Box>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClickButton}
                    sx={{ paddingRight: "0" }}
                  >
                    <img src={moreButton} alt="moreButton" />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{
                      "& .MuiMenu-list": {
                        borderRadius: "8px",
                        border: "1px solid #D2D2D0",
                        boxShadow: "0px 4px 20px 0px rgba(45, 45, 45, 0.16)",
                        paddingTop: "0",
                        paddingBottom: "0",
                      },
                      "& .MuiMenuItem-root": {
                        borderBottom: "1px solid #D2D2D0",
                      },
                      ".MuiPaper-rounded": {
                        borderRadius: "8px",
                        width: "184px",
                      },
                    }}
                  >
                    {options.map((option, index) => (
                      <MenuItem
                        key={option?.name}
                        onClick={() => handleSelectOption(index)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                          component={"div"}
                        >
                          <IconComponent
                            name={option?.icon ?? ""}
                            fill={"#8E8D8A"}
                            width={20}
                            height={20}
                          />
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "18.2px",
                              color: "#333333",
                            }}
                          >
                            {option?.name}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                    {pdfCom && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          padding: "10px 16px",
                        }}
                        onClick={handleClickDownload}
                        component={"div"}
                      >
                        <IconComponent
                          name="download"
                          fill={"#8E8D8A"}
                          width={20}
                          height={20}
                        />
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "18.2px",
                            color: "#333333",
                          }}
                        >
                          Download
                        </Typography>
                      </Box>
                    )}
                  
                    {selectedModuleFileData && selectedModuleFileData[0]?.files.student_acknowledgement_status && (
                      <Box
                        onClick={() => handleMark && handleMark(fileTaskId, markComplete)}
                        sx={{
                          background: markComplete ? "#E6F6ED" : "#FFF",
                          ":hover": { background: "#FFEDD1" },
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          padding: "10px 16px",
                        }}
                      >
                        {markComplete ? (
                          <IconComponent
                            name="greenCheckCircle"
                            fill={"#8E8D8A"}
                            width={20}
                            height={20}
                          />
                        ) : (
                          <IconComponent
                            name="checkCircle"
                            fill={"#8E8D8A"}
                            width={20}
                            height={20}
                          />
                        )}
                        {markComplete ? (
                          <Typography
                            sx={{
                              fontSize: 14,
                              color: "#333",
                              fontFamily: "Inter",
                              fontWeight: 400,
                              lineHeight: "18.2px",
                            }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: 14,
                              color: "#333",
                              fontFamily: "Inter",
                              fontWeight: 400,
                              lineHeight: "18.2px",
                            }}
                          >
                            Mark as complete
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Menu>
                </Box>
              </Box>
              {markComplete && (
                <Box
                  sx={{
                    background: "#E6F6ED",
                    display: "flex",
                    borderRadius: "8px",
                    padding: "7px 12px",
                    width: "fit-content",
                    marginTop: "5px",
                  }}
                >
                  <IconComponent
                    name="greenCheckCircle"
                    fill={"#8E8D8A"}
                    width={20}
                    height={20}
                  />
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "#333",
                      fontFamily: "Inter",
                      fontWeight: 500,
                      lineHeight: "18.2px",
                      marginLeft: "8px",
                    }}
                  >
                    Completed
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <BottomDrawer open={bottomDrawerOpen} onClose={handClose}>
            <Box>
              {subjectAOSDetails &&
                subjectAOSDetails[0]?.area_of_study?.map((tab: any) => (
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontFamily: "Inter",
                      fontWeight: 600,
                      marginLeft: "16px",
                    }}
                  >
                    {tab.area_of_study} Topics
                  </Typography>
                ))}
              <Box sx={{ padding: "16px", height: 'calc(100vh - 300px)' }}>
                <div>
                  {subjectAOSDetails &&
                    subjectAOSDetails[0]?.area_of_study[0]?.topics?.map(
                      (sub_module: any, i: number) => (
                        <Accordion
                          key={sub_module.topic + i}
                          expanded={expanded.has(`panel${sub_module.id}`)}
                          onChange={handleChange(`panel${sub_module.id}`)}
                          sx={{
                            border: "none",
                            overflowY: "auto",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${sub_module.id}-content`}
                            id={`panel${sub_module.id}-header`}
                            sx={{
                              borderRadius: "8px",
                              border: "1px solid #EFEFEF",
                              background: "#fff",
                              padding: "16px 20px",
                              backgroundColor: expanded.has(
                                `panel${sub_module.id}`
                              )
                                ? "#FFEDD1"
                                : "inherit",
                              height: "auto",
                              color: expanded.has(`panel${sub_module.id}`)
                                ? "#333333"
                                : "#696969",
                              "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                              {
                                transform: "rotate(0deg)",
                              },
                            }}
                          >
                            <Box display={"flex"} flexDirection={"column"}>
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  fontFamily: "Inter",
                                  fontWeight: 600,
                                }}
                              >
                                {sub_module.topic}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  fontFamily: "Inter",
                                  fontWeight: 400,
                                }}
                              >
                                {sub_module.file_count}{" "}
                                {sub_module.file_count === 1 ? "File" : "Files"}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{ borderRadius: "8px", padding: "0" }}
                          >
                            <Box>
                              {sub_module?.fileTypes.map(
                                (listItem: any, index: number) => {
                                  console.log("ftdgayd", listItem);
                                  return (
                                    <Box
                                      key={index}
                                      onClick={() =>
                                        handleItemClick(
                                          subjectAOSDetails[0]?.subject_id,
                                          subjectAOSDetails[0]
                                            ?.area_of_study[0]?.id,
                                          sub_module?.id,
                                          listItem?.file_type_id,
                                          listItem?.id,
                                          listItem?.ext_type,
                                          listItem?.file_type,
                                          sub_module?.topic
                                        )
                                      }
                                      sx={{
                                        justifyContent: "space-between",
                                        padding: "15px 12px",
                                        marginTop: "5px",
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                        fontWeight:
                                          selectedItems[
                                            `panel${sub_module.id}`
                                          ] === listItem?.id
                                            ? "600"
                                            : "400",
                                        backgroundColor:
                                          selectedItems[
                                            `panel${sub_module.id}`
                                          ] === listItem?.id
                                            ? "#FFC071"
                                            : "transparent",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                        textTransform: "capitalize",
                                        gap: "8px",
                                      }}
                                    >
                                      <Box sx={{ display: "flex" }}>
                                        {selectedItems[
                                          `panel${sub_module.id}`
                                        ] === listItem?.id ? (
                                          <>
                                            {listItem?.ext_type === "video" ? (
                                              <IconComponent
                                                name="recordingBarBlackIcon"
                                                fill={"#8E8D8A"}
                                                width={16}
                                                height={16}
                                              />
                                            ) : (
                                              <IconComponent
                                                name="textFileActive"
                                                fill={"#8E8D8A"}
                                                width={16}
                                                height={16}
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {listItem?.ext_type === "video" ? (
                                              <IconComponent
                                                name="recordingBarIcon"
                                                fill={"#8E8D8A"}
                                                width={16}
                                                height={16}
                                              />
                                            ) : (
                                              <IconComponent
                                                name="textFile"
                                                fill={"#8E8D8A"}
                                                width={16}
                                                height={16}
                                              />
                                            )}
                                          </>
                                        )}
                                        <Box sx={{ marginLeft: "10px" }}>
                                          {listItem?.file_type}
                                        </Box>
                                      </Box>
                                      <svg
                                        width="8"
                                        height="12"
                                        viewBox="0 0 8 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0.999517 9.8751L4.87952 5.9951L0.999517 2.1151C0.812264 1.92827 0.707031 1.67462 0.707031 1.4101C0.707031 1.14558 0.812264 0.891929 0.999517 0.705098C1.38952 0.315098 2.01952 0.315098 2.40952 0.705098L6.99952 5.2951C7.38952 5.6851 7.38952 6.3151 6.99952 6.7051L2.40952 11.2951C2.01952 11.6851 1.38952 11.6851 0.999517 11.2951C0.619517 10.9051 0.609517 10.2651 0.999517 9.8751Z"
                                          fill="#8E8D8A"
                                        />
                                      </svg>
                                    </Box>
                                  );
                                }
                              )}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      )
                    )}
                </div>
              </Box>
            </Box>
          </BottomDrawer>
          <BottomDrawer open={bottomDrawerOpen3} onClose={handClose}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "18.2px",
                color: "#333",
                padding: "32px 16px 12px",
              }}
            >
              Access the dowloaded file using the password:
            </Typography>
            <Box
              sx={{
                background: "#F4F6FF",
                display: "flex",
                alignItems: "center",
                marginTop: "12px",
                borderRadius: "8px",
                margin: "0 16px 20px",
              }}
            >
              <Typography
                sx={{
                  padding: "0px 12px",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  color: "#333",
                }}
              >
                {selectedPassword}
              </Typography>
              <Button
                onClick={() => handleCopyClick(selectedPassword)}
                sx={{
                  border: codeCopied
                    ? "1px solid #007634"
                    : "1px solid #FFA737",
                  padding: "12px",
                  height: "34px",
                  textTransform: "capitalize",
                  background: codeCopied ? "#E6F6ED" : "#FFF",
                  borderRadius: "8px",
                  ":hover": { background: "#FFEDD1" },
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {codeCopied ? (
                  <IconComponent
                    name="greenCheckCircle"
                    fill={"#8E8D8A"}
                    width={16}
                    height={16}
                  />
                ) : (
                  <IconComponent
                    name="copy"
                    fill={"#8E8D8A"}
                    width={16}
                    height={16}
                  />
                )}
                {codeCopied ? (
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "#333",
                      fontFamily: "Inter",
                      fontWeight: 600,
                      lineHeight: "21px",
                    }}
                  >
                    Copied
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "#333",
                      fontFamily: "Inter",
                      fontWeight: 600,
                      lineHeight: "21px",
                    }}
                  >
                    Copy
                  </Typography>
                )}
              </Button>
            </Box>
            <Box
              sx={{
                padding: "13px 16px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
                borderTop: "1px solid #D2D2D0",
                marginBottom: "28px",
                borderBottom: "1px solid #D2D2D0",
                ":hover": { background: "#FFF8EC" },
              }}
              component={"div"}
              onClick={handleDownloadClick}
            >
              <IconComponent
                name="download"
                fill={"#8E8D8A"}
                width={20}
                height={20}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "18.2px",
                  color: "#333",
                  maxWidth: "213px",
                }}
              >
                Begin download
              </Typography>
            </Box>
          </BottomDrawer>
          <BugDrawer
            bugRepId={bugRepId}
            open={bottomDrawerOpen2}
            onClose={handCloseBugDrawer}
            headerText={"Report a bug"}
            cancel={handCloseBugDrawer}
            minimized={handleMobileminize}
            bugTitle={bugTitle}
            setToast={setToast}
            bugDescription={bugDescription}
            attachment={attachment}
            handleBugTitleChange={handleBugTitleChange}
            handleBugDescriptionChange={handleBugDescriptionChange}
            titleError={titleError}
            descriptionError={descriptionError}
            captureScreenshot={captureScreenshot}
            setAttachment={setAttachment}
            selectedSubModule={selectedSubModule}
            selectedType={selectedType}
          />

          {showMobileMinimize && (
            <MinimizedBugPop
              expand={() => setBottomDrawerOpen2(true)}
              close={handCloseBugDrawer}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
