import React, { useState, useEffect, ChangeEvent } from "react";
import Loginlayout from "../../layouts/LoginLayout";
import { Box, TextField, Typography } from "@mui/material";
import LoginBanner from "../../assets/images/ContourLogo.png";
import Button from "../../components/Button";
import BorderText from "../../components/Label/BorderText";
import IconComponent from "../../components/Icons";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../services/api/login";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface FormData {
  email: string;
}

const ForgottenPassword = () => {
  const [formData, setFormData] = useState<FormData>({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const validateEmail = (email: string): boolean => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    const savedUserData = sessionStorage.getItem("userData");
    if (savedUserData) {
      setFormData(JSON.parse(savedUserData));
    }
  }, []);

  const handleBack = () => {
    navigate("/");
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    const isEmailValid = validateEmail(value);
    setIsEmailValid(isEmailValid);
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleForgotPassword = async () => {
    if (formData.email !== "") {
      const isEmailValid = validateEmail(formData.email);
      setIsEmailValid(isEmailValid);
      const payload = {
        email: formData.email,
      };
      if (isEmailValid) {
        setLoading(true);
        try {
          const response = await forgotPassword(payload);
          if (response && response.data) {
            setLoading(false);
            setFormData({ email: "" });
            toast.success(`${response?.data?.message}`, {
              position: "top-right",
              autoClose: 2000,
            });
          } else {
            setLoading(false);
            toast.error(`${response?.data?.message}`, {
              position: "top-right",
              autoClose: 2000,
            });
          }
        } catch (error) {
          console.error("Error during API call:", error);
          setLoading(false);
          toast.error(
            `${error ? "Email or Password does not match!" : error}`,
            {
              position: "top-right",
              autoClose: 5000,
            },
          );
        }
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (formData.email.trim() !== "") {
        handleForgotPassword();
      }
    }
  };

  const isResetButtonDisabled = !formData.email || !isEmailValid;

  return (
    <>
      <Loginlayout>
        <Box
          sx={{
            background: "#FFF",
            maxWidth: "350px",
            width: "100%",
            paddingX: "36px",
            paddingTop: "40px",
            paddingBottom: "10px",
            borderRadius: "16px",
            transform: "translate(50%, 11%)",
            top: "20%",
            bottom: "-50%",
            zIndex: "1000",
            position: "relative",
            boxShadow: "0px 4px 20px 0px rgba(45, 45, 45, 0.16)",
            "@media screen and (max-width:767px)": {
              top: "50px",
              left: "50%",
              transform: "translate(-50%, 0%)",
              paddingX: "16px",
              maxWidth: "310px",
            },
            "@media screen and (min-width:767px) and (max-width:1024px)": {
              top: "40%",
              right: "100px",
            },
            "@media screen and (max-width:350px) ": {
              maxWidth: "265px",
            },
          }}
        >
          <Box
            sx={{
              width: "78%",
              marginRight: "96px",
              marginBottom: "24px",
              "@media screen and (max-width:768px)": {
                marginRight: "57px",
              },
            }}
            component="img"
            src={LoginBanner}
            alt="LoginBanner"
          />
          <div
            style={{ maxWidth: "54px", cursor: "pointer" }}
            onClick={handleBack}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconComponent name="chevronLeft" fill={"#8E8D8A"} />
              <BorderText text={"Back"} />
            </Box>
          </div>
          <Box
            sx={{
              marginBottom: "60px",
              marginTop: "24px",
              "@media screen and (max-width:768px)": {
                marginY: "24px",
              },
            }}
          >
            <Typography
              color={"contour.title3"}
              variant="title2"
              sx={{
                "@media screen and (max-width:768px)": {
                  fontSize: "24px",
                  letterSpacing: "0.48px",
                },
              }}
            >
              Forgot Password
            </Typography>
            <Box
              width={"100%"}
              sx={{
                paddingY: "40px",
                "@media screen and (max-width:768px)": {
                  paddingY: "24px",
                },
              }}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{ marginBottom: "4px" }}
              >
                Email
              </Typography>
              <TextField
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                variant="outlined"
                error={!isEmailValid}
                onKeyDown={handleKeyPress}
                helperText={
                  !isEmailValid ? (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      <IconComponent name="warningRed" fill={"#8E8D8A"} />
                      Enter a valid email address
                    </span>
                  ) : (
                    ""
                  )
                }
                sx={{
                  marginBottom: "8px",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                  ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #C61341",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                    color: "#181A20",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root": {
                    marginLeft: 0,
                  },
                }}
              />
            </Box>
            <Box sx={{ maxWidth: "168px" }}>
                <Button
                loading={loading}
                  text="Reset"
                  textStyle={{
                    color: "#333",
                    opacity: isResetButtonDisabled ? "0.5" : "1",
                    cursor: isResetButtonDisabled ? "none" : "pointer",
                    pointerEvents: isResetButtonDisabled ? "none" : "auto",
                  }}
                  handleClick={handleForgotPassword}
                />
            </Box>
          </Box>
        </Box>
      </Loginlayout>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        pauseOnHover
        hideProgressBar
        transition={Zoom}
      />
    </>
  );
};

export default ForgottenPassword;
